import Verification from '../../Promotion/components/Verification/Verification';
import ExamSelect from '../components/ExamSelect/ExamSelect';
import React from 'react';
import ForceSelect from '../components/ForceSelect/ForceSelect';
import PersonalDetails from '../../Promotion/components/PersonalDetails/PersonalDetails';
import MarriageDetails from '../components/MarriageDetails/MarriageDetails';
import SexAndGender from '../../components/SexAndGender/SexAndGender';
import SexualOrientation from '../../components/SexualOrientation/SexualOrientation';
import RaceAndReligion from '../../components/RaceAndReligion/RaceAndReligion';
import Accessibility from '../../components/Accessibility/Accessibility';
import NationalSchemes from '../components/NationalSchemes/NationalSchemes';
import Confirmation from '../../components/Confirmation/Confirmation';
import appUtils from '../../../../utils/application';
import {processErrors} from '../../utils/fieldMapping';
import {transformOut} from './fieldMapping';
import SocioeconomicBackground from '../../components/SocioeconomicBackground/SocioeconomicBackground';
import Education from '../../components/Education/Education';
import FirstLanguage from '../../components/FirstLanguage/FirstLanguage';
import EntryRoute from '../../Promotion/components/EntryRoute/EntryRoute';
import CandidateDeclaration from '../../components/CandidateDeclaration/CandidateDeclaration';
import MilitaryVeteran from '../components/MilitaryVeteran/MilitaryVeteran';

const getForms = (props) => {
  const {form, handleStepState, step, setErrors, goToStep, focusField} = props || {};
  return [
    {
      slug: 'select-exam',
      title: 'Exams',
      component: <ExamSelect form={form} handleStepState={handleStepState} step={step} />
    },
    {
      slug: 'select-force',
      title: 'Force',
      component: <ForceSelect form={form} handleStepState={handleStepState} step={step} />
    },
    {
      slug: 'personal-details',
      title: 'Personal details',
      component: <PersonalDetails form={form} handleStepState={handleStepState} step={step} focusField={focusField} />
    },
    {
      slug: 'accessibility',
      title: 'Disability',
      component: <Accessibility form={form} handleStepState={handleStepState} step={step} type="promotion" policyUrl={'https://www.college.police.uk/What-we-do/Development/Promotion/Documents/RA%20Policy%20Examinations.pdf'} />
    },
    {
      slug: 'race-and-religion',
      title: 'Ethnicity & religion or belief',
      component: <RaceAndReligion form={form} handleStepState={handleStepState} step={step} />
    },
    {
      slug: 'marriage-details',
      title: 'Marriage & civil partnership',
      component: <MarriageDetails form={form} handleStepState={handleStepState} step={step} />
    },
    {
      slug: 'sex-and-gender',
      title: 'Sex & gender',
      component: <SexAndGender form={form} handleStepState={handleStepState} step={step} />
    },
    {
      slug: 'sexual-orientation',
      title: 'Sexual orientation',
      component: <SexualOrientation form={form} handleStepState={handleStepState} step={step} />
    },
    {
      slug: 'socioeconomic-background',
      title: 'Socioeconomic background',
      component: <SocioeconomicBackground form={form} handleStepState={handleStepState} step={step} type="promotion" />
    },
    {
      slug: 'education',
      title: 'Education',
      component: <Education form={form} handleStepState={handleStepState} step={step} />
    },
    {
      slug: 'first-language',
      title: 'First language',
      component: <FirstLanguage form={form} handleStepState={handleStepState} step={step} />
    },
    {
      slug: 'entry-route',
      title: 'Entry route',
      component: <EntryRoute form={form} handleStepState={handleStepState} step={step} type="promotion" />
    },
    {
      slug: 'veteran-status',
      title: 'Veteran status',
      component: <MilitaryVeteran form={form} handleStepState={handleStepState} step={step} />
    },
    {
      slug: 'national-schemes',
      title: 'National schemes',
      component: <NationalSchemes form={form} handleStepState={handleStepState} step={step} />
    },
    {
      slug: 'verification',
      title: 'Verification',
      component: <Verification form={form} handleStepState={handleStepState} step={step} goToStep={goToStep} getForms={getForms} />
    },
    {
      slug: 'candidate-declaration',
      title: 'Candidate declaration',
      component: <CandidateDeclaration form={form} handleStepState={handleStepState} step={step} type="promotion" />
    },
    {
      slug: 'confirmation',
      title: 'Confirmation',
      component: <Confirmation
        form={form}
        handleStepState={handleStepState}
        setErrors={setErrors}
        goToStep={goToStep}
        submissionAction={appUtils.promotionExamApply}
        transformIn={processErrors(form)}
        transformOut={transformOut}
        type="promotion"
        getForms={getForms} />
    }
  ];
};

export default getForms;
