import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';
import {CircularProgress, Grid, Paper} from '@material-ui/core';
import {Link as RouterLink, useParams} from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Typography from '@material-ui/core/Typography';
import {useTranslation} from 'react-i18next';
import AssessmentResults from './components/AssessmentResults';
import {AssessmentStatus, AssessmentDetails} from './components';
import Message from '../../../components/Message';
import appUtils from '../../../utils/application';
import {DashboardSlotAllocationView} from '../../index';
import NationalSiftResults from './components/NationalSiftResult';

const useStyles = makeStyles(() => ({
  root: {
    padding: 24
  },
  breadcrumb: {
    marginBottom: 15
  },
  noAppPaper: {
    padding: 24
  }
}));

const AppSingle = () => {

  const {appId} = useParams();
  const classes = useStyles();
  const {t} = useTranslation();
  const {show, message, type} = window.location.state || {};
  const [application, setApplication] = useState({
    topMessage: {show, message, type},
    data: {},
    renderChangeSlot: false,
    changeSlotData: {},
    loading: true
  });

  const fetchApp = async () => {
    try {
      const appData = await appUtils.getApplication(appId);
      setApplication({...application, data: appData.data, loading: false});
    } catch (e) {
      setApplication({
        ...application,
        topMessage: {message: t('dashboard.app.notFound'), show: true, type: 'info'},
        loading: false
      });
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    fetchApp();
  }, [appId, application.renderChangeSlot]);

  const handleTopMessage = (msg) => {
    setApplication({...application, topMessage: {show: msg.show, type: msg.type, message: msg.message}});
  };

  const handleApplicationChange = (state, message) => {

    const updatedAppState = state ? state : application.data.state;
    setApplication({
      ...application,
      data: Object.assign(application.data, {state: updatedAppState}),
      topMessage: {show: message.show, type: message.type, message: message.message}
    });
  };

  const showResults = () => application.data.result && application.data.state === 'COMPLETED';
  const showNationalSiftResults = () => application.data.siftFeedbackReport || application.data.siftPassApplication;
  const renderChangeSlot = (changeSlotData) => setApplication({...application, renderChangeSlot: true, changeSlotData});
  const hideSlotsChange = (topMessage) => setApplication({...application, renderChangeSlot: false, topMessage});

  return (
    <React.Fragment>
      {application.loading ? <CircularProgress data-testid="loading" /> : null}
      {application.topMessage.show ?
        (<Message
          type={application.topMessage.type || 'error'}
          message={application.topMessage.message}
        />)
        : null}
      {application.data && application.data.id ?
        <React.Fragment>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            className={classes.breadcrumb}
            data-testid="baguette"
          >
            <Link color="inherit" component={RouterLink} to="/applications">
              {t('dashboard.app.applications')}
            </Link>
            {application.renderChangeSlot
              ? <Link color="inherit" component={RouterLink} to={`/applications/${application.data.id}`}>
                {application.data.eventType} - {application.data.force}
              </Link>
              : <Typography color="textPrimary">{application.data.eventType} - {application.data.force}</Typography>}
            {application.renderChangeSlot ? <Typography color="textPrimary">{t('dashboard.app.changeSlot')}</Typography> : null}
          </Breadcrumbs>
          <Paper className={classes.root}>
            <Typography variant="h1">{application.renderChangeSlot ? t('dashboard.app.changeSlot') : t('dashboard.app.applicationDetails') }</Typography>
            {application.renderChangeSlot ?
              <DashboardSlotAllocationView
                application={application.data}
                dashboardMessage={handleTopMessage}
                hideSlotsChange={hideSlotsChange}
                availableSlots={application.changeSlotData}
              />
              : <React.Fragment>
                <div data-testid="dashboard">
                  <Grid container spacing={3}>
                    <Grid item md={7} xs={12}>
                      {application.data ?
                        <AssessmentStatus
                          application={application.data}
                          dashboardMessage={handleTopMessage}
                          handleApplicationChange={handleApplicationChange}
                        />
                        : null}
                      {showResults() ?
                        <AssessmentResults application={application.data} dashboardMessage={handleTopMessage} /> : null}
                      {showNationalSiftResults() ?
                        <NationalSiftResults application={application.data} dashboardMessage={handleTopMessage} /> : null}
                    </Grid>
                    <Grid item md={5} xs={12}>
                      {application.data.state === 'ALLOCATED' ?
                        <AssessmentDetails
                          application={application.data}
                          title={t('dashboard.app.event.title')}
                          subtitle={t('dashboard.app.event.subTitle')}
                          showLink={true}
                          dashboardMessage={handleTopMessage}
                          renderChangeSlot={renderChangeSlot}
                        />
                        : null}
                    </Grid>
                  </Grid>
                </div>
              </React.Fragment>}
          </Paper>
        </React.Fragment>
        : null}
    </React.Fragment>
  );
};

AppSingle.propTypes = {};

export default AppSingle;
