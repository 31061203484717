import React, {useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Button, Typography} from '@material-ui/core';
import WithdrawDialog from './WithdrawDialog';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import {useTranslation} from 'react-i18next';
import appUtils from '../../../../../utils/application';
import {STATES} from '../../../../../utils/applicationConst';
import Input from '../../../../../components/form/Input';
import palette from '../../../../../theme/palette';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 20,
  },
  paper: {
    textAlign: 'center',
    boxShadow: 'none'
  },
  divider: {
    marginTop: 15,
    marginBottom: 15
  },
  withdrawButton: {
    backgroundColor: palette.error.main,
    marginTop: 15,
    maxWidth: 120,
    float: 'left'
  },
  icon: {
    position: 'relative',
    top: 5,
    marginRight: 5
  },
  iconExpand: {
    position: 'relative',
    top: 5
  },
  link: {
    fontSize: 17
  },
  withdrawSelect: {
    minWidth: 200,
    marginBottom: 6,
  },
  otherLabel: {
    padding: '15px 10px 5px 0',
    fontSize: 16,
    fontWeight: 'bold'
  },
  withdrawSection: {
    marginLeft: 30
  }
}));


const Withdraw = props => {
  const {t} = useTranslation();
  const [submit, setSubmit] = useState({
    confirmDialog: false,
    withdrawReason: '',
    expandWithdraw: false,
    withdrawReasonOpen: false,
    showWithdrawReasonOther: false,
    withdrawOtherError: false,
    withdrawReasonError: false,
    withdrawReasonOther: ''
  });

  const withdrawReasons = Object.freeze({
    illness: t('dashboard.app.withdraw.reasons.illness'),
    bereavement: t('dashboard.app.withdraw.reasons.bereavement'),
    childcare: t('dashboard.app.withdraw.reasons.childcare'),
    unableToTakeTimeOffWork: t('dashboard.app.withdraw.reasons.unableToTakeTimeOffWork'),
    changeInPersonalCircumstance: t('dashboard.app.withdraw.reasons.changeInPersonalCircumstance'),
    underPreparedForAssessment: t('dashboard.app.withdraw.reasons.underPreparedForAssessment'),
    noLongerWantToBeInPolicing: t('dashboard.app.withdraw.reasons.noLongerWantToBeInPolicing'),
    travelArrangements: t('dashboard.app.withdraw.reasons.travelArrangements'),
    preferNotToSay: t('dashboard.app.withdraw.reasons.preferNotToSay'),
    other: t('dashboard.app.withdraw.reasons.other'),
  });

  const validate = () => {
    if (submit.withdrawReason === 'other' && submit.withdrawReasonOther.length === 0) {
      setSubmit({...submit, withdrawOtherError: true});
    } else if (Object.keys(withdrawReasons).includes(submit.withdrawReason)) {
      setSubmit({...submit, confirmDialog: true});
    } else {
      setSubmit({...submit, withdrawReasonError: true});
    }
  };

  const {handleApplicationChange, handleAllowedActions} = props;

  const toggleWithdrawExpand = () => {
    setSubmit({...submit, expandWithdraw: !submit.expandWithdraw});
  };

  const handleReasonChange = event => {
    let showWithdrawReasonOther = false;
    if (event.target.value === 'other') showWithdrawReasonOther = true;

    setSubmit({
      ...submit,
      withdrawReason: event.target.value,
      withdrawReasonOpen: false,
      showWithdrawReasonOther
    });
  };

  const handleWithdrawOtherReasonChange = (event) => setSubmit({...submit, withdrawReasonOther: event.target.value});

  const handleWithdraw = async (event, action) => {
    if (action === 'confirm') {
      let withdrawResponse = {};
      try {

        withdrawResponse = await appUtils.withdraw(props.application.id, {
          reason: submit.withdrawReason,
          ...(submit.withdrawReason === 'other' && {reasonOther: submit.withdrawReasonOther})
        });
      } catch (e) {
        withdrawResponse = e.response;
        console.error('Error while withdrawing application');
      }

      if (withdrawResponse.status === 204) {
        const dashboardMessage = {
          show: true,
          message: t('dashboard.app.withdraw.withdrawn'),
          type: 'ok'
        };
        setSubmit({...submit, confirmDialog: false});
        handleApplicationChange(STATES.WITHDRAWN, dashboardMessage);
        handleAllowedActions({canWithdraw: false});

      } else {
        const dashboardMessage = {
          show: true,
          message: t('dashboard.app.withdraw.error'),
          type: 'error'
        };
        setSubmit({...submit, confirmDialog: false});
        handleApplicationChange(null, dashboardMessage);
      }
    }
  };

  const handleCancel = () => {
    setSubmit({...submit, confirmDialog: false});
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <WithdrawDialog
        dialogState={submit}
        handleWithdraw={handleWithdraw}
        handleCancel={handleCancel}
        t={t}
      />
      <Link
        component="button"
        onClick={toggleWithdrawExpand}
        className={classes.link}
      >
        <NotInterestedIcon className={classes.icon} />{t('dashboard.app.withdraw.withdrawLink')}
        {submit.expandWithdraw ? <ArrowDropDownIcon className={classes.iconExpand} />
          : <ArrowLeftIcon className={classes.iconExpand} />}
      </Link>

      {submit.expandWithdraw ?
        <div className={`${classes.withdrawSection} ${submit.withdrawReasonError ? 'inline-error' : ''}`}>
          {submit.withdrawReasonError ? t('dashboard.app.withdraw.errorReason') : null}
          <Typography className={classes.otherLabel}>
            {t('dashboard.app.withdraw.selectReason')}
          </Typography>
          <FormControl className={classes.formControl}>
            <InputLabel>{t('dashboard.app.withdraw.withdrawReason')}</InputLabel>
            <Select
              native
              data-testid="select-withdraw-reason"
              value={submit.withdrawReason}
              onChange={handleReasonChange}
              className={classes.withdrawSelect}
            >
              <option value="" />
              {Object.keys(withdrawReasons).map(reason =>
                <option
                  value={reason}
                  key={reason}>{withdrawReasons[reason]}
                </option>)}
            </Select>
            {submit.showWithdrawReasonOther ?
              <Input
                name="withdrawReasonOther"
                showError={submit.withdrawOtherError}
                errorMessage={t('dashboard.app.withdraw.requiredField')}
                label={t('dashboard.app.withdraw.pleaseSpecify')}
                value={submit.withdrawReasonOther}
                handleChange={(e) => handleWithdrawOtherReasonChange(e)}
                maxLength={500}
              />
              : null}
            <Button
              variant="contained"
              color="primary"
              className={classes.withdrawButton}
              onClick={validate}
              data-testid="withdraw-button"
            >
              {t('dashboard.app.withdraw.withdraw')}
            </Button>
          </FormControl>
        </div>
        : null}
    </div>
  );
};

Withdraw.propTypes = {
  withdrawAllowed: PropTypes.bool,
  application: PropTypes.object,
  showSubmitMessage: PropTypes.func,
  handleApplicationChange: PropTypes.func,
  handleAllowedActions: PropTypes.func
};

export default Withdraw;
