import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {Typography} from '@material-ui/core';
import Timeline from './components/Timeline';
import CustomButton from '../../../components/form/CustomButton';
import appUtils from '../../../utils/application';
import AssessmentDetails from '../AppSingle/components/AssessmentDetails';
import Message from '../../../components/Message';
import PropTypes from 'prop-types';
import typography from '../../../theme/typography';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2)
  },
  sectionTitle: {
    ...typography.sectionTitle
  },
  sectionSubtitle: {
    ...typography.sectionSubtitle
  },
}));

const SlotAllocation = (props) => {

  const classes = useStyles();
  const [slots, setSlot] = useState({message: {}});
  const {application, hideSlotsChange, availableSlots} = props;
  const {t} = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventSlots = availableSlots.data[0];
        const slotsList = eventSlots.slots;
        const eventId = eventSlots.eventId;

        const slotsByDate = slotsList.reduce((slots, slot) => {
          const date = slot.date;
          if (!slots[date]) {
            slots[date] = [];
          }
          slots[date].push(slot);
          return slots;
        }, {});
        /* eslint-disable-next-line no-sequences*/
        const orderedSlots = Object.keys(slotsByDate).sort().reduce((slots, day) => (slots[day] = slotsByDate[day], slots), {});
        setSlot({...slots, available: orderedSlots, eventId});

      } catch (e) {
        setSlot({...slots, message: {text: appUtils.getSlotErrorMessage(e, t)}});
      }
    };
    /* eslint-disable react-hooks/exhaustive-deps*/
    fetchData();
  }, []);

  const handleSlotChange = async (e) => setSlot({...slots, selected: e.target.value});

  const submitSlotChange = async (e) => {

    e.preventDefault();
    setSlot({...slots, loading: true});

    const slotDateTime = slots.selected.split('_');
    const selectedSlot = {
      eventId: slots.eventId,
      date: slotDateTime[0],
      time: slotDateTime[1]
    };

    let message, type;

    try {
      await appUtils.allocateSlot(application.id, selectedSlot);
      message = t('dashboard.app.slotAllocation.changed');
      type = 'ok';

    } catch (e) {
      message = appUtils.getSlotErrorMessage(e, t);
      type = 'error';

    } finally {
      hideSlotsChange({message, type, show: true});
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item md={7} xs={12}>
          <div className={classes.root}>
            <Typography className={classes.sectionTitle}>
              {t('dashboard.app.slotAllocation.chooseNewDate')}
            </Typography>
            {slots.message.text ? <Message message={slots.message.text} /> : null}
            <form onSubmit={submitSlotChange}>
              <Timeline
                handleSlotChange={handleSlotChange}
                availableSlots={slots.available}
                loading={slots.loading} />
              <CustomButton
                content={{title: t('dashboard.app.slotAllocation.change')}}
                justify="flex-start"
                disabled={slots.loading || !slots.selected}
              />
            </form>
          </div>
        </Grid>
        <Grid item md={5} xs={12}>
          <AssessmentDetails
            application={application}
            title={t('dashboard.app.slotAllocation.currentDateTime')}
            subTitle={t('dashboard.app.slotAllocation.currentDateTimeSub')}
            showLink={false}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

SlotAllocation.propTypes = {
  hideSlotsChange: PropTypes.func,
  availableSlots: PropTypes.object,
  application: PropTypes.object
};
export default SlotAllocation;
