import React, {useEffect, useState} from 'react';
import productUtils from '../../../../../utils/products';
import PropTypes from 'prop-types';
import RadioButtonsGroup from '../../../../../components/form/RadioButtonGroup';
import Typography from '@material-ui/core/Typography';

const ExamSelect = (props) => {

  const {handleStepState, form, step} = props;
  const {slug} = step;
  const stepSlug = 'select-exam';

  const [examSelect, setExamSelect] = useState({exams: [], exam: form[stepSlug].data || ''});

  const fetchProducts = async () => {
    try {
      const availableExams = await productUtils.getProducts();
      setExamSelect({...examSelect, exams: availableExams.data});
    } catch (e) {
      console.error('Error while loading forces', e);
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (slug === stepSlug) fetchProducts();
  }, [slug]);

  const handleChange = event => handleStepState(stepSlug, {[event.target.name]: event.target.value});

  return (
    <React.Fragment>
      <Typography component="h2" variant="h2">
        Exams
      </Typography>
      <RadioButtonsGroup
        label="Select one exam you wish to apply for"
        showError={form[stepSlug].errors && form[stepSlug].errors.includes('exam')}
        name="exam"
        options={examSelect.exams}
        value={form[stepSlug].data.exam}
        handleChange={(e) => handleChange(e)}
      />
    </React.Fragment>
  );
};
ExamSelect.propTypes = {
  handleStepState: PropTypes.func,
  form: PropTypes.object,
  step: PropTypes.object,
};
export default ExamSelect;
