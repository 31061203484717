import {Typography} from '@material-ui/core';
import React from 'react';
import moment from 'moment';
import './timeline.css';
import ChangeProgress from './ChangeProgress';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import typography from '../../../../theme/typography';
import i18n from '../../../../i18n';

const useStyles = makeStyles(() => ({
  slotDate: {
    ...typography.contentBold
  },
}));

const Timeline = (props) => {

  const {availableSlots, loading} = props;
  moment.locale(i18n.language);
  const isAfternoon = (time) => {

    const slotTime = moment(time, 'HH:mm:ss');
    const noon = moment('12:00', 'HH:mm:ss');

    return slotTime.isAfter(noon);
  };
  const classes = useStyles();

  return (
    <div className="timeline">
      {loading ? <ChangeProgress /> : null}
      <ul onChange={props.handleSlotChange} className={loading ? 'loading' : null}>
        {availableSlots ? Object.keys(availableSlots).map(date => {
          availableSlots[date].sort((first, second) => first.time.localeCompare(second.time));
          return (
            <li key={date}>
              <fieldset>
                <legend>
                  <Typography
                    className={classes.slotDate}
                    data-testid={`slot-date-${date}`}>
                    {moment(date, 'YYYY-MM-DD').format('dddd, Do MMMM')}
                  </Typography>
                </legend>
                {availableSlots[date].map((slot, index) => {
                  return (
                    <React.Fragment key={`${date}_${slot.time}`}>
                      {index === 0 && isAfternoon(slot.time) ? <label className="not-available"></label> : null}
                      <input type="radio" name="slot" id={`${date}_${slot.time}`} value={`${date}_${slot.time}`} />
                      <label htmlFor={`${date}_${slot.time}`} data-testid="single-slot-time-label">{slot.time}</label>
                    </React.Fragment>
                  );
                })}
              </fieldset>
            </li>
          );
        }) : null}
      </ul>
    </div>);
};
Timeline.propTypes = {
  availableSlots: PropTypes.object,
  loading: PropTypes.bool,
  handleSlotChange: PropTypes.func
};
export default Timeline;
