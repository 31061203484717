import Button from '@material-ui/core/Button';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

const CustomButton = (props) => {

  return (
    <Grid
      container
      direction="row"
      justifyContent={props.justify}
      alignItems="baseline"
      className="nextButton"
    >
      <Button variant="contained" color="primary" type="submit" disabled={!!props.disabled}>
        {props.content.title}
      </Button>
    </Grid>);
};

CustomButton.propTypes = {
  content: PropTypes.object,
  justify: PropTypes.string,
  disabled: PropTypes.bool
};

export default CustomButton;
