import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {OPTIONAL_FIELDS} from '../../../utils/applicationConst';
import isValidDateOfBirth, {isDateOfBirthWithinAcceptableRange} from '../../../utils/date';
import {hasDomainCorrectionSuggestion, isValidEmail} from '../../../utils/email';
import {isValidNIN} from '../../../utils/string';
import Form from '../Form';
import formDefinition from './utils/formDefinition';
import getForms from './utils/forms';

const PromotionForm = () => {

  const {slug} = useParams();
  const {t} = useTranslation();

  const [form, setForm] = useState({
    activeStep: 0,
    ...(formDefinition()),
  });
  const [fieldValueOnWarn, setFieldValueOnWarn] = useState({});
  const activeStep = form.activeStep;

  const isNotOptionalField = (field) => !OPTIONAL_FIELDS.includes(field);

  const isFieldValueEmpty = (fieldValue) => !fieldValue || 0 === fieldValue.length;

  const validateStepForWarnings = (step) => {
    const {data: {email}} = form[step];
    const fieldWarning = {};

    if (email && fieldValueOnWarn.email !== email) {
      const domain = hasDomainCorrectionSuggestion(email);
      if (domain) {
        fieldWarning['email'] = `${t('common.emailDomainCorrectionWarning')} "${domain}"`;
        setFieldValueOnWarn({...fieldValueOnWarn, email});
      }
    }

    return fieldWarning;
  };

  const isValid = (step) => {
    const fieldError = {};
    const errorFields = Object.keys(form[step].data).filter(isNotOptionalField).filter(
      field => {
        if (field === 'firstLanguage') return form[slug].data['firstLanguageEnglish'] === 'no' && isFieldValueEmpty(form[slug].data[field]);
        if (field === 'gender') return form[slug].data['genderMatch'] === 'no' && 0 === form[slug].data[field].length;
        if (field === 'dateOfBirth') {
          if (!isValidDateOfBirth(form[slug].data.dateOfBirth)) return true;
          if(!isDateOfBirthWithinAcceptableRange(form[slug].data.dateOfBirth, 16, 80)) {
            fieldError.dateOfBirth = t('common.dateOfBirthAgeError');
            return true;
          }
          return false;
        }
        if (field === 'email') {
          return !isValidEmail(form[slug].data[field]);
        }
        if (field === 'nationalInsuranceNumber') {
          return !isValidNIN(form[slug].data[field]);
        } else {
          return isFieldValueEmpty(form[step].data[field]);
        }
      }
    );
    const fieldWarning = validateStepForWarnings(step);
    setForm({
      ...form,
      [step]: {...form[step], errors: [...errorFields], fieldError, fieldWarning},
    });

    return errorFields.length === 0 && Object.keys(fieldWarning).length === 0;
  };

  return (
    <Form title="Apply for an exam"
      isValid={isValid}
      getForms={getForms}
      activeStep={activeStep}
      type="promotion"
      slug={slug}
      form={form}
      setForm={setForm}
      formDefinition={formDefinition} />
  );
};
export default PromotionForm;
