import {closest, distance} from 'fastest-levenshtein';

const POPULAR_DOMAINS = [
  'aol.com',
  'bt.com',
  'btinternet.com',
  'gmail.com',
  'googlemail.com',
  'hotmail.co.uk',
  'hotmail.com',
  'icloud.com',
  'live.co.uk',
  'live.com',
  'mail.com',
  'me.com',
  'msn.com',
  'ntlworld.com',
  'outlook.com',
  'sky.com',
  'tiscali.co.uk',
  'virgin.net',
  'virginmedia.com',
  'yahoo.com',
  'yahoo.co.uk',
  'ymail.com',
  // Force domains
  'Avonandsomerset.pnn.police.uk',
  'Avonandsomerset.police.uk',
  'Bedfordshire.pnn.police.uk',
  'Bedfordshire.police.uk',
  'bristolport.co.uk',
  'BTP.pnn.police.uk',
  'BTP.police.uk',
  'Cambs.pnn.police.uk',
  'Cambs.police.uk',
  'Cheshire.pnn.police.uk',
  'Cheshire.police.uk',
  'city-of-london.pnn.police.uk',
  'city-of-london.police.uk',
  'Cleveland.pnn.police.uk',
  'Cleveland.police.uk',
  'cnc.pnn.police.uk',
  'cnc.police.uk',
  'College.pnn.police.uk',
  'College.police.uk',
  'Cumbria.pnn.police.uk',
  'Cumbria.police.uk',
  'Derbyshire.pnn.police.uk',
  'Derbyshire.police.uk',
  'devonandcornwall.pnn.police.uk',
  'devonandcornwall.police.uk',
  'Dorset.pnn.police.uk',
  'Dorset.police.uk',
  'doverport.co.uk',
  'Durham.pnn.police.uk',
  'Durham.police.uk',
  'dyfed-powys.pnn.police.uk',
  'dyfed-powys.pnn.police.uk',
  'Essex.pnn.police.uk',
  'Essex.police.uk',
  'Gloucestershire.pnn.police.uk',
  'Gloucestershire.police.uk',
  'gmp.pnn.police.uk',
  'gmp.police.uk',
  'gov.im',
  'guernsey.pnn.police.uk ',
  'Gwent.pnn.police.uk',
  'Gwent.police.uk',
  'Hants.pnn.police.uk',
  'Hants.police.uk',
  'Herts.pnn.police.uk',
  'Herts.police.uk',
  'homeoffice.gov.uk',
  'Humberside.pnn.police.uk',
  'Humberside.police.uk',
  'Kent.pnn.police.uk',
  'Kent.police.uk',
  'Lancashire.pnn.police.uk',
  'Lancashire.police.uk',
  'Leicestershire.pnn.police.uk',
  'Leicestershire.police.uk',
  'Lincs.pnn.police.uk',
  'Lincs.police.uk',
  'Merseyside.pnn.police.uk',
  'Merseyside.police.uk',
  'Met.pnn.police.uk',
  'Met.police.uk',
  'mod.gov.uk',
  'nca.gov.uk',
  'Norfolk.pnn.police.uk',
  'Norfolk.police.uk',
  'Northants.pnn.police.uk',
  'Northants.police.uk',
  'Northumbria.pnn.police.uk',
  'Northumbria.police.uk',
  'Northyorkshire.pnn.police.uk',
  'Northyorkshire.police.uk',
  'Nottinghamshire.pnn.police.uk',
  'Nottinghamshire.police.uk',
  'nthwales.pnn.police.uk',
  'nthwales.police.uk',
  'portofliverpool.police.uk',
  'potll.com',
  'south-wales.police.uk',
  'Southyorks.pnn.policeuk',
  'Southyorks.police.uk',
  'Staffordshire.pnn.police.uk',
  'Staffordshire.police.uk',
  'Suffolk.pnn.police.uk',
  'Suffolk.police.uk',
  'Surrey.pnn.police.uk',
  'Surrey.police.uk',
  'Sussex.pnn.police.uk',
  'Sussex.police.uk',
  'thamesvalley.pnn.police.uk',
  'thamesvalley.police.uk',
  'Warwickshire.pnn.police.uk',
  'Warwickshire.police.uk',
  'Westmercia.pnn.police.uk',
  'Westmercia.police.uk',
  'west-midlands.pnn.police.uk',
  'west-midlands.police.uk',
  'Westmidlands.police.uk',
  'Westyorkshire.pnn.police.uk',
  'Westyorkshire.police.uk',
  'Wiltshire.pnn.police.uk',
  'Wiltshire.police.uk',
].map((domain) => domain.toLowerCase());

export const hasDomainCorrectionSuggestion = (email) => {
  if (!isValidEmail(email))
    return false;

  const domain = email.split('@').pop().toLowerCase();
  if (POPULAR_DOMAINS.includes(domain))
    return false;

  const similarDomains = POPULAR_DOMAINS.filter((p) => {
    const dist = distance(p, domain);
    return dist >= 1 && dist <= 3;
  });
  return similarDomains.length && closest(domain, similarDomains);
};

export const isValidEmail = (email) => {
  const regex = /^[!\x23-\x27*+\-\x2F-\x39=?\x41-\x5A\x5E-\x7E]+(?:\.[!\x23-\x27*+\-\x2F-\x39=?\x41-\x5A\x5E-\x7E]+)*@((?:[a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;
  return regex.test(String(email));
};
