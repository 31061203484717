import React, {useEffect, useState} from 'react';
import {Description as FileIcon} from '@material-ui/icons';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import moment from 'moment';
import 'moment/locale/cy';
import typography from '../../../../theme/typography';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  CircularProgress,
  Divider
} from '@material-ui/core';
import appUtils from '../../../../utils/application';
import Button from '@material-ui/core/Button';
import {useTranslation} from 'react-i18next';
import i18n from '../../../../i18n';

const useStyles = makeStyles(() => ({
  root: {},
  paper: {
    padding: 0
  },
  divider: {
    marginTop: 15
  },
  tableHeading: {
    padding: 0
  },
  icon: {
    position: 'relative',
    top: 5
  },
  link: {
    fontSize: 17
  },
  sectionTitle: {
    ...typography.sectionTitle
  },
  sectionSubtitle: {
    ...typography.sectionSubtitle
  },
  eventDetailsText: {
    textAlign: 'right',
    ...typography.bold
  }
}));

const AssessmentDetails = (props) => {

  const [assessment, setAssessment] = useState({loading: true, changeSlotLoading: false, allocationData: {}});
  const {renderChangeSlot} = props;
  const {t} = useTranslation();
  moment.locale(i18n.language);

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    const fetchData = async () => {

      try {
        const allocationResponse = await appUtils.getAssessmentDetails(props.application.id);
        setAssessment({...assessment, allocationData: allocationResponse.data, loading: false});
      } catch (e) {
        setAssessment({...assessment, allocationData: {}, loading: false});
      }
    };
    fetchData();
  }, [props.application.id]);

  const classes = useStyles();
  const getSlots = () => {
    return appUtils.getAvailableSlots(props.application.id);
  };

  return (
    <React.Fragment>
      {assessment.loading
        ? <CircularProgress data-testid='loading' />
        : (<div className={classes.root} data-testid="assessment-details">
          <Table className={classes.table} aria-label="simple table">
            <TableHead className={classes.table}>
              <TableRow>
                <TableCell colSpan={2} className={classes.tableHeading}>
                  <Typography className={classes.sectionTitle}>
                    {props.title}
                  </Typography>
                  <Typography className={classes.sectionSubtitle}>
                    {props.subtitle}
                  </Typography>
                  <Divider className={classes.divider} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key="detailsDate">
                <TableCell component="th">{t('dashboard.app.event.date')}</TableCell>
                <TableCell
                  className={classes.eventDetailsText}>
                  {moment(assessment.allocationData.date, 'YYYY-MM-DD').format('dddd, MMMM Do YYYY')}
                </TableCell>
              </TableRow>
              <TableRow key="detailsTime">
                <TableCell component="th">{t('dashboard.app.event.time')}</TableCell>
                <TableCell className={classes.eventDetailsText}>{assessment.allocationData.time}</TableCell>
              </TableRow>
              <TableRow key="location">
                <TableCell component="th">{t('dashboard.app.event.location')}</TableCell>
                <TableCell className={classes.eventDetailsText}>
                  {
                    Object.values(assessment.allocationData.location).filter(e => e !== null).map(addressLine => {
                      return <Typography key={addressLine} className={classes.eventDetailsText}>{addressLine}</Typography>;
                    })
                  }
                </TableCell>
              </TableRow>
              {assessment.allocationData.locationDocument ?
                <TableRow key="locationLink">
                  <TableCell colSpan={2}>
                    <a target='_blank' rel="noopener noreferrer" href={`/document?id=${assessment.allocationData.locationDocument}`}>
                      <FileIcon className={classes.icon} />{t('dashboard.app.event.locationLink')}
                    </a>
                  </TableCell>
                </TableRow>
                : null}
              {props.showLink ?
                <TableRow key="changeSlot">
                  <TableCell colSpan={2} align="left">
                    {!assessment.changeSlotLoading ?
                      (<Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={async () => {
                            setAssessment({...assessment, changeSlotLoading: true});
                            try {
                              const availableSlots = await getSlots();
                              window.location.state = {availableSlots};
                              renderChangeSlot(availableSlots);
                            } catch (e) {
                              props.dashboardMessage({
                                show: true,
                                message: appUtils.getSlotErrorMessage(e, t),
                                type: 'error'
                              });
                              setAssessment({...assessment, changeSlotLoading: false});
                            }
                          }}
                        >
                          {t('dashboard.app.event.change')}
                        </Button>
                      </Typography>) : <CircularProgress />
                    }
                  </TableCell>
                </TableRow>
                : null}
            </TableBody>
          </Table>
        </div>)}
    </React.Fragment>
  );
};

AssessmentDetails.propTypes = {
  className: PropTypes.string,
  application: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showLink: PropTypes.bool,
  dashboardMessage: PropTypes.func,
  renderChangeSlot: PropTypes.func
};

export default AssessmentDetails;
