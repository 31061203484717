import React from 'react';
import PropTypes from 'prop-types';
import RadioButtonsGroup from '../../../../components/form/RadioButtonGroup';
import Typography from '@material-ui/core/Typography';

const SexualOrientation = (props) => {

  const {handleStepState, form} = props;
  const stepSlug = 'sexual-orientation';

  const handleChange = event => handleStepState(stepSlug, {[event.target.name]: event.target.value});

  return (
    <React.Fragment>
      <Typography component="h2" variant="h2">
        Sexual orientation
      </Typography>
      <RadioButtonsGroup
        label="Which of the following options best describes your sexual orientation?"
        showError={form[stepSlug].errors && (form[stepSlug].errors.includes('sexualOrientation') || form[stepSlug].errors.includes('sexualOrientationOther'))}
        errorMsg={'If you do not wish to answer this question, please select ‘Prefer not to say’'}
        name="sexualOrientation"
        options={[
          {id: 'heterosexual', name: 'Straight / Heterosexual'},
          {id: 'gayLesbian', name: 'Gay or Lesbian'},
          {id: 'bisexual', name: 'Bisexual'},
          {id: 'other', name: 'Other'},
          {id: 'preferNotToSay', name: 'Prefer not to say'}
        ]}
        value={form[stepSlug].data.sexualOrientation}
        other={form[stepSlug].data.sexualOrientationOther}
        nameOther="sexualOrientationOther"
        labelOther="Please describe (optional)"
        handleChange={(e) => handleChange(e)}
        showTextfield="other"
      />
    </React.Fragment>
  );
};
SexualOrientation.propTypes = {
  handleStepState: PropTypes.func,
  form: PropTypes.object,
  step: PropTypes.object,
};
export default SexualOrientation;
