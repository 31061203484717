import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import {List, Button, Link} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import logo from '../../../assets/CoP_MasterLogo_RGB.svg';
import Profile from './Profile';
import config from '../../../config/config';
import palette from '../../../theme/palette';
import {
  Dashboard as DashboardIcon,
  Input as Logout
} from '@material-ui/icons';
import {getLogoutUrl} from '../../../utils/auth';
import ChangeLanguage from '../../../components/ChangeLanguage';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
    },
    backgroundColor: 'white',
  },
  menuButton: {
    margin: '6px 10px 0 0',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    color: palette.button.secondary.color,
    float: 'right'
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logo: {
    width: 125,
    marginTop: 3
  },
  topbarContainer: {
    width: '100%',
  },
  navbarText: {
    fontSize: 14,
    marginLeft: 10,
    textTransform: 'none',
    color: palette.text.primary
  },
  button: {
    color: palette.button.secondary.color,
  },
  logoutIcon: {
    display: 'none',
    color: palette.button.secondary.color,
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    }
  },
  changeLanguageLink: {
    position: 'absolute',
    top: '30%',
    [theme.breakpoints.up('sm')]: {
      right: '10%'
    },
    [theme.breakpoints.down('sm')]: {
      right: '20%'
    }
  }
}));

const DrawerNav = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navbarMenu = [
    {icon: <DashboardIcon />, text: t('dashboard.drawer.applications'), url: '/applications'}
  ];

  const redirectLogout = () => {
    window.location.assign(getLogoutUrl(config));
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Profile />
      <Divider />
      <List>
        {navbarMenu.map(menuItem =>
          <ListItem key={menuItem.text}>
            <Button href={menuItem.url} className={classes.button}>
              {menuItem.icon}
              <div className={classes.navbarText}>{menuItem.text}</div>
            </Button>
          </ListItem>
        )}
        {mobileOpen ? (
          <ListItem key="Logout">
            <Button onClick={redirectLogout} className={classes.button} data-testid="logout-mobile">
              <Logout />
              <div className={classes.navbarText}>{t('dashboard.drawer.logout')}</div>
            </Button>
          </ListItem>) : null}
      </List>
      <Divider />
    </div>
  );

  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <div className={classes.topbarContainer}>
            <img
              alt="CoP"
              src={logo}
              className={classes.logo}
            />
            <div className={classes.changeLanguageLink}><ChangeLanguage /></div>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
              data-testid="drawer"
            >
              <MenuIcon />
            </IconButton>
          </div>
          <Link
            title="Sign out"
            href="#"
            onClick={redirectLogout}
            data-testid="logout-toolbar">
            <Logout color="primary" className={classes.logoutIcon} /></Link>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="candidate menu">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </React.Fragment>);
};

export default DrawerNav;
