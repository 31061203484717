import getFormDefinition from './formDefinition';
import moment from 'moment';
import { flatten, boolenify } from '../../utils/fieldMapping';

const EXCLUDE_STEPS = ['confirmation'];

const formDefinition = getFormDefinition();

const transformOut = (formData) => {

  const stepsData = Object.keys(formDefinition)
    .reduce((steps, stepId) => {
      if (EXCLUDE_STEPS.includes(stepId)) {
        // Drop excluded steps
        return steps;
      }

      if (!formData[stepId]?.data) {
        // Drop steps without data
        return steps;
      }

      return {
        ...steps,
        [stepId]: formData[stepId].data,
      };
    }, {});

  const flattened = flatten(stepsData);
  delete flattened.completed;
  delete flattened.ethnicOriginParent;
  delete flattened.academicAttainmentParent;
  delete flattened.englishAcademicAttainmentParent;
  delete flattened.activeStep;

  ['reasonableAdjustment', 'nationalSchemesFastTrack', 'nationalSchemesDirectEntry'].forEach(f => {
    if (flattened[f]) flattened[f] = boolenify(flattened[f]);
  });

  if (flattened.dateOfBirth) flattened.dateOfBirth = moment(flattened.dateOfBirth, 'YYYY-MM-DD').format('YYYY-MM-DDT00:00:00.000') + 'Z';

  return flattened;
};

export {transformOut};
