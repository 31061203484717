import TextField from '@material-ui/core/TextField';
import React from 'react';
import {makeStyles} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  standard_input: {
    marginTop: 5
  }
}));

const Input = (props) => {

  const classes = useStyles();

  const deriveClassName = () => {
    if (props.showError)
      return 'inline-error';

    if(props.showWarning)
      return 'inline-warning';

    return '';
  };

  return (
    <React.Fragment>
      {props.title ? <label htmlFor={props.name}>{props.title}</label> : null}
      <div className={deriveClassName()}>{props.showError ? props.errorMessage : null}
        <TextField
          id={props.name}
          name={props.name}
          variant="outlined"
          placeholder={props.placeholder ? props.placeholder : ''}
          fullWidth
          error={props.showError}
          className={classes.standard_input}
          value={props.value}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          label={props.label}
          multiline={props.maxLength > 200}
          minRows={props.maxLength > 200 ? 4 : 1}
          inputProps={{
            maxLength: props.maxLength,
          }}
          inputRef={props.inputRef}
        />
        {!props.showError && props.showWarning ? props.warningMessage : null}
      </div>
    </React.Fragment>
  );
};

Input.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
  showWarning: PropTypes.bool,
  warningMessage: PropTypes.string,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  label: PropTypes.string,
  inputRef: PropTypes.func
};

export default Input;
