import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import FormHeader from '../../components/FormHeader';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(() => ({
  applyButton: {
    marginTop: 10
  },
  root: {
    maxWidth: '80%',
    margin: '0 auto'
  },
  section: {
    marginTop: 15
  }
}));

const SelfRegisterLanding = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormHeader text="Apply for an exam" />
      <div className={classes.section}>
        <Typography paragraph={true}>
          Welcome to the candidate portal, where you can apply for your next exam. Please make sure that you meet any eligibility criteria before you apply.
        </Typography>
        <Typography paragraph={true}>
          When you apply, please check that all your details are entered correctly. Your link to access the exam will be sent to the
          email address that you enter at registration; please ensure this is correct or you will not be able to access your exam.
          You will need to re-enter your date of birth, email address and National Insurance number to access your results after the exam.
          Please ensure these are entered correctly at registration or this will delay getting your results.
        </Typography>
        <Typography paragraph={true}>
          Please also ensure that you apply for the correct exam and with the correct force. Any errors could result in your application
          being delayed and/or not processed for the exam.
        </Typography>
        <Typography paragraph={true}>
          If you have any queries, please speak to your force exams contact.
        </Typography>
      </div>
      <div className={classes.section}>
        <Typography component='h2' variant='h2'>
          Diversity, equality and inclusion
        </Typography>
        <div className={classes.section}>
          <Typography paragraph={true}>
            The College and wider policing family are pro-inclusion, we celebrate diversity and are constantly striving towards equality of opportunity. We want to understand the make-up of our workforce in order to identify and address any discrimination, and to ensure equality of opportunity and access for all.
          </Typography>
          <Typography paragraph={true}>
            We therefore ask for your help to gather this information so that we understand the accurate make-up of our workforce.
            This will then shape the future planning of our diversity, equality and inclusion work.
          </Typography>
          <Typography paragraph={true}>
            Once you have clicked the ‘Apply’ button below, you will be asked a series of questions about your personal characteristics.
            Providing this information is voluntary, it will not form any part of your assessment and will only be used for monitoring purposes.
            You can find out how we process your data in our&nbsp;
            <a href="/privacy-policy" target="_blank" className="MuiLink-root">privacy notice</a>.
          </Typography>
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/promotion/select-exam"
        className={classes.applyButton}>
        Apply
      </Button>
    </div>
  );
};
export default SelfRegisterLanding;
