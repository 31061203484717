import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import PropTypes from 'prop-types';
import Input from './Input';

const useStyles = makeStyles(() => ({
  question: {
    marginBottom: 15,
    paddingTop: 15,
    fontWeight: 'bold',
    fontSize: 16
  },
  formControl: {
    marginTop: 24
  },
  otherInput: {
    marginLeft: 28
  },
  fieldset: {
    width: '100%', //IE fix
    maxWidth: 'inherit'
  }
}));

const RadioButtonsGroup = (props) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.fieldset}>
      {props.label ?
        <FormLabel
          component="legend"
          className={classes.question}
          data-testid={`label-${props.name}`}>{props.label}
        </FormLabel>
        : null}
      {props.info ? props.info : null}
      <div className={props.showError ? 'inline-error' : ''}>
        {props.showError ? <p>{props.errorMsg || 'This question is mandatory, an answer is required for you to continue'}</p> : ''}
        <RadioGroup aria-label={props.name} name={props.name} value={props.value} onChange={props.handleChange}>
          {props.options.map(option =>
            <React.Fragment key={option.id}>
              <FormControlLabel
                value={option.id}
                control={<Radio color="primary" />}
                label={option.name}
                data-testid={`${props.name}-select-option`}
              />
              {option.id === props.showTextfield && props.value === props.showTextfield
                ? <div className={classes.otherInput}>
                  <Input
                    name={props.nameOther}
                    value={props.other}
                    handleChange={props.handleChange}
                    label={props.labelOther ? props.labelOther : 'Please specify'}
                    variant="outlined"
                    maxLength={props.maxLength ? props.maxLength : 100}
                  />
                </div>
                : null}
            </React.Fragment>
          )}
        </RadioGroup>
      </div>
    </FormControl>
  );
};
RadioButtonsGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelOther: PropTypes.string,
  showError: PropTypes.bool,
  errorMsg: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  options: PropTypes.array,
  other: PropTypes.string,
  showTextfield: PropTypes.string,
  nameOther: PropTypes.string,
  info: PropTypes.object,
  maxLength: PropTypes.number
};
export default RadioButtonsGroup;
