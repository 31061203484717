import Link from '@material-ui/core/Link';
import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { renewTokens } from '../utils/auth';
import {Trans, useTranslation} from 'react-i18next';

const useQuery = () => new URLSearchParams(useLocation().search);

const OAuth2Callback = () => {
  const [error, setError] = useState();
  const [unauthorised, setUnauthorised] = useState(false);
  const [redirection, setRedirection] = useState();
  const {t} = useTranslation();
  const query = useQuery();
  const code = query.get('code');
  const state = query.get('state');

  useEffect(() => {
    if (!code) {
      console.error('Failed to complete OAuth2 flow because of: no `code` provided');
      return setError(true);
    }

    renewTokens(code)
      .then(() => setRedirection(state || '/applications'))
      .catch((error) => {
        if (error && error.response && error.response.status === 403) {
          setUnauthorised(true);
        } else {
          console.error('Failed to complete OAuth2 flow because of: ', error);
          setError(true);
        }
      });
  }, [code, state]);

  if (unauthorised) {
    return (
      <React.Fragment>
        <Trans i18nKey={'common.unauthorisedAccess'}>
          <Link href='mailto:aims.support@college.police.uk' />
        </Trans>
      </React.Fragment>
    );
  }

  if (error) {
    return (
      <React.Fragment>
        {t('common.authenticationFailure')}
      </React.Fragment>
    );
  }

  if (redirection) {
    return (<Redirect to={redirection} />);
  }

  return (
    <React.Fragment>
      <span>{t('common.authenticating')}</span>
      <LinearProgress />
    </React.Fragment>
  );
};

export default OAuth2Callback;
