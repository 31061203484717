import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Cookie from 'js-cookie';
import {useTranslation} from 'react-i18next';
import {Link} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';

const COOKIE_NAME = 'cookie_consent';
const getCookie = () => Cookie.get(COOKIE_NAME) || 'show';
const setCookie = (cookie) => Cookie.set(COOKIE_NAME, cookie, {path: '/', expires: 365});

const useStyles = makeStyles(() => ({
  root: {},
  snackbar: {
    margin: 4,
    color: 'white'
  },
}));

const CookieConsent = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(getCookie());
  const {t} = useTranslation();

  useEffect(() => {
    setCookie(getCookie());
  }, [open]);

  const acceptCookies = () => {
    setOpen(false);
    setCookie('accepted');
  };

  const closeConsent = (
    <Button
      variant="contained"
      color="primary"
      onClick={acceptCookies}
    >
      {t('common.close')}
    </Button>
  );

  return (
    <React.Fragment>
      {(open && getCookie() !== 'accepted')
        ? <div className={classes.root} data-testid="cookie-consent">
          <Snackbar
            open={true}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            className={classes.snackbar}
            message={<p>{t('common.cookieConsent')}<Link href="/privacy-policy">{t('common.cookiesPolicy')}</Link>.</p>}
            action={closeConsent}
          />
        </div>
        : null}
    </React.Fragment>
  );
};
export default CookieConsent;
