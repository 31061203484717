import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Button from '@material-ui/core/Button';

const Error = (props) => {
  const {t} = useTranslation();
  let title = props.title || t('pages.error.unknownErrorTitle');
  let message = props.message || t('pages.error.unknownErrorMsg');
  let showRetry = false;

  if (window.location && window.location.state) {
    title = (window.location.state.title) ? window.location.state.title : title;
    message = (window.location.state.message) ? window.location.state.message : message;
    showRetry = window.location.state.retry;
  }
  const goBack = () => window.history.back();
  return (
    <React.Fragment>
      <Typography component="h1" variant="h1" align="center" style={{marginBottom: 50}}>
        {title}
      </Typography>
      <Typography align="center" paragraph={true}>
        {message}
      </Typography>
      {showRetry ?
        <Typography align="center" paragraph={true}>
          <Button
            variant="contained"
            color="primary"
            onClick={goBack}
          >
            {t('pages.error.retry')}
          </Button>
        </Typography>
        : null}
    </React.Fragment>
  );
};

Error.propTypes = {
  location: PropTypes.object,
  title: PropTypes.string,
  message: PropTypes.string
};
export default Error;
