import React, {useState, useEffect} from 'react';
import i18n from '../i18n';
import {Link} from '@material-ui/core';

const ChangeLanguage = () => {

  const [langToggle, setLangToggle] = useState(i18n.language);

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    langToggle === 'en'
      ? setLangToggle('cy')
      : setLangToggle('en');
  }, []);

  const toggleLang = () => {

    const newLanguage = () => i18n.language === 'en' ? 'cy' : 'en';
    i18n
      .changeLanguage(newLanguage())
      .then((t) => {
        t('key');
      });
    setLangToggle(newLanguage);
  };

  return (
    <Link
      title="Change language"
      href="#"
      onClick={toggleLang}
      data-testid="changeLanguage-toolbar">
      {langToggle === 'cy' ? 'Cymraeg' : 'English'}</Link>
  );
};

export default ChangeLanguage;
