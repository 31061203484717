import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

const WithdrawDialog = (props) => {

  const {dialogState, handleWithdraw, handleCancel, t} = props;

  return (
    <Dialog
      open={dialogState.confirmDialog}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid="withdraw-dialog"
    >
      <DialogTitle id="alert-dialog-title">{t('dashboard.app.withdraw.dialogTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('dashboard.app.withdraw.dialogText')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary" autoFocus>
          {t('dashboard.app.withdraw.dialogCancel')}
        </Button>
        <Button onClick={(e) => handleWithdraw(e, 'confirm')} color="primary">
          {t('dashboard.app.withdraw.dialogConfirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

WithdrawDialog.propTypes = {
  handleWithdraw: PropTypes.func,
  handleCancel: PropTypes.func,
  dialogState: PropTypes.object,
  t: PropTypes.func
};

export default WithdrawDialog;
