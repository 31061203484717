import axios from 'axios';

const VAULT_TTL_LEEWAY = 5000; // 5 seconds leeway
const ACCESS_TOKEN = 'access_token';
const ID_TOKEN = 'id_token';

const redirectUri = () => `${document.location.origin}/oauth2`;

const tokensVault = (() => {
  const vault = {};
  const get = (key) => vault[key]?.expiresAt > Date.now() + VAULT_TTL_LEEWAY ? vault[key].value : undefined;
  const set = (key) => (value, expiresAt) => (vault[key] = {value, expiresAt}) && value;
  return {get, set};
})();

export const getAccessToken = () => tokensVault.get(ACCESS_TOKEN);
const setAccessToken = tokensVault.set(ACCESS_TOKEN);

export const getIdToken = () => tokensVault.get(ID_TOKEN);
const setIdToken = tokensVault.set(ID_TOKEN);

export const renewTokens = async (code) => {
  const res = await axios.get(`${window.location.origin}/oauth2/tokens`, {
    params: {
      ...(code && {code}),
      redirectUri: redirectUri(),
    }
  });

  const {
    access_token,
    id_token,
    expires_in,
    expires_at, // TODO Deprecated in favour of `expires_in`, remove in next major release
  } = res.data;

  const expiryTimestamp = expires_in ? (Date.now() + (expires_in * 1000)) : expires_at;

  setAccessToken(access_token, expiryTimestamp);
  setIdToken(id_token, Date.now() + 999999 * 1000);
};

const toBoolean = (promise) => promise.then(() => true).catch((e) => {
  if (e.response && e.response.status === 403) {
    throw e;
  }
  console.error(e);
  return false;
});

export const hasSession = async () => !!(getAccessToken() && getIdToken()) || toBoolean(renewTokens());

export const getLoginUrl = (config) => (state) => config.OAUTH2_LOGIN_URL.replace(/\[\[(\w+)\]\]/g, (_, key) => ({
  client_id: config.OAUTH2_CLIENT_ID,
  redirect_uri: redirectUri(),
  state: encodeURIComponent(state),
}[key]));

export const getLogoutUrl = (config) => config.OAUTH2_LOGOUT_URL.replace(/\[\[(\w+)\]\]/g, (_, key) => ({
  redirect_uri: `${document.location.origin}/logout`,
  id_token: getIdToken(),
}[key]));
