import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import {makeStyles} from '@material-ui/styles';
import {Grid, Divider, Typography} from '@material-ui/core';
import {
  Description as FileIcon
} from '@material-ui/icons';
import {Trans, useTranslation} from 'react-i18next';
import typography from '../../../../theme/typography';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 30
  },
  paper: {
    textAlign: 'center',
    boxShadow: 'none'
  },
  divider: {
    marginTop: 15,
    marginBottom: 15
  },
  icon: {
    position: 'relative',
    top: 5
  },
  link: {
    fontSize: 17
  },
  linkGrid: {
    textAlign: 'center'
  },
  sectionTitle: {
    ...typography.sectionTitle
  }
}));

const NationalSiftResults = props => {
  const {application} = props;
  const classes = useStyles();
  const {t} = useTranslation();
  const displaySiftResult = () => application.siftFeedbackReport;
  const displaySiftPassApplication = () => application.siftPassApplication;
  return (
    <div className={classes.root} data-testid="national-sift-results">
      <Typography className={classes.sectionTitle}>
        {t('dashboard.app.nationalSiftResults.title')}
      </Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          {displaySiftResult() ?
            <ul>
              <li><b>{t('dashboard.app.nationalSiftResults.result')}:</b> {application.siftResult}</li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href={`/document?id=${application.siftFeedbackReport}`} data-testid="national-sift-feedback-report">
                  <FileIcon className={classes.icon} />{t('dashboard.app.nationalSiftResults.feedbackReport.title')}
                </a>
              </li>
            </ul>
            : null}
          {displaySiftPassApplication() ?
            <Typography paragraph={true} align="left">
              <Trans i18nKey={'dashboard.app.nationalSiftResults.passedApplication'}>
                <Link href={`/applications/${application.siftPassApplication}`} />
              </Trans>
            </Typography>
            : null}
        </Grid>
      </Grid>
    </div>
  );
};

NationalSiftResults.propTypes = {
  application: PropTypes.object,
  dashboardMessage: PropTypes.func
};

export default NationalSiftResults;
