import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import appUtils from '../utils/application';

const useQuery = () => new URLSearchParams(useLocation().search);

const Document = () => {
  const [error, setError] = useState();
  const [documentDownloadUrl, setDocumentDownloadUrl] = useState();
  const {t} = useTranslation();
  const query = useQuery();
  const docId = query.get('id');

  useEffect(() => {
    if (!docId) {
      console.error('Failed to download Document because of: no `id` provided');
      return setError(true);
    }
    
    appUtils.getDownloadUrl(docId)
      .then((documentUrl) => {
        if (documentUrl && documentUrl.data && documentUrl.data.download_url) {
          setDocumentDownloadUrl(documentUrl.data.download_url);
        } else {
          throw new Error('Error while retrieving the download url');
        }
      })
      .catch((error) => {
        console.error('Failed to complete document open because of: ', error);
        setError(true);
      });
  }, [docId]);

  if (error) {
    return (
      <React.Fragment>
        {t('common.documentOpenFailure')}
      </React.Fragment>
    );
  }

  if (documentDownloadUrl) {
    window.location.assign(documentDownloadUrl);
  }

  return (
    <React.Fragment>
      <span>{t('common.downloading')}</span>
      <CircularProgress />
    </React.Fragment>
  );
};

export default Document;
