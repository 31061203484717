import {Checkbox, FormGroup} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

const useStyles = makeStyles(() => ({
  question: {
    marginBottom: 15,
    paddingTop: 15,
    fontWeight: 'bold',
    fontSize: 16
  },
  formControl: {
    marginTop: 24
  },
  fieldset: {
    width: '100%' //IE fix
  }
}));

const FormMultiSelect = (props) => {
  const classes = useStyles();
  const {handleChange, info, label, name, options, showError, value} = props;
  const [selectedValues, setSelectedValues] = useState(value || []);

  const handleSelection = (event) => {
    const {checked, value} = event.target;
    if (checked) {
      setSelectedValues((prevValues) => [...prevValues, value]);
    } else {
      setSelectedValues(selectedValues.filter(prevValue => prevValue !== value));
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    handleChange({target: {name, value: selectedValues}});
  }, [selectedValues]);

  return (
    <FormControl component="fieldset" className={classes.fieldset}>
      {label ?
        <FormLabel
          component="legend"
          className={classes.question}
          data-testid={`label-${name}`}>{label}
        </FormLabel>
        : null}
      {info ? info : null}
      <div className={showError ? 'inline-error' : ''}>
        {showError ? <p>This question is mandatory, an answer is required for you to continue</p> : ''}
        <FormGroup aria-label={name} onChange={handleSelection}>
          {options.map(option =>
            <FormControlLabel
              key={option.id}
              value={option.id}
              control={<Checkbox color="primary" checked={selectedValues.includes(option.id)} />}
              label={option.name}
              data-testid={`${name}-select-option`}
            />
          )}
        </FormGroup>
      </div>
    </FormControl>
  );
};
FormMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  showError: PropTypes.bool,
  value: PropTypes.array,
  handleChange: PropTypes.func,
  options: PropTypes.array,
  other: PropTypes.string,
  info: PropTypes.object,
  maxLength: PropTypes.number
};
export default FormMultiSelect;
