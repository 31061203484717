import {Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/styles';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import typography from '../../theme/typography';

const useStyles = makeStyles(() => ({
  root: {},
  sectionTitle: {
    ...typography.sectionTitle
  },
  cellText: {
    verticalAlign: 'top',
    border: '1px solid  black'
  },
  visibilityColumn: {
    width: '20%'
  }
}));

const style = {
  overflow: {
    overflowX: 'auto',
  }
};

const PrivacyPolicy = () => {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <React.Fragment>
      <Typography component="h2" variant="h2" align="center">
        {t('pages.privacyPolicy.title')}
      </Typography>

      <br />

      <Typography component="h3" variant="h3" align="center">
        {t('pages.privacyPolicy.policyTitle')}
      </Typography>

      <br />

      <Typography component="h3" variant="h3" align="left">
        {t('pages.privacyPolicy.aboutUsTitle')}
      </Typography>
      <Typography paragraph={true}>
        <Trans i18nKey={'pages.privacyPolicy.aboutUsParagraph'}>
          <Link href='https://ico.org.uk/' target='_blank' />
        </Trans>
      </Typography>

      <Typography component="h3" variant="h3" align="left">
        {t('pages.privacyPolicy.informationTitle')}
      </Typography>
      <Typography paragraph={true}>
        {t('pages.privacyPolicy.informationParagraph')}
      </Typography>

      <div style={style.overflow}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellText}>
                <span />
              </TableCell>
              <TableCell className={classes.cellText}>
                <Typography className={classes.sectionTitle}>
                  {t('pages.privacyPolicy.dataCollectionTableHeaderCitizen')}
                </Typography>
              </TableCell>
              <TableCell className={classes.cellText}>
                <Typography className={classes.sectionTitle}>
                  {t('pages.privacyPolicy.dataCollectionTableHeaderPoliceOfficer')}
                </Typography>

              </TableCell>
              <TableCell className={classes.cellText}>
                <Typography className={classes.sectionTitle}>
                  {t('pages.privacyPolicy.dataCollectionTableHeaderAssociate')}
                </Typography>

              </TableCell>
              <TableCell className={`${classes.cellText} ${classes.visibilityColumn}`}>
                <Typography className={classes.sectionTitle}>
                  {t('pages.privacyPolicy.dataCollectionTableHeaderVisibility')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.cellText}>
                <strong>{t('pages.privacyPolicy.dataCollectionTableMandatoryInfo')}</strong>
              </TableCell>
              <TableCell className={classes.cellText}>
                <ul>
                  <li>{t('pages.privacyPolicy.dataCollectionTableFirstName')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableSurname')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableEmail')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableDob')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableNino')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableReasonableAdj')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableSex')}</li>
                </ul>
              </TableCell>
              <TableCell className={classes.cellText}>
                <ul>
                  <li>{t('pages.privacyPolicy.dataCollectionTableExamAppliedFor')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableForceServingWith')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableFirstName')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableSurname')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableEmail')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableDob')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableNino')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTablePoliceNumber')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableReasonableAdj')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableSex')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableNationalSchemes')}</li>
                </ul>
              </TableCell>
              <TableCell className={classes.cellText}>
                <ul>
                  <li>{t('pages.privacyPolicy.dataCollectionTableMemberId')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableNationalStatus')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableTitleFirstLastName')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableEmail')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableNino')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableDob')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableAddress')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTablePrimaryPhone')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableScCleared')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableDateTrained')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableSpeaksWelsh')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableSkills')}</li>
                </ul>
              </TableCell>
              <TableCell className={classes.cellText}>
                <ul>
                  <li>{t('pages.privacyPolicy.dataCollectionTableVisibility')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableYourInfo')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableYourNino')}</li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellText}>
                <strong>{t('pages.privacyPolicy.dataCollectionTableVoluntaryInfo')}</strong>
              </TableCell>
              <TableCell className={classes.cellText}>
                <ul>
                  <li>{t('pages.privacyPolicy.dataCollectionTableDisability')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableEthnicity')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableReligion')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableGenderIdentity')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableSexualOrientation')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableSocioeconomicBackground')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableAcademic')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableEnglish')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTablePrimaryLanguage')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableLevelOfEnglish')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableEntryRecruit')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableVeteranStatus')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTablePoliceRoles')}</li>
                </ul>
              </TableCell>
              <TableCell className={classes.cellText}>
                <ul>
                  <li>{t('pages.privacyPolicy.dataCollectionTableDisability')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableEthnicity')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableMaritalStatus')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTablePregnancy')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableReligion')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableGenderIdentity')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableSexualOrientation')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableSocioeconomicBackground')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableAcademic')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableEnglish')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTablePrimaryLanguage')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableLevelOfEnglish')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableEntryExam')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableVeteranStatus')}</li>
                </ul>
              </TableCell>
              <TableCell className={classes.cellText}>
                <ul>
                  <li>{t('pages.privacyPolicy.dataCollectionTableTitle')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableSecondPhone')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableDisability')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableSex')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableSexualOrientation')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableGenderIdentity')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableEthnicity')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableReligion')}</li>
                </ul>
              </TableCell>
              <TableCell className={classes.cellText}>
                <ul>
                  <li>{t('pages.privacyPolicy.dataCollectionTableRestrictedAccess')}</li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.cellText}>
                <strong>{t('pages.privacyPolicy.dataCollectionTableThirdParty')}</strong>
              </TableCell>
              <TableCell className={classes.cellText}>
                <ul>
                  <li>{t('pages.privacyPolicy.dataCollectionTableQuickCase')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableTalogy')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableTazio')}</li>
                </ul>
              </TableCell>
              <TableCell className={classes.cellText}>
                <ul>
                  <li>{t('pages.privacyPolicy.dataCollectionTableQuickCase')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableLaunchPsi')}</li>
                </ul>
              </TableCell>
              <TableCell className={classes.cellText}>
                <ul>
                  <li>{t('pages.privacyPolicy.dataCollectionTableQuickCase')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableTazio')}</li>
                </ul>
              </TableCell>
              <TableCell className={classes.cellText}>
                <ul>
                  <li>{t('pages.privacyPolicy.dataCollectionTableQuickCaseAccess')}</li>
                  <li>{t('pages.privacyPolicy.dataCollectionTableThirdPartyAccess')}</li>
                </ul>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      <br />

      <Typography component="h3" variant="h3" align="left">
        {t('pages.privacyPolicy.waysUseInformationTitle')}
      </Typography>
      <Typography paragraph={true}>
        <ul>
          <li>{t('pages.privacyPolicy.waysUseInformationLine1')}</li>
          <li>{t('pages.privacyPolicy.waysUseInformationLine2')}</li>
          <li>{t('pages.privacyPolicy.waysUseInformationLine3')}</li>
          <li>{t('pages.privacyPolicy.waysUseInformationLine4')}</li>
        </ul>
      </Typography>

      <Typography component="h3" variant="h3" align="left">
        {t('pages.privacyPolicy.lawfulBasisTitle')}
      </Typography>
      <Typography paragraph={true}>
        <Trans i18nKey={'pages.privacyPolicy.lawfulBasisParagraph1'}>
          <strong />
        </Trans>
      </Typography>
      <Typography paragraph={true}>
        <Trans i18nKey={'pages.privacyPolicy.lawfulBasisParagraph2'}>
          <strong />
        </Trans>
      </Typography>
      <Typography paragraph={true}>
        <Trans i18nKey={'pages.privacyPolicy.lawfulBasisParagraph3'} />
      </Typography>
      <Typography paragraph={true}>
        <Trans i18nKey={'pages.privacyPolicy.lawfulBasisParagraph4'}>
          <strong />
          <strong />
          <strong />
        </Trans>
      </Typography>
      <Typography paragraph={true}>
        {t('pages.privacyPolicy.lawfulBasisParagraph5')}
      </Typography>

      <Typography component="h3" variant="h3" align="left">
        {t('pages.privacyPolicy.personalDataTitle')}
      </Typography>
      <Typography paragraph={true}>
        <Trans i18nKey={'pages.privacyPolicy.personalDataParagraph1'}>
          <strong />
        </Trans>
      </Typography>
      <Typography paragraph={true}>
        <Trans i18nKey={'pages.privacyPolicy.personalDataParagraph2'}>
          <strong />
        </Trans>
      </Typography>

      <Typography component="h3" variant="h3" align="left">
        {t('pages.privacyPolicy.thirdPartyTitle')}
      </Typography>
      <Typography paragraph={true}>
        {t('pages.privacyPolicy.thirdPartyParagraph1')}
      </Typography>
      <Typography paragraph={true}>
        {t('pages.privacyPolicy.thirdPartyParagraph2')}
        <ul>
          <li>
            <Trans i18nKey={'pages.privacyPolicy.thirdPartyParagraph2Line1'}>
              <Link href='https://www.talogy.com/en-gb/' target='_blank' />
            </Trans>
          </li>
          <li>
            <Trans i18nKey={'pages.privacyPolicy.thirdPartyParagraph2Line2'}>
              <Link href='https://www.tazio.io/' target='_blank' />
            </Trans>
          </li>
          <li>
            <Trans i18nKey={'pages.privacyPolicy.thirdPartyParagraph2Line3'}>
              <Link href='https://www.civica.com/en-gb/product-pages/civica-eassessment-software/' target='_blank' />
            </Trans>
          </li>
        </ul>
      </Typography>

      <Typography component="h3" variant="h3" align="left">
        {t('pages.privacyPolicy.technicalOrgMeasuresTitle')}
      </Typography>
      <Typography paragraph={true}>
        {t('pages.privacyPolicy.technicalOrgMeasuresParagraph1')}
      </Typography>
      <Typography paragraph={true}>
        <Trans i18nKey={'pages.privacyPolicy.technicalOrgMeasuresParagraph2'}>
          <strong />
        </Trans>
      </Typography>

      <Typography component="h3" variant="h3" align="left">
        {t('pages.privacyPolicy.dataRetentionTitle')}
      </Typography>
      <Typography component="h4" variant="h4" align="left">
        {t('pages.privacyPolicy.dataRetentionParagraph1Title')}
      </Typography>
      <Typography paragraph={true}>
        {t('pages.privacyPolicy.dataRetentionParagraph1Text')}
      </Typography>
      <Typography component="h4" variant="h4" align="left">
        {t('pages.privacyPolicy.dataRetentionParagraphInappropriateBehaviourTitle')}
      </Typography>
      <Typography paragraph={true}>
        {t('pages.privacyPolicy.dataRetentionParagraphInappropriateBehaviourText')}
      </Typography>
      <Typography component="h4" variant="h4" align="left">
        {t('pages.privacyPolicy.dataRetentionParagraph2Title')}
      </Typography>
      <Typography paragraph={true}>
        {t('pages.privacyPolicy.dataRetentionParagraph2Text')}
      </Typography>
      <Typography paragraph={true}>
        {t('pages.privacyPolicy.dataRetentionParagraph3Text')}
      </Typography>
      <Typography component="h4" variant="h4" align="left">
        {t('pages.privacyPolicy.dataRetentionParagraph4Title')}
      </Typography>
      <Typography paragraph={true}>
        {t('pages.privacyPolicy.dataRetentionParagraph4Text')}
      </Typography>
      <Typography component="h4" variant="h4" align="left">
        {t('pages.privacyPolicy.dataRetentionParagraph5Title')}
      </Typography>
      <Typography paragraph={true}>
        {t('pages.privacyPolicy.dataRetentionParagraph5Text')}
      </Typography>
      <Typography paragraph={true}>
        <Trans i18nKey={'pages.privacyPolicy.dataRetentionParagraph6Text'}>
          <strong />
        </Trans>
      </Typography>
      <Typography component="h4" variant="h4" align="left">
        {t('pages.privacyPolicy.dataRetentionParagraph7Title')}
      </Typography>
      <Typography paragraph={true}>
        {t('pages.privacyPolicy.dataRetentionParagraph7Text')}
      </Typography>
      <Typography component="h3" variant="h3" align="left">
        {t('pages.privacyPolicy.yourRightsTitle')}
      </Typography>
      <Typography paragraph={true}>
        <Trans i18nKey={'pages.privacyPolicy.yourRightsParagraph'}>
          <Link
            href='https://www.college.police.uk/privacy'
            target='_blank' />
        </Trans>
      </Typography>

      <br />

      <Typography component="h2" variant="h2" align="center">
        {t('pages.cookiePolicy.title')}
      </Typography>

      <Typography paragraph={true}>
        {t('pages.cookiePolicy.introParagraph')}
      </Typography>

      <Typography component="h3" variant="h3" align="left">
        {t('pages.cookiePolicy.usageTitle')}
      </Typography>
      <Typography paragraph={true}>
        {t('pages.cookiePolicy.usageParagraph')}
      </Typography>

      <div style={style.overflow}>
        <Typography component="h4" variant="h4" align="left">
          auth.aims.college.police.uk
        </Typography>


        <Table aria-label="simple table">

          <colgroup>
            <col width="25%" />
            <col width="25%" />
            <col width="50%" />
          </colgroup>

          <TableHead>
            <TableRow>
              <TableCell className={classes.cellText}>
                <Typography className={classes.sectionTitle}>
                  {t('pages.cookiePolicy.headerCookieName')}
                </Typography>
              </TableCell>
              <TableCell className={classes.cellText}>
                <Typography className={classes.sectionTitle}>
                  {t('pages.cookiePolicy.headerCookieLifespan')}
                </Typography>
              </TableCell>
              <TableCell className={classes.cellText}>
                <Typography className={classes.sectionTitle}>
                  {t('pages.cookiePolicy.headerCookiePurpose')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell className={classes.cellText}>
                <strong>AUTH_SESSION_ID</strong>
              </TableCell>
              <TableCell className={classes.cellText}>
                {t('pages.cookiePolicy.lifespanSession')}
              </TableCell>
              <TableCell className={classes.cellText}>
                {t('pages.cookiePolicy.purposeAuthSessionId')}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.cellText}>
                <strong>KC_RESTART</strong>
              </TableCell>
              <TableCell className={classes.cellText}>
                {t('pages.cookiePolicy.lifespanSession')}
              </TableCell>
              <TableCell className={classes.cellText}>
                {t('pages.cookiePolicy.purposeKeycloakInternal')}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.cellText}>
                <strong>KC_START</strong>
              </TableCell>
              <TableCell className={classes.cellText}>
                {t('pages.cookiePolicy.lifespanSession')}
              </TableCell>
              <TableCell className={classes.cellText}>
                {t('pages.cookiePolicy.purposeKeycloakInternal')}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.cellText}>
                <strong>KEYCLOAK_IDENTITY</strong>
              </TableCell>
              <TableCell className={classes.cellText}>
                <span />
              </TableCell>
              <TableCell className={classes.cellText}>
                {t('pages.cookiePolicy.purposeKeycloakIdentity')}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.cellText}>
                <strong>KEYCLOAK_LOCALE</strong>
              </TableCell>
              <TableCell className={classes.cellText}>
                {t('pages.cookiePolicy.lifespanSession')}
              </TableCell>
              <TableCell className={classes.cellText}>
                {t('pages.cookiePolicy.purposeKeycloakLocale')}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.cellText}>
                <strong>KEYCLOAK_SESSION</strong>
              </TableCell>
              <TableCell className={classes.cellText}>
                {t('pages.cookiePolicy.lifespan10h')}
              </TableCell>
              <TableCell className={classes.cellText}>
                {t('pages.cookiePolicy.purposeKeycloakSession')}
              </TableCell>
            </TableRow>
          </TableBody>

        </Table>
      </div>

      <br />

      <div style={style.overflow}>
        <Typography component="h4" variant="h4" align="left">
          candidate.aims.college.police.uk
        </Typography>

        <Table aria-label="simple table">

          <colgroup>
            <col width="25%" />
            <col width="25%" />
            <col width="50%" />
          </colgroup>

          <TableHead>
            <TableRow>
              <TableCell className={classes.cellText}>
                <Typography className={classes.sectionTitle}>
                  {t('pages.cookiePolicy.headerCookieName')}
                </Typography>
              </TableCell>
              <TableCell className={classes.cellText}>
                <Typography className={classes.sectionTitle}>
                  {t('pages.cookiePolicy.headerCookieLifespan')}
                </Typography>
              </TableCell>
              <TableCell className={classes.cellText}>
                <Typography className={classes.sectionTitle}>
                  {t('pages.cookiePolicy.headerCookiePurpose')}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell className={classes.cellText}>
                <strong>cookie_consent</strong>
              </TableCell>
              <TableCell className={classes.cellText}>
                {t('pages.cookiePolicy.lifespan1Y')}
              </TableCell>
              <TableCell className={classes.cellText}>
                {t('pages.cookiePolicy.purposeCookieConsent')}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.cellText}>
                <strong>refresh_token</strong>
              </TableCell>
              <TableCell className={classes.cellText}>
                {t('pages.cookiePolicy.lifespan30m')}
              </TableCell>
              <TableCell className={classes.cellText}>
                {t('pages.cookiePolicy.purposeRefreshToken')}
              </TableCell>
            </TableRow>

          </TableBody>

        </Table>
      </div>

      <br />

      <Typography component="h3" variant="h3" align="left">
        {t('pages.cookiePolicy.settingsTitle')}
      </Typography>
      <Typography paragraph={true}>
        <Trans i18nKey={'pages.cookiePolicy.settingsParagraph'}>
          <Link
            href='http://www.allaboutcookies.org/'
            target='_blank' />
        </Trans>
      </Typography>

    </React.Fragment>
  );
};
export default PrivacyPolicy;
