import {Typography} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import FormMultiSelect from '../../../../../components/form/FormMultiSelect';
import RadioButtonsGroup from '../../../../../components/form/RadioButtonGroup';
import {COMMON} from '../../../../../utils/applicationConst';

const useStyles = makeStyles(() => ({
  formControl: {
    maxWidth: '100%'
  },
  formControlNested: {
    marginLeft: 28
  },
  question: {
    paddingBottom: 15,
    fontWeight: 'bold',
    fontSize: 16
  },
  section: {
    marginTop: 15
  },
  info: {
    fontSize: 16
  }
}));

const PoliceRoles = (props) => {

  const {handleStepState, form, productId} = props;
  const classes = useStyles();
  const stepSlug = 'police-roles';
  const handleChange = event => handleStepState(stepSlug, {[event.target.name]: event.target.value});

  const getPreviousPolicingOptions = (productId) => {
    const options = [
      {id: 'policeOfficer', name: 'Police officer'},
      {id: 'policeStaff', name: 'Police staff (including designated officer)'},
      {id: 'volunteer', name: 'Volunteer'},
      {id: 'cadet', name: 'Cadet'}
    ];
    if (productId === COMMON.PCSO) {
      options.splice(2, 0, {id: 'specialConstable', name: 'Special constable'});
    } else if (productId === COMMON.SPECIALS) {
      options.splice(2, 0, {id: 'pcso', name: 'PCSO'});
    } else {
      options.splice(2, 0, {id: 'specialConstable', name: 'Special constable'}, {id: 'pcso', name: 'PCSO'});
    }
    return options;
  };

  return (
    <React.Fragment>
      <Typography component="h2" variant="h2">
        Current/previous police roles
      </Typography>
      <div className={classes.section}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel
            component="legend"
            className={classes.question}
            data-testid="label-anyPreviousPolicingExperience">Are you currently working for a police organisation or have you done so in the past?
          </FormLabel>
          <div
            className={form[stepSlug].errors && form[stepSlug].errors.findIndex(element => element.includes('anyPreviousPolicingExperience')) !== -1 ? 'inline-error' : ''}>
            {form[stepSlug].errors && form[stepSlug].errors.findIndex(element => element.includes('anyPreviousPolicingExperience')) !== -1 ?
              <p>If you do not wish to answer this question, please select ‘Prefer not to say’</p> : ''}

            <RadioGroup
              aria-label="anyPreviousPolicingExperience"
              name="anyPreviousPolicingExperience"
              value={form[stepSlug].data.anyPreviousPolicingExperience}
              onChange={(e) => handleChange(e)}>

              <FormControlLabel
                value="yes" control={<Radio color="primary" />} label="Yes"
                data-testid="anyPreviousPolicingExperience-select-option" />
              {form[stepSlug].data.anyPreviousPolicingExperience === 'yes' ?
                <div className={classes.formControlNested}>
                  <span className={classes.info}>Please select all that apply</span>
                  <FormMultiSelect
                    showError={form[stepSlug].errors && form[stepSlug].errors.includes('previousPolicingExperience')}
                    name="previousPolicingExperience"
                    options={getPreviousPolicingOptions(productId)}
                    value={form[stepSlug].data.previousPolicingExperience}
                    handleChange={(e) => handleChange(e)}
                  />
                </div>
                : null}

              <FormControlLabel
                value="no" control={<Radio color="primary" />} label="No"
                data-testid="anyPreviousPolicingExperience-select-option" />

              <FormControlLabel
                value="preferNotToSay" control={<Radio color="primary" />} label="Prefer not to say"
                data-testid="anyPreviousPolicingExperience-select-option" />
            </RadioGroup>
          </div>
        </FormControl>
      </div>
      <div className={classes.section}>
        <RadioButtonsGroup
          label="Has a member of your family worked for the police, either as a police officer, special constable, PCSO or police staff?"
          showError={form[stepSlug].errors && form[stepSlug].errors.includes('familyPolicingInvolvement')}
          errorMsg={'If you do not wish to answer this question, please select ‘Prefer not to say’'}
          name="familyPolicingInvolvement"
          options={[
            {id: 'yes', name: 'Yes'},
            {id: 'no', name: 'No'},
            {id: 'preferNotToSay', name: 'Prefer not to say'}
          ]}
          value={form[stepSlug].data.familyPolicingInvolvement}
          handleChange={(e) => handleChange(e)}
        />
      </div>
    </React.Fragment>
  );
};
PoliceRoles.propTypes = {
  handleStepState: PropTypes.func,
  form: PropTypes.object,
  step: PropTypes.object,
  productId: PropTypes.string
};
export default PoliceRoles;
