import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import {makeStyles} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  dateField_input: {
    width: '30%',
    maxWidth: 100,
    marginTop: 5
  },
  dateTextField:{
    maxWidth: 100
  }
}));

const BACKSPACE = 8;
const TAB = 9;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const NAVIGATION_KEYS = [BACKSPACE, TAB, LEFT_ARROW, RIGHT_ARROW];
const NUMBERS_REGEXP = /^[0-9]+$/;

const DateInput = (props) => {
  const classes = useStyles();
  const handleChange = (e) => {
    let dateVal;
    switch (e.target.name) {
      case 'day':
        trimDate(e, 2);
        dateVal = `${getDate('year')}-${getDate('month')}-${e.target.value}`;
        break;
      case 'month':
        trimDate(e, 2);
        dateVal = `${getDate('year')}-${e.target.value}-${getDate('day')}`;
        break;
      case 'year':
        trimDate(e, 4);
        dateVal = `${e.target.value}-${getDate('month')}-${getDate('day')}`;
        break;
      default:
        dateVal = '';
    }
    props.handleChange({
      target: {
        name: props.name,
        value: dateVal
      }
    });
  };

  const trimDate = (e, chars) => {
    if (e.target.value.length > chars) e.target.value = e.target.value.slice(0, chars);
    return e;
  };

  const getDate = (part) => {

    switch (part) {
      case 'day':
        return props.value.split('-')[2] || '';
      case 'month':
        return props.value.split('-')[1] || '';
      case 'year':
        return props.value.split('-')[0] || '';
      default:
        console.error('Incorrect date format');
    }
  };

  const preventNonNumericalInput = (e) => {
    e = e || window.event;
    var charCode = (typeof e.which == 'undefined') ? e.keyCode : e.which;
    var charStr = e.key;

    if (!NAVIGATION_KEYS.includes(charCode) && !charStr.match(NUMBERS_REGEXP))
      e.preventDefault();
  };

  return (
    <Grid item xs={12}>
      <label>
        {props.title}
      </label>
      <div className={props.showError ? ('inline-error') : ''}>{props.errorMessage}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item className={classes.dateField_input}>
            <TextField
              inputProps={{
                maxLength: 2,
                onKeyDown: (e) => preventNonNumericalInput(e)
              }}
              id={props.name + '_day'}
              name="day"
              variant="outlined"
              placeholder="DD"
              label={(props.label && props.label.day) || 'Day'}
              type="number"
              error={props.showError}
              onChange={handleChange}
              value={getDate('day')}
              className={classes.dateTextField}
              inputRef={props.inputRef}
            />
          </Grid>
          <Grid item className={classes.dateField_input}>
            <TextField
              inputProps={{
                maxLength: 2,
                onKeyDown: (e) => preventNonNumericalInput(e)
              }}
              id={props.name + '_month'}
              name="month"
              variant="outlined"
              placeholder="MM"
              label={(props.label && props.label.month) || 'Month'}
              type="number"
              error={props.showError}
              onChange={handleChange}
              value={getDate('month')}
              className={classes.dateTextField}
            />
          </Grid>
          <Grid item className={classes.dateField_input}>
            <TextField
              inputProps={{
                maxLength: 4,
                onKeyDown: (e) => preventNonNumericalInput(e)
              }}
              id={props.name + '_year'}
              name="year"
              variant="outlined"
              placeholder="YYYY"
              label={(props.label && props.label.year) || 'Year'}
              type="number"
              error={props.showError}
              onChange={handleChange}
              value={getDate('year')}
              className={classes.dateTextField}
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

DateInput.propTypes = {
  handleChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.shape({day: PropTypes.string, month: PropTypes.string, year: PropTypes.string}),
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
  inputRef: PropTypes.func
};

export default DateInput;
