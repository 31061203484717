import moment from 'moment';

const isValidDateOfBirth = (dob) => moment(dob, 'YYYY-M-D', true).isValid();

export const isDateOfBirthWithinAcceptableRange = (dob, minAge = 16, maxAge = 80) => {
  const age = moment().diff(moment(dob, 'YYYY-M-D', true), 'years', false);
  return age >= minAge && age <= maxAge;
};

export const formatDateDDMMYYY = (date) => moment(date, 'YYYY-M-D').format('DD-MM-YYYY');

export default isValidDateOfBirth;
