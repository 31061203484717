// This defaultConfig is only used for headless environment like test runners.
// For local development config, please see the `.env` file
const defaultConfig = {
  OAUTH2_LOGIN_URL: 'idam/login?response_type=code&client_id=[[client_id]]&state=[[state]]&redirect_uri=[[redirect_uri]]',
  OAUTH2_LOGOUT_URL: 'idam/logout?post_logout_redirect_uri=[[redirect_uri]]&id_token_hint=[[id_token]]',
  OAUTH2_CLIENT_ID: 'client-123',
};

// See .env for config development values
const config = (window && window.config) || defaultConfig;

export default config;
