import {makeStyles} from '@material-ui/core/styles';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import {COMMON, SIFT_ONLY_PRODUCTS} from '../../../../utils/applicationConst';

const useStyles = makeStyles(() => ({
  section: {
    marginTop: 15
  }
}));

const SelfRegisterLanding = (props) => {

  const {forceName, productId} = props;
  const classes = useStyles();
  const getSiftingExplanation= () => {
    if (productId?.toLowerCase() === COMMON.SPECIALS.toLowerCase()) {
      return 'Once your registration has received the relevant checks, you will be required to sit and pass the ' +
        'College of Policing national sift process. This assessment consists of  two exercises that test key ' +
        'competency and value areas important for performing effectively as a special constable.';
    } else if (productId?.toLowerCase() === COMMON.PCSO.toLowerCase()) {
      return 'Once your registration has received the relevant checks, you will be required to sit and pass the ' +
        'College of Policing national sift process. This assessment consists of  two exercises that test key ' +
        'competency and value areas important for performing effectively as a police community support officer (PCSO).';
    }
    return 'Once your registration has received the relevant checks, you may be required to sit and pass the' +
      ' national sift, dependant on the force you have applied to, and the College' +
      ' of Policing online assessment process. This assessment consists of a series of exercises that test key' +
      ' competency and value areas important for performing effectively as a police constable.';
  };
  const getProcessName = (productId) => {
    if (productId?.toLowerCase() === COMMON.SPECIALS.toLowerCase()) {
      return ' your special constable application.';
    } else if (productId?.toLowerCase() === COMMON.PCSO.toLowerCase()) {
      return ' your PCSO application.';
    }
    return ' the next step and the online assessment process.';
  };

  return (
    <React.Fragment>
      <div>
        <div className={classes.section}>
          <Typography paragraph={true}>
            Thank you for your interest in applying to {forceName}. You are invited to register your application with the College of Policing.
          </Typography>
          <Typography paragraph={true}>
            {getSiftingExplanation(productId)}
          </Typography>
          <Typography paragraph={true}>
            Once you have completed this registration form, more information will be sent to you regarding {getProcessName(productId)}
          </Typography>
          <Typography paragraph={true}>
            Please note you may apply to more than one force at a time by filling in more than one force application; however, you are only
            permitted to sit the national sift {SIFT_ONLY_PRODUCTS.includes(productId)? '' : 'and the online assessment' } process with one force at a time within a three-month period
            and a maximum of twice within a 12-month period.
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography component='h2' variant='h2'>
            Diversity, equality and inclusion
          </Typography>
          <div className={classes.section}>
            <Typography paragraph={true}>
              The College and wider policing family are pro-inclusion, we celebrate diversity and are constantly striving towards equality of opportunity. We want to understand the make-up of our workforce in order to identify and address any discrimination, and to ensure equality of opportunity and access for all.
            </Typography>
            <Typography paragraph={true}>
              We therefore ask for your help to gather this information so that we understand the accurate make-up of our workforce.
              This will then shape the future planning of our diversity, equality and inclusion work.
            </Typography>
            <Typography paragraph={true}>
              Once you have clicked the ‘Apply’ button below, you will be asked a series of questions about your personal characteristics.
              Providing this information is voluntary, it will not form any part of your assessment and will only be used for monitoring purposes.
              You can find out more about how we process your data in our&nbsp;
              <a href="/privacy-policy" target="_blank" className="MuiLink-root">privacy notice</a>.
            </Typography>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
SelfRegisterLanding.propTypes = {
  handleStepState: PropTypes.func,
  form: PropTypes.object,
  step: PropTypes.object,
  forceName: PropTypes.string,
  productId: PropTypes.string
};
export default SelfRegisterLanding;
