import {makeStyles} from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import RadioButtonsGroup from '../../../../../components/form/RadioButtonGroup';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  section: {
    marginTop: 15
  }
}));

const MilitaryVeteran = (props) => {

  const {handleStepState, form} = props;
  const stepSlug = 'veteran-status';
  const classes = useStyles();

  const handleChange = event => handleStepState(stepSlug, {[event.target.name]: event.target.value});

  return (
    <React.Fragment>
      <div className={classes.section}>
        <Typography component="h2" variant="h2">
          Veteran status
        </Typography>
        <RadioButtonsGroup
          label="Are you a UK military veteran (someone who has served at least one day in the armed forces and completed their service)?"
          showError={form[stepSlug].errors && form[stepSlug].errors.includes('militaryVeteran')}
          errorMsg={'If you do not wish to answer this question, please select ‘Prefer not to say’'}
          name="militaryVeteran"
          options={[
            {id: 'yes', name: 'Yes'},
            {id: 'no', name: 'No'},
            {id: 'preferNotToSay', name: 'Prefer not to say'}
          ]}
          value={form[stepSlug].data.militaryVeteran}
          handleChange={(e) => handleChange(e)}
        />
      </div>
    </React.Fragment>
  );
};
MilitaryVeteran.propTypes = {
  handleStepState: PropTypes.func,
  form: PropTypes.object,
  step: PropTypes.object,
};
export default MilitaryVeteran;
