import React from 'react';
import PropTypes from 'prop-types';
import PromotionDeclaration from './PromotionDeclaration';
import RecruitmentDeclaration from './RecruitmentDeclaration';

const CandidateDeclaration = (props) => {
  const {type, productId} = props;

  const getDeclaration = () => type === 'promotion' ? <PromotionDeclaration /> : <RecruitmentDeclaration productId={productId}  />;
  return (
    <React.Fragment>
      {getDeclaration()}
    </React.Fragment>
  );
};
CandidateDeclaration.propTypes = {
  handleStepState: PropTypes.func,
  form: PropTypes.object,
  step: PropTypes.object,
  type: PropTypes.string,
  productId: PropTypes.string
};
export default CandidateDeclaration;
