import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

const ChangeProgress = () => {
  return (

    <div className="change-progress">
      <CircularProgress />
    </div>

  );
};

export default ChangeProgress;
