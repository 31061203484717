import {useEffect, useState} from 'react';

const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = useState(
    localStorage.getItem(localStorageKey)
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (value) {
      localStorage.setItem(localStorageKey, value);
    }
  }, [value]);
 
  return [value, setValue];
};
export {useStateWithLocalStorage};
