import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Redirect, useHistory} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormProgress from './components/FormProgress';
import {Container} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formNavigation: {
    marginTop: 24
  },
  stepContent: {
    '@media screen and (min-width: 1080px)': {
      margin: '0 auto',
      flexBasis: '80%'
    }
  }
}));

const Form = (props) => {

  const [skipToStep, setSkipToStep] = useState();
  const [focusField, setFocusField] = useState();
  const classes = useStyles();
  const history = useHistory();
  const getForms = props.getForms;
  const type = props.type;
  const isValid = props.isValid;
  const slug = props.slug;
  const form = props.form;
  const setForm = props.setForm;
  const activeStep = props.activeStep;
  const forceName = props.forceName;
  const productId = props.productId;

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    const currStep = getForms().findIndex(f => f.slug === slug);
    setForm({...form, activeStep: currStep});
    history.listen((location, action) => {

      if (action === 'POP' && location.pathname !== '/' + type + '/') {

        handleBack(location.pathname);
      }
    });
    scrollToTop();
  }, [activeStep]);

  const handleBack = (backSlug) => setForm({
    ...form,
    activeStep: getForms().findIndex(f => f.slug === backSlug.replace('/' + type + '/', ''))
  });

  const handleStepState = (step, stepData) => {
    const data = Object.assign(form[step].data, stepData);
    setForm({...form, ...data});
  };

  const goToStep = (step, skipToStep, focusField, replaceHistory) => {
    setSkipToStep(skipToStep);
    setFocusField(focusField);
    setForm({...form, activeStep: step, [slug]: {...form[slug], errors: ['']}});
    if (skipToStep || replaceHistory) {
      history.replace(`/${type}/${getForms()[step].slug}`);
    } else {
      history.push(`/${type}/${getForms()[step].slug}`);
    }
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const setErrors = (step, fieldErrors) => {
    setForm({
      ...form,
      [step]: {
        ...form[step],
        fieldError: fieldErrors,
        // Deprecated, use `fieldError` instead
        errors: Object.keys(fieldErrors),
      },
    });
  };

  const clearStepWarningsAndErrors = (step) => {
    if (form[step]) {
      setForm({
        ...form,
        [step]: {
          ...form[step],
          fieldWarning: {},
          fieldError:  {},
        },
      });
    }
  };

  const handleNext = (currentStep) => {
    if (isValid(currentStep)) {
      clearStepWarningsAndErrors(currentStep);
      handleStepState(currentStep, {completed: true});
      const nextStep = skipToStep ? skipToStep : activeStep + 1;
      goToStep(nextStep, undefined, undefined, !!skipToStep);
    }
  };

  const getStepContent = (id) => {

    try {
      return getForms({form, handleStepState, step: {slug}, goToStep, setErrors, forceName, productId, focusField})[id].component;
    } catch (e) {
      return <Redirect to="/not-found" />;
    }
  };

  const getButtonLabel = () => {
    switch (activeStep) {
      case 0:
        return props.startBtnLabel || 'Continue';
      case getForms().length - 2:
        return props.submitBtnLabel || 'Submit';
      default:
        return 'Continue';
    }
  };

  const isLandingPage = (activeStep) => {
    const activeForm = getForms()[activeStep];
    return activeForm && activeForm.slug === 'role-force';
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg" data-testid={`active-step-${activeStep}`}>
        <Typography component="h1" variant="h1" className={classes.title}>
          {props.title}
        </Typography>
      </Container>
      <Container maxWidth="lg">
        <FormProgress activeStep={activeStep} form={form} getForms={getForms} />
      </Container>
      <Container maxWidth="lg">
        <Grid container justifyContent="center">
          <Grid item xs={12} className={classes.stepContent}>
            <div data-testid={`${slug}-${activeStep}`}>
              {getStepContent(activeStep)}
              {activeStep !== getForms().length - 1 ?
                <div className={classes.formNavigation}>
                  {!isLandingPage(activeStep) &&
                    <Button
                      disabled={!!skipToStep}
                      onClick={() => window.history.back()}
                      className={classes.backButton}>
                      Back
                    </Button>
                  }
                  <Button variant="contained" color="primary" onClick={() => handleNext(slug)}>
                    {getButtonLabel()}
                  </Button>
                </div>
                : null}
            </div>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};
Form.propTypes = {
  title: PropTypes.string,
  isValid: PropTypes.func,
  getForms: PropTypes.func,
  activeStep: PropTypes.number,
  type: PropTypes.string,
  slug: PropTypes.string,
  form: PropTypes.object,
  setForm: PropTypes.func,
  formDefinition: PropTypes.object,
  forceName: PropTypes.string,
  productId: PropTypes.string,
  startBtnLabel: PropTypes.string,
  submitBtnLabel: PropTypes.string
};
export default Form;
