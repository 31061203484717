import React from 'react';
import {Route} from 'react-router-dom';
import PropTypes from 'prop-types';

const RouteWithLayout = props => {
  const {layout: Layout, component: Component} = props;

  return (
    <Route>
      <Layout>
        <Component />
      </Layout>
    </Route>
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string
};

export default RouteWithLayout;
