import React from 'react';
import {makeStyles} from '@material-ui/core';
import palette from '../theme/palette';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  messageBox: {
    padding: 20,
    marginBottom: 15,
    fontSize: 20
  },
  error: {
    color: palette.error.contrastText,
    backgroundColor: palette.error.main,
  },
  ok: {
    color: palette.ok.contrastText,
    backgroundColor: palette.ok.main,
  },
  info: {
    color: palette.black,
    backgroundColor: palette.white,
  }
}));

const Message = (props) => {

  const classes = useStyles();

  return (
    <div className={`${classes.messageBox} ${classes[props.type]}`}>
      {props.message}
    </div>
  );
};
Message.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
};
export default Message;
