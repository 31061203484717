import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import {COMMON} from '../../../../utils/applicationConst';

const RecruitmentSuccess = (props) => {
  const {productId} = props;

  const getRoleName = (productId) => {
    if (productId?.toLowerCase() === COMMON.PCSO.toLowerCase()) {
      return 'PCSO';
    } else if (productId?.toLowerCase() === COMMON.SPECIALS.toLowerCase()) {
      return 'special constable';
    }
    return 'police constable';
  };

  const getAssessmentName = (productId) => {
    if (productId?.toLowerCase() === COMMON.PCSO.toLowerCase()) {
      return 'PCSO';
    } else if (productId?.toLowerCase() === COMMON.SPECIALS.toLowerCase()) {
      return 'special constable';
    }
    return 'national sift and online assessment';
  };

  return (<div>
    <Typography paragraph={true}>
      Thank you for providing registration information for the {getAssessmentName(productId)} process.
      You can expect to receive an email shortly to confirm your application. Once your application has received the
      relevant checks, you will receive a further email notifying you of the next steps
      {productId?.toLowerCase() !== COMMON.SPECIALS.toLowerCase() ? ' of the process' : ''}.
      Please be aware that emails from our online system may go to your spam or junk inboxes so please do look in those
      folders for instructions on next steps.
    </Typography>
    <Typography paragraph={true}>
      If you have any questions regarding your application for the role of {getRoleName(productId)}, please contact your recruiting force.
    </Typography>
    <Typography paragraph={true}>
      If, after submitting your application, you are concerned that you have made an error, please do not submit a new application but contact your recruiting force instead.
    </Typography>
    <Typography paragraph={true}>
      Again, thank you for your application.
    </Typography>
  </div>);
};

RecruitmentSuccess.propTypes = {
  productId: PropTypes.string
};
export default RecruitmentSuccess;
