import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import RadioButtonsGroup from '../../../../components/form/RadioButtonGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import Input from '../../../../components/form/Input';

const useStyles = makeStyles(() => ({
  formControlNested: {
    marginLeft: 28
  },
  question: {
    paddingBottom: 15,
    fontWeight: 'bold',
    fontSize: 16
  },
  section: {
    marginTop: 15
  }
}));

const Education = (props) => {

  const {handleStepState, form} = props;
  const classes = useStyles();
  const stepSlug = 'education';
  const handleChange = event => handleStepState(stepSlug, {[event.target.name]: event.target.value});

  const handleAcademicAttainmentParentChange = event => {
    handleStepState(stepSlug, {academicAttainment: ''});
    handleStepState(stepSlug, {academicAttainmentOther: ''});
    handleChange(event);

    if (['doctorate', 'nvq5', 'noFormalQualifications', 'preferNotToSay', 'other'].includes(form[stepSlug].data.academicAttainmentParent)) {
      handleChange({target: {name: 'academicAttainment', value: event.target.value}});
    }

  };

  const handleEnglishAcademicAttainmentParentChange = event => {
    handleStepState(stepSlug, {englishAcademicAttainment: ''});
    handleStepState(stepSlug, {englishAcademicAttainmentOther: ''});
    handleChange(event);

    if (['noQualificationsAchieved', 'preferNotToSay', 'other'].includes(form[stepSlug].data.englishAcademicAttainmentParent)) {
      handleChange({target: {name: 'englishAcademicAttainment', value: event.target.value}});
    }
  };

  return (
    <React.Fragment>
      <Typography component="h2" variant="h2">
        Education
      </Typography>
      <div className={classes.section}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel
            component="legend"
            className={classes.question}
            data-testid="label-academicAttainment">What is your highest level of academic attainment?
          </FormLabel>
          <div
            className={form[stepSlug].errors && form[stepSlug].errors.findIndex(element => element.includes('academicAttainment')) !== -1 ? 'inline-error' : ''}>
            {form[stepSlug].errors && form[stepSlug].errors.findIndex(element => element.includes('academicAttainment')) !== -1 ? <p>If you do not wish to answer this question, please select ‘Prefer not to say’</p> : ''}

            <RadioGroup
              aria-label="academicAttainmentParent"
              name="academicAttainmentParent"
              value={form[stepSlug].data.academicAttainmentParent} onChange={(e) => handleAcademicAttainmentParentChange(e)}>

              <FormControlLabel
                value="doctorate"
                control={<Radio color="primary" />} label="Doctorate"
                data-testid="academicAttainmentParent-select-option" />

              <FormControlLabel
                value="masters" control={<Radio color="primary" />} label="Masters"
                data-testid="academicAttainmentParent-select-option" />
              {form[stepSlug].data.academicAttainmentParent === 'masters' ?
                <div className={classes.formControlNested}>
                  <RadioButtonsGroup
                    label=""
                    name="academicAttainment"
                    options={[
                      {id: 'mastersDistinction', name: 'Masters - Distinction'},
                      {id: 'mastersMerit', name: 'Masters - Merit'},
                      {id: 'mastersPass', name: 'Masters - Pass'}
                    ]}
                    value={form[stepSlug].data.academicAttainment}
                    handleChange={(e) => handleChange(e)}
                  />
                </div>
                : null}

              <FormControlLabel
                value="postgrad" control={<Radio color="primary" />} label="Postgraduate Certificate / Diploma"
                data-testid="academicAttainmentParent-select-option" />
              {form[stepSlug].data.academicAttainmentParent === 'postgrad' ?
                <div className={classes.formControlNested}>
                  <RadioButtonsGroup
                    label=""
                    name="academicAttainment"
                    options={[
                      {id: 'postgradDiplomaDistinction', name: 'Postgraduate Certificate / Diploma - Distinction'},
                      {id: 'postgradDiplomaMerit', name: 'Postgraduate Certificate / Diploma - Merit'},
                      {id: 'postgradDiplomaPass', name: 'Postgraduate Certificate / Diploma - Pass'}
                    ]}
                    value={form[stepSlug].data.academicAttainment}
                    handleChange={(e) => handleChange(e)}
                  />
                </div>
                : null}

              <FormControlLabel
                value="bachelor" control={<Radio color="primary" />} label="Bachelor Degree"
                data-testid="academicAttainmentParent-select-option" />
              {form[stepSlug].data.academicAttainmentParent === 'bachelor' ?
                <div className={classes.formControlNested}>
                  <RadioButtonsGroup
                    label=""
                    name="academicAttainment"
                    options={[
                      {id: 'degree1st', name: 'Bachelor Degree - 1st'},
                      {id: 'degree21', name: 'Bachelor Degree - 2:1'},
                      {id: 'degree22', name: 'Bachelor Degree - 2:2'},
                      {id: 'degree3rd', name: 'Bachelor Degree - 3rd'},
                      {id: 'degreePass', name: 'Bachelor Degree - Pass'}
                    ]}
                    value={form[stepSlug].data.academicAttainment}
                    handleChange={(e) => handleChange(e)}
                  />
                </div>
                : null}

              <FormControlLabel
                value="nvq5" control={<Radio color="primary" />} label="NVQ Level 5"
                data-testid="academicAttainmentParent-select-option" />

              <FormControlLabel
                value="aLevel" control={<Radio color="primary" />} label="A Level"
                data-testid="academicAttainmentParent-select-option" />
              {form[stepSlug].data.academicAttainmentParent === 'aLevel'
                ? <div className={classes.formControlNested}>
                  <RadioButtonsGroup
                    label=""
                    name="academicAttainment"
                    options={[
                      {id: 'aLevelA', name: 'A Level - A'},
                      {id: 'aLevelB', name: 'A Level - B'},
                      {id: 'aLevelC', name: 'A Level - C'},
                      {id: 'aLevelDE', name: 'A Level - D-E'}
                    ]}
                    value={form[stepSlug].data.academicAttainment}
                    handleChange={(e) => handleChange(e)}
                  />
                </div>
                : null}

              <FormControlLabel
                value="oLevel" control={<Radio color="primary" />} label="O Level / GCSE / CSE"
                data-testid="academicAttainmentParent-select-option" />
              {form[stepSlug].data.academicAttainmentParent === 'oLevel'
                ? <div className={classes.formControlNested}>
                  <RadioButtonsGroup
                    label=""
                    name="academicAttainment"
                    options={[
                      {id: 'oLevelGcseCseA', name: 'O Level / GCSE / CSE - A*/A (9-7)'},
                      {id: 'oLevelGcseCseBC', name: 'O Level / GCSE / CSE - B/C (6-4)'},
                      {id: 'oLevelGcseCseDG', name: 'O Level / GCSE / CSE - D/G (3-1)'}
                    ]}
                    value={form[stepSlug].data.academicAttainment}
                    handleChange={(e) => handleChange(e)}
                  />
                </div>
                : null}

              <FormControlLabel
                value="other" control={<Radio color="primary" />} label="Other qualification"
                data-testid="academicAttainmentParent-select-option" />
              {form[stepSlug].data.academicAttainmentParent === 'other'
                ? <div className={classes.otherTextField}>
                  <Input
                    name="academicAttainmentOther"
                    label="Please describe (optional)"
                    value={form[stepSlug].data.academicAttainmentOther}
                    handleChange={(e) => handleChange(e)}
                    error={false}
                    maxLength={100}
                  />
                </div>
                : null}

              <FormControlLabel
                value="noFormalQualifications" control={<Radio color="primary" />} label="No formal qualifications"
                data-testid="academicAttainmentParent-select-option" />

              <FormControlLabel
                value="preferNotToSay" control={<Radio color="primary" />} label="Prefer not to say"
                data-testid="academicAttainmentParent-select-option" />
            </RadioGroup>
          </div>
        </FormControl>
      </div>
      <div className={classes.section}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel
            component="legend"
            className={classes.question}
            data-testid="label-englishAcademicAttainment">What is your highest attainment in the English language?
          </FormLabel>
          <div
            className={form[stepSlug].errors && form[stepSlug].errors.findIndex(element => element.includes('englishAcademicAttainment')) !== -1 ? 'inline-error' : ''}>
            {form[stepSlug].errors && form[stepSlug].errors.findIndex(element => element.includes('englishAcademicAttainment')) !== -1 ? <p>If you do not wish to answer this question, please select ‘Prefer not to say’</p> : ''}

            <RadioGroup 
              aria-label="englishAcademicAttainmentParent"
              name="englishAcademicAttainmentParent"
              value={form[stepSlug].data.englishAcademicAttainmentParent} onChange={(e) => handleEnglishAcademicAttainmentParentChange(e)}>

              <FormControlLabel
                value="oLevel" control={<Radio color="primary" />} label="O Level / GCSE / CSE"
                data-testid="englishAcademicAttainmentParent-select-option" />
              {form[stepSlug].data.englishAcademicAttainmentParent === 'oLevel' ?
                <div className={classes.formControlNested}>
                  <RadioButtonsGroup
                    label=""
                    name="englishAcademicAttainment"
                    options={[
                      {id: 'oLevelGcseCseA', name: 'O Level / GCSE / CSE - A*/A (9-7)'},
                      {id: 'oLevelGcseCseBC', name: 'O Level / GCSE / CSE - B/C (6-4)'},
                      {id: 'oLevelGcseCseDG', name: 'O Level / GCSE / CSE - D/G (3-1)'}
                    ]}
                    value={form[stepSlug].data.englishAcademicAttainment}
                    handleChange={(e) => handleChange(e)}
                  />
                </div>
                : null}

              <FormControlLabel
                value="aLevel" control={<Radio color="primary" />} label="A Level"
                data-testid="englishAcademicAttainmentParent-select-option" />
              {form[stepSlug].data.englishAcademicAttainmentParent === 'aLevel' ?
                <div className={classes.formControlNested}>
                  <RadioButtonsGroup
                    label=""
                    name="englishAcademicAttainment"
                    options={[
                      {id: 'aLevelA', name: 'A Level - A'},
                      {id: 'aLevelB', name: 'A Level - B'},
                      {id: 'aLevelC', name: 'A Level - C'},
                      {id: 'aLevelDE', name: 'A Level - D-E'}
                    ]}
                    value={form[stepSlug].data.englishAcademicAttainment}
                    handleChange={(e) => handleChange(e)}
                  />
                </div>
                : null}

              <FormControlLabel
                value="bachelor" control={<Radio color="primary" />} label="Bachelor Degree"
                data-testid="englishAcademicAttainmentParent-select-option" />
              {form[stepSlug].data.englishAcademicAttainmentParent === 'bachelor' ?
                <div className={classes.formControlNested}>
                  <RadioButtonsGroup
                    label=""
                    name="englishAcademicAttainment"
                    options={[
                      {id: 'degree1st', name: 'Bachelor Degree - 1st'},
                      {id: 'degree21', name: 'Bachelor Degree - 2:1'},
                      {id: 'degree22', name: 'Bachelor Degree - 2:2'},
                      {id: 'degree3rd', name: 'Bachelor Degree - 3rd'},
                      {id: 'degreePass', name: 'Bachelor Degree - Pass'}
                    ]}
                    value={form[stepSlug].data.englishAcademicAttainment}
                    handleChange={(e) => handleChange(e)}
                  />
                </div>
                : null}

              <FormControlLabel
                value="postgrad" control={<Radio color="primary" />} label="Postgraduate Certificate / Diploma"
                data-testid="englishAcademicAttainmentParent-select-option" />
              {form[stepSlug].data.englishAcademicAttainmentParent === 'postgrad' ?
                <div className={classes.formControlNested}>
                  <RadioButtonsGroup
                    label=""
                    name="englishAcademicAttainment"
                    options={[
                      {id: 'postgradDiplomaDistinction', name: 'Postgraduate Certificate / Diploma - Distinction'},
                      {id: 'postgradDiplomaMerit', name: 'Postgraduate Certificate / Diploma - Merit'},
                      {id: 'postgradDiplomaPass', name: 'Postgraduate Certificate / Diploma - Pass'}
                    ]}
                    value={form[stepSlug].data.englishAcademicAttainment}
                    handleChange={(e) => handleChange(e)}
                  />
                </div>
                : null}

              <FormControlLabel
                value="keySkills" control={<Radio color="primary" />} label="Key skills / functional skills"
                data-testid="englishAcademicAttainmentParent-select-option" />
              {form[stepSlug].data.englishAcademicAttainmentParent === 'keySkills' ?
                <div className={classes.formControlNested}>
                  <RadioButtonsGroup
                    label=""
                    name="englishAcademicAttainment"
                    options={[
                      {id: 'keyFuncSkillsLevel1', name: 'Key skills / functional skills - Level 1'},
                      {id: 'keyFuncSkillsLevel2', name: 'Key skills / functional skills - Level 2'},
                      {id: 'keyFuncSkillsLevel3', name: 'Key skills / functional skills - Level 3'}
                    ]}
                    value={form[stepSlug].data.englishAcademicAttainment}
                    handleChange={(e) => handleChange(e)}
                  />
                </div>
                : null}

              <FormControlLabel
                value="other" control={<Radio color="primary" />} label="Other qualification"
                data-testid="englishAcademicAttainmentParent-select-option" />
              {form[stepSlug].data.englishAcademicAttainmentParent === 'other'
                ? <div className={classes.otherTextField}>
                  <Input
                    name="englishAcademicAttainmentOther"
                    label="Please describe (optional)"
                    value={form[stepSlug].data.englishAcademicAttainmentOther}
                    handleChange={(e) => handleChange(e)}
                    error={false}
                    maxLength={100}
                  />
                </div>
                : null}

              <FormControlLabel
                value="noQualificationsAchieved" control={<Radio color="primary" />} label="No qualification achieved"
                data-testid="englishAcademicAttainmentParent-select-option" />

              <FormControlLabel
                value="preferNotToSay" control={<Radio color="primary" />} label="Prefer not to say"
                data-testid="englishAcademicAttainmentParent-select-option" />
            </RadioGroup>
          </div>
        </FormControl>
      </div>
    </React.Fragment>
  );
};
Education.propTypes = {
  handleStepState: PropTypes.func,
  form: PropTypes.object,
  step: PropTypes.object,
};
export default Education;
