import React from 'react';
import PropTypes from 'prop-types';
import RadioButtonsGroup from '../../../../components/form/RadioButtonGroup';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {makeStyles} from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Input from '../../../../components/form/Input';

const useStyles = makeStyles(() => ({
  otherTextField: {
    marginLeft: 58,
  },
  formControlNested: {
    marginLeft: 28
  },
  question: {
    paddingBottom: 15,
    fontWeight: 'bold',
    fontSize: 16
  },
  section: {
    marginTop: 15
  }
}));

const RaceAndReligion = (props) => {

  const {handleStepState, form} = props;
  const stepSlug = 'race-and-religion';
  const classes = useStyles();

  const handleChange = event => handleStepState(stepSlug, {[event.target.name]: event.target.value});

  const handleParentChange = event => {
    handleStepState(stepSlug, {ethnicOrigin: ''});
    handleChange(event);

    if (['arab', 'other', 'preferNotToSay'].includes(form[stepSlug].data.ethnicOriginParent)) {
      handleChange({target: {name: 'ethnicOrigin', value: event.target.value}});
    }
  };

  return (
    <React.Fragment>
      <div className={classes.section}>
        <Typography component="h2" variant="h2">
          Ethnicity
        </Typography>
        <div className={classes.section}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel
              component="legend"
              className={classes.question}
              data-testid="label-ethnicOrigin">What is your ethnic group? Choose one section, then choose one option that best describes your ethnic group or background.
            </FormLabel>
            <div
              className={form[stepSlug].errors && form[stepSlug].errors.findIndex(element => element.includes('ethnicOrigin')) !== -1 ? 'inline-error' : ''}>
              {form[stepSlug].errors && form[stepSlug].errors.findIndex(element => element.includes('ethnicOrigin')) !== -1 ?
                <p>If you do not wish to answer this question, please select ‘Prefer not to say’</p> : ''}

              <RadioGroup
                aria-label="ethnicOriginParent"
                name="ethnicOriginParent"
                value={form[stepSlug].data.ethnicOriginParent} onChange={(e) => handleParentChange(e)}>
                <FormControlLabel
                  value="white"
                  control={<Radio color="primary" />} label="White"
                  data-testid="ethnicOriginParent-select-option" />

                {form[stepSlug].data.ethnicOriginParent === 'white' ?
                  <div className={classes.formControlNested}>
                    <RadioButtonsGroup
                      label=""
                      name="ethnicOrigin"
                      options={[
                        {id: 'whiteBritish', name: 'White – English / Welsh / Scottish / Northern Irish / British'},
                        {id: 'whiteIrish', name: 'White - Irish'},
                        {id: 'whiteGypsyTraveller', name: 'White – Gypsy or Irish Traveller'},
                        {id: 'whiteRoma', name: 'White – Roma'},
                        {id: 'otherWhite', name: 'White – Any other White background'}
                      ]}
                      value={form[stepSlug].data.ethnicOrigin}
                      other={form[stepSlug].data.ethnicOriginOther}
                      labelOther="Please describe (optional)"
                      nameOther="ethnicOriginOther"
                      handleChange={(e) => handleChange(e)}
                      showTextfield="otherWhite"
                    />
                  </div>
                  : null}

                <FormControlLabel
                  value="mixed" control={<Radio color="primary" />} label="Mixed / Multiple ethnic groups"
                  data-testid="ethnicOriginParent-select-option" />
                {form[stepSlug].data.ethnicOriginParent === 'mixed' ?
                  <div className={classes.formControlNested}>
                    <RadioButtonsGroup
                      label=""
                      name="ethnicOrigin"
                      options={[
                        {id: 'mixWhiteBlackCaribbean', name: 'Mixed – White and Black Caribbean'},
                        {id: 'mixWhiteBlackAfrican', name: 'Mixed – White and Black African'},
                        {id: 'mixWhiteAsian', name: 'Mixed – White and Asian'},
                        {id: 'otherMix', name: 'Mixed – Any other Mixed / Multiple ethnic background'}
                      ]}
                      value={form[stepSlug].data.ethnicOrigin}
                      other={form[stepSlug].data.ethnicOriginOther}
                      labelOther="Please describe (optional)"
                      nameOther="ethnicOriginOther"
                      handleChange={(e) => handleChange(e)}
                      showTextfield="otherMix"
                    />
                  </div>
                  : null}

                <FormControlLabel
                  value="asian" control={<Radio color="primary" />} label="Asian / Asian British"
                  data-testid="ethnicOriginParent-select-option" />
                {form[stepSlug].data.ethnicOriginParent === 'asian' ?
                  <div className={classes.formControlNested}>
                    <RadioButtonsGroup
                      label=""
                      name="ethnicOrigin"
                      options={[
                        {id: 'asianAsianBritishBritish', name: 'Asian – English / Welsh / Scottish / Northern Irish / British'},
                        {id: 'asianAsianBritishIndian', name: 'Asian - Indian'},
                        {id: 'asianAsianBritishPakistani', name: 'Asian - Pakistani'},
                        {id: 'asianAsianBritishBangladeshi', name: 'Asian - Bangladeshi'},
                        {id: 'asianAsianBritishChinese', name: 'Asian - Chinese'},
                        {id: 'otherAsianAsianBritish', name: 'Asian – Any other Asian background'}
                      ]}
                      value={form[stepSlug].data.ethnicOrigin}
                      other={form[stepSlug].data.ethnicOriginOther}
                      labelOther="Please describe (optional)"
                      nameOther="ethnicOriginOther"
                      handleChange={(e) => handleChange(e)}
                      showTextfield="otherAsianAsianBritish"
                    />
                  </div>
                  : null}

                <FormControlLabel
                  value="black" control={<Radio color="primary" />} label="Black / African / Caribbean / Black British"
                  data-testid="ethnicOriginParent-select-option" />
                {form[stepSlug].data.ethnicOriginParent === 'black' ?
                  <div className={classes.formControlNested}>
                    <RadioButtonsGroup
                      label=""
                      name="ethnicOrigin"
                      options={[
                        {id: 'blackBlackBritishBritish', name: 'Black – English / Welsh / Scottish / Northern Irish / British'},
                        {id: 'blackBlackBritishAfrican', name: 'Black - African'},
                        {id: 'blackBlackBritishCaribbean', name: 'Black - Caribbean'},
                        {id: 'blackBlackBritishOther', name: 'Black – Any other Black / African / Caribbean / Black British background'}
                      ]}
                      value={form[stepSlug].data.ethnicOrigin}
                      other={form[stepSlug].data.ethnicOriginOther}
                      labelOther="Please describe (optional)"
                      nameOther="ethnicOriginOther"
                      handleChange={(e) => handleChange(e)}
                      showTextfield="blackBlackBritishOther"
                    />
                  </div>
                  : null}

                <FormControlLabel
                  value="otherEthnicGroup" control={<Radio color="primary" />} label="Other ethnic group"
                  data-testid="ethnicOriginParent-select-option" />
                {form[stepSlug].data.ethnicOriginParent === 'otherEthnicGroup' ?
                  <div className={classes.formControlNested}>
                    <RadioButtonsGroup
                      label=""
                      name="ethnicOrigin"
                      options={[
                        {id: 'arab', name: 'Other - Arab'},
                        {id: 'other', name: 'Other – Any other ethnic group'},
                      ]}
                      value={form[stepSlug].data.ethnicOrigin}
                      other={form[stepSlug].data.ethnicOriginOther}
                      labelOther="Please describe (optional)"
                      nameOther="ethnicOriginOther"
                      handleChange={(e) => handleChange(e)}
                      showTextfield="blackBlackBritishOther"
                    />
                  </div>
                  : null}
                {form[stepSlug].data.ethnicOrigin === 'other'
                  ? <div className={classes.otherTextField}>
                    <Input
                      name="ethnicOriginOther"
                      label="Please describe (optional)"
                      value={form[stepSlug].data.ethnicOriginOther}
                      handleChange={(e) => handleChange(e)}
                      error={false}
                      maxLength={100}
                    />
                  </div>
                  : null}

                <FormControlLabel
                  value="preferNotToSay" control={<Radio color="primary" />} label="Prefer not to say"
                  data-testid="ethnicOriginParent-select-option" />
              </RadioGroup>
            </div>
          </FormControl>
        </div>
      </div>

      <div className={classes.section}>
        <Typography component="h2" variant="h2">
          Religion or belief
        </Typography>
        <RadioButtonsGroup
          label="What is your religion?"
          showError={form[stepSlug].errors && (form[stepSlug].errors.includes('religiousBelief') || form[stepSlug].errors.includes('religiousBeliefOther'))}
          errorMsg={'If you do not wish to answer this question, please select ‘Prefer not to say’'}
          name="religiousBelief"
          options={[
            {id: 'noReligion', name: 'No Religion'},
            {id: 'christian', name: 'Christian (including Church of England, Catholic, Protestant and all other Christian denominations)'},
            {id: 'buddhist', name: 'Buddhist'},
            {id: 'hindu', name: 'Hindu'},
            {id: 'jewish', name: 'Jewish'},
            {id: 'muslim', name: 'Muslim'},
            {id: 'pagan', name: 'Pagan'},
            {id: 'sikh', name: 'Sikh'},
            {id: 'other', name: 'Any other religion'},
            {id: 'preferNotToSay', name: 'Prefer not to say'}
          ]}
          value={form[stepSlug].data.religiousBelief}
          other={form[stepSlug].data.religiousBeliefOther}
          labelOther="Please describe (optional)"
          nameOther="religiousBeliefOther"
          handleChange={(e) => handleChange(e)}
          showTextfield="other"
        />
      </div>
    </React.Fragment>
  );
};
RaceAndReligion.propTypes = {
  handleStepState: PropTypes.func,
  form: PropTypes.object,
  step: PropTypes.object,
};
export default RaceAndReligion;
