import Link from '@material-ui/core/Link';
import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import config from '../config/config';
import LinearProgress from '@material-ui/core/LinearProgress';
import {getLoginUrl, hasSession} from '../utils/auth';
import {Trans, useTranslation} from 'react-i18next';
import Message from './Message';

const OAuth2Guard = ({children}) => {
  const [authenticated, setAuthenticated] = useState();
  const [unauthorised, setUnauthorised] = useState(false);
  const {t} = useTranslation();
  const {pathname, search} = useLocation();
  const query = new URLSearchParams(search);

  useEffect(() => {
    hasSession()
      .then(session => {
        if (!session) {
          const invitationId = query.get('invitationId');
          const invitationCode = query.get('invitationCode');
          const state = (invitationId && invitationCode) ? `${pathname}?invitationId=${invitationId}&invitationCode=${invitationCode}` : pathname;
          return window.location.assign(getLoginUrl(config)(state));
        }

        setUnauthorised(false);
        setAuthenticated(session);
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 403) {
          setUnauthorised(true);
        }
        setAuthenticated(false);
      });
  }, [pathname]);

  if (unauthorised) {
    return (
      <React.Fragment>
        <Message type={'error'} message={
          <Trans i18nKey={'common.unauthorisedAccess'}>
            <Link href='mailto:aims.support@college.police.uk' />
          </Trans>
        } />
      </React.Fragment>
    );
  }

  if (authenticated) {
    return children;
  }

  return (
    <React.Fragment>
      <span data-testid="redirecting">{t('common.redirectingToLogin')}</span>
      <LinearProgress />
    </React.Fragment>
  );
};

OAuth2Guard.displayName = 'OAuth2Guard';
OAuth2Guard.propTypes = {
  children: PropTypes.object
};

export default OAuth2Guard;
