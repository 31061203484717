const STATES = Object.freeze({
  CREATED: 'dashboard.app.status.state.created',
  INVALID: 'dashboard.app.status.state.invalid',
  AWAITING_SIFT: 'dashboard.app.status.state.awaiting_sift',
  AWAITING_ALLOCATION: 'dashboard.app.status.state.awaiting_allocation',
  ALLOCATED: 'dashboard.app.status.state.allocated',
  ATTENDED: 'dashboard.app.status.state.attended',
  COMPLETED: 'dashboard.app.status.state.completed',
  WITHDRAWN: 'dashboard.app.status.state.withdrawn',
  REJECTED: 'dashboard.app.status.state.rejected',
  NO_SHOW: 'dashboard.app.status.state.no_show',
  SIFT_COMPLETE: 'dashboard.app.status.state.sift_only_successful',
  UNKNOWN: 'dashboard.app.status.state.unknown'
});

const RESULTS = Object.freeze({
  PASS: 'PASSED',
  FAIL: 'UNSUCCESSFUL'
});

const COMMON = Object.freeze({
  SPECIALS: 'Specials',
  PCSO: 'PCSOs'
});

const OPTIONAL_FIELDS = Object.freeze([
  'academicAttainmentOther', 'disabilityNature', 'englishAcademicAttainmentOther', 'ethnicOriginOther', 'firstLanguage',
  'genderOther', 'highSchoolOther', 'parentAcademicAttainOther', 'religiousBeliefOther', 'sexualOrientationOther'
]);

const SIFT_ONLY_PRODUCTS = Object.freeze(([
  COMMON.PCSO,
  COMMON.SPECIALS,
]));

export {
  COMMON,
  OPTIONAL_FIELDS,
  RESULTS,
  SIFT_ONLY_PRODUCTS,
  STATES
};
