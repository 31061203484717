import {get, post, put} from './calls';
import config from '../config/config';
import {isUserNetworkError} from './errorUtil';

export default {

  getApplications: async () => get(`${config.BFF_BASE_URL}/api/applications`),
  getApplication: async (appId) => get(`${config.BFF_BASE_URL}/api/applications/${appId}`),
  getAssessmentDetails: (appId) => get(`${config.BFF_BASE_URL}/api/applications/${appId}/allocation`),
  getDownloadUrl: (docId) => get(`${config.DOC_STORE_BASE_URL}/documents/${docId}`),
  getAvailableSlots: (appId) => get(`${config.BFF_BASE_URL}/api/applications/${appId}/slots`),
  allocateSlot: (appId, data) => put(`${config.BFF_BASE_URL}/api/applications/${appId}/allocation`, data),

  getSlotErrorMessage: (e, t) => {
    if (isUserNetworkError(e)) {
      return t('dashboard.app.slotAllocation.error.networkError');
    }
    switch (e?.response?.status) {
      case 404:
        return t('dashboard.app.slotAllocation.error.noSlots');
      case 409:
        return t('dashboard.app.slotAllocation.error.reasonableAdjustment');
      case 423:
        return t('dashboard.app.slotAllocation.error.notAllowedTime');
      default:
        return t('dashboard.app.slotAllocation.error.generic');
    }
  },
  withdraw: (appId, data) => post(`${config.BFF_BASE_URL}/api/applications/${appId}/withdraw`, data),
  appActions: (appId) => get(`${config.BFF_BASE_URL}/api/applications/${appId}/actions`),
  redeemInvite: async (data, inviteId, inviteCode) => {

    const verificationData = {
      ...data,
      case: {
        inviteCode
      }
    };
    return post(`${config.BFF_BASE_URL}/api/invite/${inviteId}/redeem`, verificationData);
  },
  promotionExamApply: (form) => post(`${config.BFF_BASE_URL}/api/applications/promotions`, form),
  recruitmentApply: (form) => post(`${config.BFF_BASE_URL}/api/applications/recruitments`, form)
};
