import React from 'react';
import RadioButtonsGroup from '../../../../components/form/RadioButtonGroup';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';

const FirstLanguage = (props) => {

  const {handleStepState, form} = props;
  const stepSlug = 'first-language';
  const handleChange = event => handleStepState(stepSlug, {[event.target.name]: event.target.value});

  return (
    <React.Fragment>
      <Typography component="h2" variant="h2">
        Language
      </Typography>
      <RadioButtonsGroup
        label="What is your main language?"
        showError={form[stepSlug].errors && (form[stepSlug].errors.includes('firstLanguageEnglish') || form[stepSlug].errors.includes('firstLanguage'))}
        errorMsg={'If you do not wish to answer this question, please select ‘Prefer not to say’'}
        name="firstLanguageEnglish"
        options={[
          {id: 'yes', name: 'English'},
          {id: 'welsh', name: 'Welsh'},
          {id: 'no', name: 'Other, including British Sign Language'},
          {id: 'preferNotToSay', name: 'Prefer not to say'}
        ]}
        value={form[stepSlug].data.firstLanguageEnglish}
        other={form[stepSlug].data.firstLanguage}
        nameOther="firstLanguage"
        labelOther="Please describe (optional)"
        handleChange={(e) => handleChange(e)}
        showTextfield="no"
      />
      <RadioButtonsGroup
        label="How well can you speak English?"
        showError={form[stepSlug].errors && form[stepSlug].errors.includes('englishSpeechLevel')}
        errorMsg={'If you do not wish to answer this question, please select ‘Prefer not to say’'}
        name="englishSpeechLevel"
        options={[
          {id: 'fluent', name: 'Fluent'},
          {id: 'advanced', name: 'Advanced'},
          {id: 'intermediate', name: 'Intermediate'},
          {id: 'beginner', name: 'Beginner'},
          {id: 'preferNotToSay', name: 'Prefer not to say'}
        ]}
        value={form[stepSlug].data.englishSpeechLevel}
        handleChange={(e) => handleChange(e)}
      />
    </React.Fragment>
  );
};
FirstLanguage.propTypes = {
  handleStepState: PropTypes.func,
  form: PropTypes.object,
  step: PropTypes.object,
};
export default FirstLanguage;
