import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import {isUserNetworkError} from '../../../../utils/errorUtil';
import PromotionSuccess from './PromotionSuccess';
import RecruitmentSuccess from './RecruitmentSuccess';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 35
  },
  pendingText: {
    marginTop: 10
  }
}));

const Confirmation = (props) => {
  const classes = useStyles();
  const {submissionAction, transformIn, transformOut, handleStepState, form, setErrors, goToStep, type, getForms, productId} = props;
  const stepSlug = 'confirmation';
  const [confirmation, setConfirmation] = useState({status: 'PENDING', errors: []});
  const verificationStepId = getForms().findIndex(f => f.slug === 'verification');

  const submit = async () => {
    let submissionErrors = [];
    let submissionStatus;

    try {
      await submissionAction(transformOut(form));
      submissionStatus = 'OK';
      handleStepState(stepSlug, {completed: true});
    } catch (e) {
      if (e.response && e.response.status === 400) {
        submissionStatus = 'ERROR_BAD_REQUEST';
        const responseData = e.response.data;
        const stepErrors = transformIn(responseData);

        Object.keys(stepErrors).forEach(step => {
          setErrors(step, stepErrors[step].fieldErrors);
          submissionErrors.push({step, stepErrors: Object.keys(stepErrors[step].fieldErrors)});
        });
      } else if (e.response && e.response.status === 422) {
        submissionStatus = 'SHUTTERED';
      } else if (isUserNetworkError(e)) {
        submissionStatus = 'NETWORK_ERROR';
      } else {
        submissionStatus = 'ERROR';
      }
    } finally {
      setConfirmation({
        ...confirmation,
        status: submissionStatus,
        errors: submissionErrors
      });
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    submit();
  }, [stepSlug]);

  const getSuccess = () => type === 'promotion' ? <PromotionSuccess /> : <RecruitmentSuccess productId={productId} />;

  return (
    <div className={classes.root}>
      <Typography component="h2" variant="h2" className={classes.title}>
        {confirmation.status === 'OK' ? 'Confirmation' : confirmation.status === 'PENDING' ? '' : 'Error'}
      </Typography>

      {confirmation.status === 'PENDING'
        ? (
          <Grid container justifyContent="center" spacing={5}>
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          </Grid>
        )
        : (
          <React.Fragment>
            {confirmation.status === 'OK'
              ? getSuccess()
              : confirmation.status === 'ERROR_BAD_REQUEST' ?
                <div>
                  <Typography paragraph={true}>
                    Error while submitting your application.
                  </Typography>
                  <Typography paragraph={true}>
                    Please correct the following errors and try again.
                  </Typography>
                  <Grid container spacing={3}>
                    {confirmation.errors.map(error => {
                      const stepId = getForms().findIndex(f => f.slug === error.step);
                      return (
                        <React.Fragment key={stepId}>
                          <Grid item xs={6}>{getForms()[stepId].title}</Grid>
                          <Grid item xs={6}>
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() => goToStep(stepId, verificationStepId)}
                            >
                              Edit
                            </Link>
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                </div>
                : confirmation.status === 'NETWORK_ERROR' ?
                  <div>
                    <Typography paragraph={true}>
                      There was an error communicating with the server. Please check your network connection and retry.
                    </Typography>
                  </div>
                  : confirmation.status === 'SHUTTERED' ?
                    <div>
                      <Typography paragraph={true}>
                        The force is not currently accepting applications for this assessment. Please contact them directly for guidance.
                      </Typography>
                    </div>
                    : <div>
                      <Typography paragraph={true}>
                        System error. Please contact support.
                      </Typography>
                    </div>
            }
          </React.Fragment>
        )
      }
    </div>
  );
};
Confirmation.propTypes = {
  handleStepState: PropTypes.func,
  transformIn: PropTypes.func,
  transformOut: PropTypes.func,
  submissionAction: PropTypes.func,
  form: PropTypes.object,
  setErrors: PropTypes.func,
  goToStep: PropTypes.func,
  type: PropTypes.string,
  getForms: PropTypes.func,
  productId: PropTypes.string
};
export default Confirmation;
