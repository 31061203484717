import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  content: {
    paddingTop: 10,
    paddingBottom: 10
  }
}));


const Error = (props) => {

  const {title, message} = props;
  const classes = useStyles();

  /* eslint-disable react/no-array-index-key */
  const textContent = message.split('\\n').map((text, i) => {
    return <Typography variant="body1" key={i} className={classes.content}>{text}</Typography>;
  });


  return (
    <React.Fragment>
      <Typography component="h1" variant="h1">
        {title}
      </Typography>
      {textContent}
    </React.Fragment>
  );
};
Error.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
};
export default Error;
