import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import FormLabel from '@material-ui/core/FormLabel';

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: 24
  },
  formControlInner: {
    minWidth: 200,
  },
  question: {
    fontWeight: 'bold',
    fontSize: 16
  }
}));

const FormSelect = (props) => {

  const classes = useStyles();
  const {handleChange, data, showError, value, name, legend} = props;

  return (
    <FormControl component="fieldset" data-testid={`${name}-select-form`} className={classes.formControl}>
      <FormLabel component="legend" className={classes.question}>{legend}</FormLabel>
      <div
        className={showError ? ('inline-error') : ''}>
        {showError ? 'This question is mandatory, an answer is required for you to continue' : ''}
        <FormControl className={classes.formControlInner}>
          <InputLabel id={`${name}-select-label`}>{props.inputLabel}</InputLabel>
          <Select
            name={name}
            labelId={`${name}-select-label`}
            id={`${name}-select`}
            value={value || []}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {data.map(e => <MenuItem key={e.name} value={e.id}>{e.name}</MenuItem>)}
          </Select>
        </FormControl>
      </div>
    </FormControl>
  );
};

FormSelect.propTypes = {
  handleChange: PropTypes.func,
  data: PropTypes.array,
  showError: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  inputLabel: PropTypes.string,
  legend: PropTypes.string
};

export default FormSelect;
