/* eslint-disable */
const formDefinition = () => ({
  ['role-force']: {data: {role: '', force: ''}, errors: []},
  ['personal-details']: {data: {firstName: '', lastName: '', dateOfBirth: '', nationalInsuranceNumber: '', email: ''}, errors: []},
  ['accessibility']: {
    data: {disability: '', disabilityNature: '', reasonableAdjustment: ''},
    errors: []
  },
  ['race-and-religion']: {
    data: {
      ethnicOrigin: '',
      ethnicOriginOther: '',
      ethnicOriginParent: '',
      religiousBelief: '',
      religiousBeliefOther: ''
    }, errors: []
  },
  ['sex-and-gender']: {data: {sex: '', gender: '', genderOther: '', genderMatch: ''}, errors: []},
  ['sexual-orientation']: {data: {sexualOrientation: '', sexualOrientationOther: ''}, errors: []},
  ['socioeconomic-background']: {
    data: {highSchool: '', highSchoolOther: '', parentAcademicAttain: '', parentAcademicAttainOther: '', freeSchoolMeals: '', fromLowerSocioEcoBackground: ''}
  },
  ['education']: {
    data: {
      academicAttainment: '',
      academicAttainmentParent: '',
      englishAcademicAttainment: '',
      englishAcademicAttainmentParent: ''
    }, errors: []
  },
  ['first-language']: {
    data: {firstLanguageEnglish: '', firstLanguage: '', englishSpeechLevel: ''}
  },
  ['entry-route']: {
    data: {entryRoute: ''}
  },
  ['veteran-status']: {
    data: {militaryVeteran: '', militaryServiceLeaver: ''}
  },
  ['police-roles']: {
    data: {anyPreviousPolicingExperience: '', previousPolicingExperience: [], familyPolicingInvolvement: ''}
  },
  ['verification']: {
    data: {}
  },
  ['candidate-declaration']: {data: {}, errors: []},
  confirmation: {data: {formErrors: {}, completed: false}}
});

export default formDefinition;
