import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Grid, Divider, Typography, Paper, CircularProgress} from '@material-ui/core';
import Withdraw from './Withdraw/Withdraw';
import appUtils from '../../../../utils/application';
import {Description as FileIcon} from '@material-ui/icons';
import {STATES} from '../../../../utils/applicationConst';
import typography from '../../../../theme/typography';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(() => ({
  paper: {
    boxShadow: 'none',
  },
  divider: {
    marginTop: 15,
    marginBottom: 15
  },
  icon: {
    position: 'relative',
    top: 5,
    marginRight: 5
  },
  link: {
    fontSize: 17,
  },
  detailsTitle: {
    fontSize: 16,
    letterSpacing: 0.2,
  },
  detailsValue: {
    fontSize: 18,
    letterSpacing: 0.2,
    fontWeight: 700,
  },
  sectionTitle: {
    ...typography.sectionTitle
  },
  sectionSubtitle: {
    ...typography.sectionSubtitle
  },
  status: {
    textTransform: 'uppercase'
  }
}));

const AssessmentStatus = props => {

  const {application, handleApplicationChange} = props;
  const [actions, setActions] = useState({canWithdraw: false, loading: true});
  const classes = useStyles();
  const {t} = useTranslation();

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    const canWithdraw = async () => {
      try {
        const actions = await appUtils.appActions(application.id);
        setActions({...actions, canWithdraw: actions.data.canWithdraw === true, loading: false});

      } catch (e) {
        setActions({...actions, loading: false});
        console.error('Error while checking allowed actions for application', e);
      }
    };
    canWithdraw();
  }, [application.id]);

  const handleAllowedActions = async (modifiedActions) => {
    setActions({...actions, ...modifiedActions});
  };

  const displayProductDocument = () => application.productDocument && application.state !== 'COMPLETED';

  return (
    <div>
      <Typography className={classes.sectionTitle}>
        {t('dashboard.app.status.title')}
      </Typography>
      <Typography className={classes.sectionSubtitle}>
        {t('dashboard.app.status.subTitle')}
      </Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <Typography className={classes.detailsTitle}>
              {t('dashboard.app.status.type')}
            </Typography>
            <Typography className={classes.detailsValue}>
              {application.eventType}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <Typography className={classes.detailsTitle}>
              {t('dashboard.app.status.force')}
            </Typography>
            <Typography className={classes.detailsValue}>
              {application.force}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <Typography className={classes.detailsTitle}>
              {t('dashboard.app.status.status')}
            </Typography>
            <Typography className={`${classes.detailsValue} ${classes.status}`}>
              {t(STATES[application.state])}
            </Typography>
          </Paper>
        </Grid>
        {displayProductDocument()
          ? <Grid item xs={12} sm={12}>
            <Typography className={classes.link}>
              <a target='_blank' rel="noopener noreferrer" href={`/document?id=${application.productDocument}`}>
                <FileIcon className={classes.icon} />{application.eventType} - {t('dashboard.app.status.information')}
              </a>
            </Typography>
          </Grid>
          : null}
      </Grid>
      {actions.loading ? <CircularProgress data-testid="loading" />
        : actions.canWithdraw ?
          <Withdraw
            application={application}
            handleApplicationChange={handleApplicationChange}
            handleAllowedActions={handleAllowedActions}
            data-testid="withdraw-application"
          /> : null}
    </div>
  );
};

AssessmentStatus.propTypes = {
  className: PropTypes.string,
  application: PropTypes.object,
  handleApplicationChange: PropTypes.func,
  dashboardMessage: PropTypes.func,
};

export default AssessmentStatus;
