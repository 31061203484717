import React from 'react';
import Typography from '@material-ui/core/Typography';

const PromotionDeclaration = () => (
  <div>
    <Typography component="h2" variant="h2">
      College of Policing Online Exams Candidate Declaration
    </Typography>
    <Typography paragraph={true}>
      As a police officer taking a professional career exam, you are expected to act with integrity and in line with
      the principles and standards of behaviour set out in the Code of Ethics. Failure to act in line with the Code of
      Ethics could result in your exam result being nullified and/or you being banned from entering the exam in
      future.
    </Typography>
    <Typography paragraph={true}>
      You are expected to ensure you have read and understood the rules of the exam as detailed in the candidate handbook, and to act in
      accordance with these rules at all times. Attempts to gain an unfair advantage in the exam could amount to gross misconduct and any
      candidates suspected of cheating could have their exam result voided and will be referred to their force’s Professional Standards
      Unit, or equivalent.
    </Typography>
    <Typography paragraph={true}>
      <Typography paragraph={false}>
        Before you take the exam, you will be asked to read and agree to a declaration to confirm you are fit to sit and are fully prepared.
        It is expected that you will preserve the integrity of the exam. This means that you will not:
      </Typography>
      <Typography component="ul">
        <Typography component="li">copy or record the exam, or any part thereof</Typography>
        <Typography component="li">be involved in coaching other candidates for the exam</Typography>
        <Typography component="li">discuss or divulge the content of the exam after having gained access to it</Typography>
        <Typography component="li">receive help from someone else while completing the exam</Typography>
        <Typography component="li">attempt to stop the clock or gain additional time within the exam</Typography>
      </Typography>
    </Typography>
    <Typography paragraph={true}>
      On completion of your exam, you are expected to continue to act in accordance with the expectations set out above. Failure to do so could end in your exam result being nullified.
    </Typography>
    <Typography paragraph={true}>
      By submitting your application for the exam, you are confirming that you agree to abide by the expectations outlined above.
    </Typography>
  </div>
);

export default PromotionDeclaration;
