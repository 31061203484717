const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    main: '#2E2C70',
  },
  secondary: {
    contrastText: black,
    main: '#4A4A4A',
  },
  error: {
    contrastText: white,
    main: '#E80000',
  },
  ok: {
    contrastText: white,
    main: '#2F8721',
  },
  text: {
    primary: '#4A4A4A',
    secondary: '#4A4A4A',
  },
  header: {
    primary: '#2E2C70',
    secondary: '#58568D'
  },
  button: {
    primary: {
      color: '#5D4FB5',
      hover: '#58568D'
    },
    secondary: {
      color: '#C75000',
    }
  },
  background: {
    default: '#F4F4F4',
    hover: '#E1E4E7',
    paper: white,
    main: white
  },
};
