/* eslint-disable */
const formDefinition = () => ({
  ['select-exam']: {data: {exam: ''}, errors: []},
  ['select-force']: {data: {force: ''}, errors: []},
  ['personal-details']: {
    data: {firstName: '', lastName: '', dateOfBirth: '', nationalInsuranceNumber: '', email: '', currentPoliceNumber: ''},
    errors: []
  },
  ['accessibility']: {
    data: {disability: '', disabilityNature: '', reasonableAdjustment: ''},
    errors: []
  },
  ['race-and-religion']: {
    data: {
      ethnicOrigin: '',
      ethnicOriginOther: '',
      ethnicOriginParent: '',
      religiousBelief: '',
      religiousBeliefOther: ''
    }, errors: []
  },
  ['marriage-details']: {data: {partnershipStatus: '', pregnancy: ''}, errors: []},
  ['sex-and-gender']: {data: {sex: '', gender: '', genderOther: '', genderMatch: ''}, errors: []},
  ['sexual-orientation']: {data: {sexualOrientation: '', sexualOrientationOther: ''}, errors: []},
  ['socioeconomic-background']: {
    data: {
      highSchool: '',
      highSchoolOther: '',
      parentAcademicAttain: '',
      parentAcademicAttainOther: '',
      freeSchoolMeals: '',
      fromLowerSocioEcoBackground: ''
    }
  },
  ['education']: {
    data: {
      academicAttainment: '',
      academicAttainmentParent: '',
      englishAcademicAttainment: '',
      englishAcademicAttainmentParent: ''
    }, errors: []
  },
  ['first-language']: {
    data: {firstLanguageEnglish: '', firstLanguage: '', englishSpeechLevel: ''}
  },
  ['entry-route']: {
    data: {entryRoute: ''}
  },
  ['veteran-status']: {
    data: {militaryVeteran: ''}
  },
  ['national-schemes']: {
    data: {
      nationalSchemesDirectEntry: '',
      nationalSchemesFastTrack: '',
    }
  },
  ['verification']: {
    data: {}
  },
  ['candidate-declaration']: {data: {}, errors: []},
  confirmation: {data: {formErrors: {}, completed: false}}
});

export default formDefinition;
