import {makeStyles} from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import RadioButtonsGroup from '../../../../components/form/RadioButtonGroup';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  section: {
    marginTop: 15
  }
}));

const SexAndGender = (props) => {

  const {handleStepState, form} = props;
  const stepSlug = 'sex-and-gender';
  const classes = useStyles();

  const handleChange = event => handleStepState(stepSlug, {[event.target.name]: event.target.value});

  return (
    <React.Fragment>
      <div className={classes.section}>
        <Typography component="h2" variant="h2">
          Sex and gender
        </Typography>
        <RadioButtonsGroup
          label="What is your sex?"
          showError={form[stepSlug].errors && (form[stepSlug].errors.includes('sex'))}
          errorMsg={'Please select one option'}
          name="sex"
          options={[
            {id: 'female', name: 'Female'},
            {id: 'male', name: 'Male'},
            {id: 'intersex', name: 'Intersex'}
          ]}
          value={form[stepSlug].data.sex}
          handleChange={(e) => handleChange(e)}
        />
      </div>

      <div className={classes.section}>
        <Typography component="h2" variant="h2">
          Gender identity and gender reassignment
        </Typography>
        <RadioButtonsGroup
          label="Is the gender you identify with the same as your sex registered at birth?"
          showError={form[stepSlug].errors && form[stepSlug].errors.includes('genderMatch')}
          errorMsg={'If you do not wish to answer this question, please select ‘Prefer not to say’'}
          name="genderMatch"
          options={[
            {id: 'yes', name: 'Yes'},
            {id: 'no', name: 'No'},
            {id: 'preferNotToSay', name: 'Prefer not to say'}
          ]}
          value={form[stepSlug].data.genderMatch}
          handleChange={(e) => handleChange(e)}
        />
        <RadioButtonsGroup
          label="If not, what is your gender identity?"
          showError={form[stepSlug].errors && (form[stepSlug].errors.includes('gender') || form[stepSlug].errors.includes('genderOther'))}
          errorMsg={'If you do not wish to answer this question, please select ‘Prefer not to say’'}
          name="gender"
          options={[
            {id: 'man', name: 'Man'},
            {id: 'woman', name: 'Woman'},
            {id: 'nonBinary', name: 'Non-binary'},
            {id: 'inAnotherWay', name: 'Prefer to self-describe'},
            {id: 'preferNotToSay', name: 'Prefer not to say'}
          ]}
          value={form[stepSlug].data.gender}
          other={form[stepSlug].data.genderOther}
          nameOther="genderOther"
          labelOther="Please describe (optional)"
          handleChange={(e) => handleChange(e)}
          showTextfield="inAnotherWay"
        />
      </div>
    </React.Fragment>
  );
};
SexAndGender.propTypes = {
  handleStepState: PropTypes.func,
  form: PropTypes.object,
  step: PropTypes.object,
};
export default SexAndGender;
