import React, {useEffect, useState} from 'react';
import productUtils from '../../../../../utils/products';
import PropTypes from 'prop-types';
import FormSelect from '../../../../../components/form/FormSelect';
import Typography from '@material-ui/core/Typography';

const ForceSelect = (props) => {

  const {handleStepState, form, step} = props;
  const {slug} = step;

  const [forces, setForces] = useState({available: [], force: ''});

  const stepSlug = 'select-force';

  const fetchForces = async () => {
    try {
      const forces = await productUtils.getForces(form.exam);
      setForces({...forces, available: forces.data});
    } catch (e) {
      console.error('Error while loading forces', e);
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (slug === stepSlug) fetchForces();
  }, []);

  const handleChange = event => handleStepState(stepSlug, {[event.target.name]: event.target.value});

  return (
    <React.Fragment>
      <Typography component="h2" variant="h2">
        Force
      </Typography>
      <FormSelect
        name="force"
        legend="Which force are you currently serving with?"
        inputLabel="Force"
        data-testid="force-select-form"
        showError={form[stepSlug].errors && form[stepSlug].errors.includes('force')}
        data={forces.available}
        value={form[stepSlug].data.force}
        handleChange={(e) => handleChange(e)}
      />
    </React.Fragment>
  );
};
ForceSelect.propTypes = {
  handleStepState: PropTypes.func,
  form: PropTypes.object,
  step: PropTypes.object,
};
export default ForceSelect;
