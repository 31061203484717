import Link from '@material-ui/core/Link';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const RecruitmentDeclaration = () => {
  return (
    <div>
      <Typography component="h2" variant="h2">
        Online assessments candidate declaration
      </Typography>
      <Typography component="h3" variant="h3" style={{marginTop: 30, marginBottom: 15}}>
        Confidentiality
      </Typography>
      <Typography paragraph={true}>
        You will be given access to online assessment materials and processes that we use to assess suitability of
        candidates for initial police recruitment.
      </Typography>
      <Typography paragraph={true}>
        <Typography paragraph={false}>
          The following declaration relates to all materials associated with any of the assessment stages. The integrity of our
          online assessments is important to us and all candidates are required to:
        </Typography>
        <Typography component="ul">
          <Typography component="li">
            act in accordance with the principles and standards of behaviour contained within our <a
              href="https://www.college.police.uk/What-we-do/Ethics/Ethics-home/Pages/Code-of-Ethics.aspx" target="_blank" rel="noreferrer"
              className="MuiLink-root">Code of Ethics</a> at
            all times. Failure to do so can result in dismissal if you are successful.
          </Typography>
          <Typography component="li">preserve the integrity of assessment materials:</Typography>
          <Typography component="ul">
            <Typography component="li">you will not copy/record exercises or any part thereof</Typography>
            <Typography component="li">you will not be involved in coaching other candidates having gained access to the assessment materials</Typography>
            <Typography component="li">you will not discuss or divulge the information that you see during the assessments</Typography>
            <Typography component="li">you will complete each exercise by yourself with no help from anyone else</Typography>
          </Typography>
          <Typography component="li">
            notify one of the assessment team if you have previously been involved in the development of, or assessing
            of, candidates taking part in the assessment process
          </Typography>
        </Typography>
      </Typography>
      <Typography paragraph={true}>
        Any candidate found to be disclosing information about the content of the exercises (for example sharing
        content via social media) or using methods to cheat/enhance their performance will be reported to their recruiting force.
        If allegations are found to be true, this may result in the cancellation of the candidate’s application or dismissal if in the role.
      </Typography>
      <Typography component="h3" variant="h3">
        Code of conduct
      </Typography>
      <Typography paragraph={true}>
        It is essential that high professional standards in relation to behaviour, language and appearance are
        maintained throughout any online assessment to ensure it is conducted in an environment in which all team
        members feel valued and comfortable to perform their role.
      </Typography>
      <Typography paragraph={true}>
        <Typography paragraph={false}>
          Any behaviour or language related to the following will be reviewed and not tolerated:
        </Typography>
        <Typography component="ul">
          <Typography component="li">
            Any racist, sexist, homophobic or other discriminatory language or behaviour.
          </Typography>
          <Typography component="li">
            Any racial, sexual, or other form of harassment or bullying language or behaviour.
          </Typography>
        </Typography>
        <Typography paragraph={false}>
          Use of inappropriate language or behaviour will be reviewed and can lead to an unsuccessful result, with severe demonstrations
          resulting in candidates being unable to re-apply for at least two years from the start of the initial assessment. Your recruiting
          force and vetting team can be made aware of these instances. For more information on the use of your data, please refer to the&nbsp;
          <Link href='/privacy-policy' target='_blank'>privacy notice</Link>.
        </Typography>
      </Typography>
      <Typography component="h3" variant="h3">
        Online assessments
      </Typography>
      <Typography paragraph={true}>
        <Typography component="ul">
          <Typography component="li">
            Candidates are only permitted to sit the online assessments with one force. You must inform your forces
            immediately if you have received invitations to complete the online assessments with multiple forces or you
            have already completed the assessments with another force within the last three months.
          </Typography>
          <Typography component="li">
            Candidates wishing to retake the online assessments may do so in three months’ time from the start date of their
            assessment window. Candidates are limited to attempting the assessments twice within a 12-month period.
          </Typography>
        </Typography>
      </Typography>
      <Typography component="h3" variant="h3" style={{marginBottom: 15}}>
        Acknowledgement
      </Typography>
      <Typography paragraph={true}>
        By accessing any online assessment material, you are agreeing to abide by the behaviours set out above,
        particularly in respect of confidentiality. Failure to do so could result in an assessment pass being revoked
        or dismissal if in the role.
      </Typography>
      <Typography paragraph={true}>
        If there is anything within the above that you do not understand, or you have any questions, please contact your
        recruiting force.
      </Typography>
      <Typography paragraph={true}>
        <b>Good luck!</b>
      </Typography>
    </div>
  );
};

RecruitmentDeclaration.propTypes = {
  productId: PropTypes.string
};
export default RecruitmentDeclaration;
