import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import DrawerNav from './components/Drawer';
import Footer from '../Parts/Footer';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: 24,
  },
}));

const Dashboard = ({children}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DrawerNav />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
        <Footer />
      </main>
    </div>
  );
};

Dashboard.displayName = 'DashboardLayout';
Dashboard.propTypes = {
  children: PropTypes.object
};

export default Dashboard;
