import {get} from './calls';
import config from '../config/config';
import appUtils from './application';

export default {
  getProduct: (product) => get(`${config.BFF_BASE_URL}/api/products/${product}?type=RECRUITMENT`), 
  getProducts: () => get(`${config.BFF_BASE_URL}/api/products?type=PROMOTION`),
  getForce: (force, product) => get(`${config.BFF_BASE_URL}/api/forces/${force}?productId=${product}`),
  getForces: (product) => get(`${config.BFF_BASE_URL}/api/forces?productId=${product}`),
  openDocument: async (docId) => {
    const documentUrl = await appUtils.getDownloadUrl(docId);
    if (documentUrl && documentUrl.data && documentUrl.data.download_url) {
      window.open(documentUrl.data.download_url);
    } else {
      throw new Error('Error while retrieving the document');
    }
  }
};
