import React from 'react';
import PersonalDetails from '../components/PersonalDetails/PersonalDetails';
import SexAndGender from '../../components/SexAndGender/SexAndGender';
import SexualOrientation from '../../components/SexualOrientation/SexualOrientation';
import RaceAndReligion from '../../components/RaceAndReligion/RaceAndReligion';
import Accessibility from '../../components/Accessibility/Accessibility';
import Education from '../../components/Education/Education';
import FirstLanguage from '../../components/FirstLanguage/FirstLanguage';
import PoliceRoles from '../components/PoliceRoles/PoliceRoles';
import Confirmation from '../../components/Confirmation/Confirmation';
import Verification from '../components/Verification/Verification';
import appUtils from '../../../../utils/application';
import {processErrors} from '../../utils/fieldMapping';
import {transformOut} from './fieldMapping';
import SelfRegisterLanding from '../SelfRegisterLanding/SelfRegisterLanding';
import SocioeconomicBackground from '../../components/SocioeconomicBackground/SocioeconomicBackground';
import MilitaryVeteran from '../components/MilitaryVeteran/MilitaryVeteran';
import CandidateDeclaration from '../../components/CandidateDeclaration/CandidateDeclaration';

const getFormSpecials = (props) => {
  const {form, handleStepState, step, setErrors, goToStep, forceName, productId, focusField} = props || {};
  return [
    {
      slug: 'role-force',
      title: 'Apply to join the Police',
      component: <SelfRegisterLanding form={form} handleStepState={handleStepState} step={step} forceName={forceName} productId={productId} />
    },
    {
      slug: 'personal-details',
      title: 'Personal details',
      component: <PersonalDetails form={form} handleStepState={handleStepState} step={step} focusField={focusField} />
    },
    {
      slug: 'accessibility',
      title: 'Disability',
      component: <Accessibility form={form} handleStepState={handleStepState} step={step} type="recruitment" policyUrl={'https://www.college.police.uk/guidance/reasonable-adjustment-guide-online-assessments-candidates'} />
    },
    {
      slug: 'race-and-religion',
      title: 'Ethnicity & religion or belief',
      component: <RaceAndReligion form={form} handleStepState={handleStepState} step={step} />
    },
    {
      slug: 'sex-and-gender',
      title: 'Sex & gender',
      component: <SexAndGender form={form} handleStepState={handleStepState} step={step} />
    },
    {
      slug: 'sexual-orientation',
      title: 'Sexual orientation',
      component: <SexualOrientation form={form} handleStepState={handleStepState} step={step} />
    },
    {
      slug: 'socioeconomic-background',
      title: 'Socioeconomic background',
      component: <SocioeconomicBackground form={form} handleStepState={handleStepState} step={step} type="recruitment" />
    },
    {
      slug: 'education',
      title: 'Education',
      component: <Education form={form} handleStepState={handleStepState} step={step} />
    },
    {
      slug: 'first-language',
      title: 'First language',
      component: <FirstLanguage form={form} handleStepState={handleStepState} step={step} />
    },
    {
      slug: 'veteran-status',
      title: 'Veteran status',
      component: <MilitaryVeteran form={form} handleStepState={handleStepState} step={step} />
    },
    {
      slug: 'police-roles',
      title: 'Police roles',
      component: <PoliceRoles form={form} handleStepState={handleStepState} step={step}  productId={productId} />
    },
    {
      slug: 'verification',
      title: 'Verification',
      component: <Verification form={form} handleStepState={handleStepState} step={step} goToStep={goToStep} getForms={getFormSpecials} />
    },
    {
      slug: 'candidate-declaration',
      title: 'Candidate declaration',
      component: <CandidateDeclaration form={form} handleStepState={handleStepState} step={step} type="recruitment" productId={productId} />
    },
    {
      slug: 'confirmation',
      title: 'Confirmation',
      component: <Confirmation
        form={form}
        handleStepState={handleStepState}
        setErrors={setErrors}
        goToStep={goToStep}
        submissionAction={appUtils.recruitmentApply}
        transformIn={processErrors(form)}
        transformOut={transformOut}
        type="recruitment"
        getForms={getFormSpecials}
        productId={productId}
      />
    }
  ];
};

export default getFormSpecials;
