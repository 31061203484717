import palette from './palette';

export default {
  h1: {
    color: palette.header.primary,
    fontWeight: 700,
    fontSize: '70px',
    lineHeight: '82px',
    letterSpacing: '0.2px',
    marginBottom: '20px',
    '@media screen and (min-width: 0px) and (max-width: 1500px)': {
      fontSize: '40px',
      lineHeight: '51px'
    },
  },
  h2: {
    color: palette.header.primary,
    fontWeight: 700,
    fontSize: '50px',
    letterSpacing: '0.2px',
    lineHeight: '58px',
    '@media screen and (min-width: 0px) and (max-width: 1500px)': {
      fontSize: '32px',
      lineHeight: '39px'
    },
  },
  h3: {
    color: palette.header.secondary,
    fontWeight: 700,
    fontSize: '34px',
    letterSpacing: '0.2px',
    lineHeight: '40px',
    '@media screen and (min-width: 0px) and (max-width: 1500px)': {
      fontSize: '32px',
      lineHeight: '39px'
    },
  },
  h4: {
    color: palette.black,
    fontWeight: 700,
    fontSize: '22px',
    letterSpacing: '0.2px',
    lineHeight: '28px',
    '@media screen and (min-width: 0px) and (max-width: 1500px)': {
      fontSize: '24px',
      lineHeight: '29px'
    },
  },
  body1: {
    color: palette.text.primary,
    fontSize: '16px',
    letterSpacing: '0.2px',
    lineHeight: '26px'
  },
  body2: {
    color: palette.text.secondary,
    fontSize: '14px',
    letterSpacing: '0.2px',
    lineHeight: '24px'
  },
  fontFamily: 'Gotham',
  contentBold: {
    fontSize: '18px',
    fontWeight: 700
  },
  sectionTitle: {
    fontSize: 22,
    fontWeight: 700,
    color: palette.header.secondary
  },
  sectionSubtitle: {
    fontSize: 18
  },
  bold: {
    fontWeight: 700
  }
};
