import React from 'react';
import {makeStyles, Typography} from '@material-ui/core';
import palette from '../theme/palette';
import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';
import BlockIcon from '@material-ui/icons/Block';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(() => ({
  resultBox: {
    padding: 10,
    fontSize: 20,
    width: '50%',
    textAlign: 'center',
    margin: '0 auto'
  },
  resultText: {
    display: 'inline',
    color: palette.white,
    textTransform: 'uppercase'
  },
  fail: {
    color: palette.error.contrastText,
    backgroundColor: palette.error.main,
  },
  pass: {
    color: palette.ok.contrastText,
    backgroundColor: palette.ok.main,
  },
  icon: {
    position: 'relative',
    top: 5
  }
}));

const ResultStatus = (props) => {

  const classes = useStyles();
  const {passed} = props;
  const {t} = useTranslation();

  return (
    <div className={`${classes.resultBox} ${passed ? classes.pass : classes.fail}`}>
      {passed ?
        (<React.Fragment>
          <CheckIcon className={classes.icon} />
          <Typography variant="body1" className={classes.resultText}>{t('dashboard.app.results.status.passed')}
          </Typography>
        </React.Fragment>) :
        (<React.Fragment>
          <BlockIcon className={classes.icon} />
          <Typography
            variant="body1"
            className={classes.resultText}>{t('dashboard.app.results.status.unsuccessful')}</Typography>
        </React.Fragment>)}
    </div>
  );
};
ResultStatus.propTypes = {
  passed: PropTypes.bool
};
export default ResultStatus;
