import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import profileUtils from '../../../utils/profile';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
    marginTop: 20,
    marginBottom: 20
  },
  profileName: {
    fontWeight: 700
  }
}));

const Profile = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  const [candidate, setCandidate] = useState({
    fullName: '',
    candidateNumber: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await profileUtils.getProfile();
        if (userData.data)
          setCandidate({
            fullName: `${userData.data.firstName} ${userData.data.lastName}`,
            candidateNumber: userData.data.candidateNumber
          });

      } catch (e) {

        console.error('Error while retrieving data from JWT token', e);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={classes.root}>
      <Typography variant="body1" className={classes.profileName}>{candidate.fullName}</Typography>
      <Typography variant="body2">{t('dashboard.drawer.candidateNumber')}</Typography>
      <Typography variant="body2">{candidate.candidateNumber}</Typography>
    </div>
  );
};

export default Profile;
