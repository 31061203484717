import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import {Trans, useTranslation} from 'react-i18next';


const AccessibilityStatement = () => {
  const {t} = useTranslation();

  return (
    <React.Fragment>
      <Typography component="h1" variant="h1" align="left">
        {t('pages.accessibility.title')}
      </Typography>

      <Typography paragraph={true} align="left">
        {t('pages.accessibility.intro1')}
      </Typography>

      <Typography paragraph={true} align="left">
        {t('pages.accessibility.intro2')}
      </Typography>

      <Typography component="h2" variant="h2" align="left">
        {t('pages.accessibility.accessAdvice.title')}
      </Typography>

      <Typography paragraph={true} align="left">
        {t('pages.accessibility.accessAdvice.intro')}
      </Typography>

      <ul>
        <Typography component="li">{t('pages.accessibility.accessAdvice.advice1')}</Typography>
        <Typography component="li">{t('pages.accessibility.accessAdvice.advice2')}</Typography>
        <Typography component="li">{t('pages.accessibility.accessAdvice.advice3')}</Typography>
        <Typography component="li">{t('pages.accessibility.accessAdvice.advice4')}</Typography>
        <Typography component="li">{t('pages.accessibility.accessAdvice.advice5')}</Typography>
      </ul>

      <Typography paragraph={true} align="left">
        <Trans i18nKey={'pages.accessibility.accessAdvice.abilityNet'}>
          <Link href='https://mcmw.abilitynet.org.uk/' />
        </Trans>
      </Typography>

      <Typography component="h2" variant="h2" align="left">
        {t('pages.accessibility.alternativeFormat.title')}
      </Typography>

      <Typography paragraph={true} align="left">
        <Trans i18nKey={'pages.accessibility.alternativeFormat.text'}>
          <Link href='mailto:contactcentre@college.pnn.police.uk' />
        </Trans>
      </Typography>

      <Typography component="h2" variant="h2" align="left">
        {t('pages.accessibility.reportIssues.title')}
      </Typography>

      <Typography paragraph={true} align="left">
        <Trans i18nKey={'pages.accessibility.reportIssues.text'}>
          <Link href='mailto:contactcentre@college.pnn.police.uk' />
        </Trans>
      </Typography>

      <Typography component="h2" variant="h2" align="left">
        {t('pages.accessibility.enforcement.title')}
      </Typography>

      <Typography paragraph={true} align="left">
        <Trans i18nKey={'pages.accessibility.enforcement.text'}>
          <Link href='https://www.equalityadvisoryservice.com/' />
        </Trans>
      </Typography>

      <Typography component="h2" variant="h2" align="left">
        {t('pages.accessibility.technicalInfo.title')}
      </Typography>

      <Typography paragraph={true} align="left">
        <Trans i18nKey={'pages.accessibility.technicalInfo.text'}>
          <Link href='https://www.w3.org/TR/WCAG21/' />
        </Trans>
      </Typography>

      <Typography component="h2" variant="h2" align="left">
        {t('pages.accessibility.notAccessible.title')}
      </Typography>

      <Typography paragraph={true} align="left">
        {t('pages.accessibility.notAccessible.text')}
      </Typography>

      <Typography component="h3" variant="h3" align="left">
        {t('pages.accessibility.nonCompliance.title')}
      </Typography>

      <Typography paragraph={true} align="left">
        {t('pages.accessibility.nonCompliance.intro')}
      </Typography>

      <ul>
        <Typography component="li">{t('pages.accessibility.nonCompliance.defect1')}</Typography>
        <Typography component="li">{t('pages.accessibility.nonCompliance.defect2')}</Typography>
        <Typography component="li">{t('pages.accessibility.nonCompliance.defect3')}</Typography>
        <Typography component="li">{t('pages.accessibility.nonCompliance.defect4')}</Typography>
        <Typography component="li">{t('pages.accessibility.nonCompliance.defect5')}</Typography>
        <Typography component="li">{t('pages.accessibility.nonCompliance.defect6')}</Typography>
        <Typography component="li">{t('pages.accessibility.nonCompliance.defect7')}</Typography>
        <Typography component="li">{t('pages.accessibility.nonCompliance.defect8')}</Typography>
        <Typography component="li">{t('pages.accessibility.nonCompliance.defect9')}</Typography>
        <Typography component="li">{t('pages.accessibility.nonCompliance.defect10')}</Typography>
      </ul>

      <Typography component="h2" variant="h2" align="left">
        {t('pages.accessibility.testing.title')}
      </Typography>

      <Typography paragraph={true} align="left">
        {t('pages.accessibility.testing.text')}
      </Typography>

      <Typography component="h2" variant="h2" align="left">
        {t('pages.accessibility.improvement.title')}
      </Typography>

      <Typography paragraph={true} align="left">
        {t('pages.accessibility.improvement.text')}
      </Typography>

      <Typography paragraph={true} align="left">
        {t('pages.accessibility.publishing')}
      </Typography>
    </React.Fragment>
  );
};

export default AccessibilityStatement;
