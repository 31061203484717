import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/styles';
import theme from './theme';
import Routes from './Routes';
import CssBaseline from '@material-ui/core/CssBaseline';
import CookieConsent from './components/CookieConsent';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes />
      </Router>
      <CookieConsent />
    </ThemeProvider>
  );
};

export default App;
