import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import Topbar from './components/Topbar';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Footer from '../Parts/Footer';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 64,
    height: '100%'
  },
  paper: {
    width: '80%',
    margin: '0 auto',
    marginTop: 50,
    padding: 30,
    '@media screen and (min-width: 0px) and (max-width: 700px)': {
      padding: 20,
      width: '95%'
    }
  }
}));

const Basic = props => {

  const {children, translate} = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Topbar translate={translate} />
      <Paper className={classes.paper}>
        <Container maxWidth="lg">
          <main className={classes.content}>{children}</main>
        </Container>
      </Paper>
      <Footer />
    </div>
  );
};

Basic.defaultProps = {
  translate: true
};
Basic.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  translate: PropTypes.bool
};

export default Basic;
