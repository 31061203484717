import React from 'react';
import PropTypes from 'prop-types';
import RadioButtonsGroup from '../../../../components/form/RadioButtonGroup';
import Typography from '@material-ui/core/Typography';

const Accessibility = (props) => {

  const {handleStepState, form, type} = props;
  const stepSlug = 'accessibility';

  const handleChange = event => handleStepState(stepSlug, {[event.target.name]: event.target.value});

  const getReasonableAdjustment = () => {
    if (type === 'promotion') {
      return <RadioButtonsGroup
        label="Do you intend to request reasonable adjustment under the Equality Act 2010?"
        info={
          <React.Fragment>
            <Typography paragraph={true}>
              For instance, you may require a reasonable adjustment such as extra time or an accommodation such as being able to take a scheduled break during the exam.
            </Typography>
            <Typography paragraph={true}>
              For further information on our reasonable adjustment policy and how you can access support please refer to the College of Policing Selection & Assessments&apos; Policy on <a href="https://www.college.police.uk/What-we-do/Development/Promotion/Documents/RA%20Policy%20Examinations.pdf" rel="noopener noreferrer" target="_blank">Accommodations and Reasonable Adjustments</a>.
            </Typography>
          </React.Fragment>}
        showError={form[stepSlug].errors && form[stepSlug].errors.includes('reasonableAdjustment')}
        name="reasonableAdjustment"
        options={[
          {id: 'no', name: 'No'},
          {id: 'yes', name: 'Yes'}
        ]}
        value={form[stepSlug].data.reasonableAdjustment}
        handleChange={(e) => handleChange(e)}
      />;
    } else {
      return <RadioButtonsGroup
        label="Do you intend to request reasonable adjustment under the Equality Act 2010?"
        info={
          <React.Fragment>
            <Typography paragraph={true}>
              For instance, you may require a reasonable adjustment such as extra time.
            </Typography>
            <Typography paragraph={true}>
              For further information on our reasonable adjustment policy and how you can access support please refer to the College of Policing Selection & Assessments&apos; Policy via <a href={props.policyUrl} rel="noopener noreferrer" target="_blank">Accommodations and Reasonable Adjustments</a>.
            </Typography>
          </React.Fragment>}
        showError={form[stepSlug].errors && form[stepSlug].errors.includes('reasonableAdjustment')}
        name="reasonableAdjustment"
        options={[
          {id: 'no', name: 'No'},
          {id: 'yes', name: 'Yes'},
        ]}
        value={form[stepSlug].data.reasonableAdjustment}
        handleChange={(e) => handleChange(e)}
      />;
    }
  };

  return (
    <React.Fragment>
      <Typography component="h2" variant="h2">
        Disability
      </Typography>
      <Typography paragraph={true}>
        Disability is a physical or mental impairment, which has a substantial and long-term adverse effect on a person’s ability to carry
        out normal day-to-day activities. This includes progressive and long-term conditions from the point of diagnosis, such as HIV,
        multiple sclerosis, cancer, mental illness or mental health problems, diabetes, epilepsy and neurodiverse conditions such as dyslexia.
        This also includes ‘disabled’ as per the definition set out in the Equality Act 2010.
      </Typography>
      {/* eslint-disable react/no-unescaped-entities */}
      <RadioButtonsGroup
        label="Do you consider yourself to have a disability according to the definition above?"
        showError={form[stepSlug].errors && (form[stepSlug].errors.includes('disability') || form[stepSlug].errors.includes('disabilityNature'))}
        errorMsg={'If you do not wish to answer this question, please select ‘Prefer not to say’'}
        name="disability"
        options={[
          {id: 'no', name: 'No'},
          {id: 'yes', name: 'Yes'},
          {id: 'preferNotToSay', name: 'Prefer not to say'},
        ]}
        value={form[stepSlug].data.disability}
        other={form[stepSlug].data.disabilityNature}
        nameOther="disabilityNature"
        labelOther="Please describe (optional)"
        handleChange={(e) => handleChange(e)}
        showTextfield="yes"
        maxLength={500}
      />
      {getReasonableAdjustment()}
    </React.Fragment>
  );
};
Accessibility.propTypes = {
  handleStepState: PropTypes.func,
  type: PropTypes.string,
  form: PropTypes.object,
  step: PropTypes.object,
  policyUrl: PropTypes.string,
};
export default Accessibility;
