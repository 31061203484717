import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Grid, Typography} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Input from '../../../components/form/Input';
import DateInput from '../../../components/form/DateInput';
import appUtils from '../../../utils/application';
import {makeStyles} from '@material-ui/styles';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import {isValidNIN} from '../../../utils/string';
import Container from '@material-ui/core/Container';
import {useTranslation} from 'react-i18next';
import isValidDateOfBirth from '../../../utils/date';

const Verification = (() => {
  const {t} = useTranslation();
  const history = useHistory();
  const [verificationState, setVerificationState] = useState({
    fields: {
      nin: '',
      dateOfBirth: ''
    },
    errors: [],
    isFormValid: false,
    redirect: {
      pathname: '/error',
      state: {},
      shouldRedirect: false
    },
    submissionResponse: {},
  });
  const query = new URLSearchParams(useLocation().search);

  const isError = (fieldName) => !!verificationState.errors.find(f => f.field === fieldName);

  const validateStep = () => {

    const errors = [];

    if (verificationState.fields.nin.length === 0) {
      errors.push({field: 'nin', message: t('pages.verification.errors.ninRequired')});
    } else if (!isValidNIN(verificationState.fields.nin)) {
      errors.push({field: 'nin', message: t('pages.verification.errors.ninInvalid')});
    }

    if (!isValidDateOfBirth(verificationState.fields.dateOfBirth)) {
      errors.push({field: 'dateOfBirth', message: t('pages.verification.errors.dobRequired')});
    }

    setVerificationState({
      ...verificationState,
      errors
    });

    return errors.length === 0;
  };

  const submit = (event) => {
    event.preventDefault();
    validateStep();
    nextStep();
  };

  const nextStep = async () => {

    // TODO: update to contract https://trello.com/c/SSK5oJky/141-redeem-invitation-post-contract
    if (validateStep()) {

      const applicationId = query.get('invitationId') || sessionStorage.getItem('invitationId');
      const invitationCode = query.get('invitationCode') || sessionStorage.getItem('invitationCode');
      if (!(applicationId && invitationCode)) {
        window.location.state = {
          title: t('pages.verification.errors.failedToRedeemInvitationTitle'),
          message: t('pages.verification.errors.failedToRedeemInvitationMsg')
        };
        setVerificationState({
          ...verificationState,
          redirect:
            {
              pathname: '/error',
              shouldRedirect: true
            },
        });
        return;
      }

      try {
        let submissionResponse = await appUtils.redeemInvite(preparedData(verificationState.fields), applicationId, invitationCode);

        if (submissionResponse.data.status !== 'OK') {
          window.location.state = {
            title: t('pages.verification.errors.noMatchTitle'),
            message: t('pages.verification.errors.noMatchMsg'),
            retry: true
          };
        }

        setVerificationState({
          fields: {
            ...verificationState.fields
          },
          errors: verificationState.errors,
          isValid: verificationState.isValid,
          redirect: (submissionResponse.data.status === 'OK')
            ? {pathname: `/applications/${applicationId}`, shouldRedirect: true}
            : {pathname: '/error', shouldRedirect: true},
          submissionResponse: submissionResponse.data
        });

      } catch (e) {
        console.error('Verification submission failed because of: ', e);
        window.location.state = {
          title: t('pages.verification.errors.submissionTitle'),
          message: t('pages.verification.errors.submissionMsg')
        };
        setVerificationState({
          ...verificationState,
          redirect:
            {
              pathname: '/error',
              shouldRedirect: true
            },
        });
      }
    }
  };

  const preparedData = (formData) => {
    return {
      candidate: {
        nin: formData.nin,
        dob: moment(formData.dateOfBirth, 'YYYY-M-D').format('DD-MM-YYYY')
      }
    };
  };

  const handleChange = (event) => {

    setVerificationState({
      fields: {
        ...verificationState.fields,
        [event.target.name]: event.target.value
      },
      errors: [...verificationState.errors].filter(e => e.field !== event.target.name),
      redirect: {...verificationState.redirect}
    });

  };
  const useStyles = makeStyles(() => ({
    paper: {
      boxShadow: 'none',
      padding: 30
    },
    nextButton: {
      margin: '15px 0 15px 0',
      float: 'right'
    },
  }));

  const classes = useStyles();

  return (
    <Container maxWidth={'sm'}>
      {(verificationState.redirect.shouldRedirect) ?
        history.push(verificationState.redirect.pathname) : null}
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h1" align="center" fontWeight="fontWeightBold">
          {t('pages.verification.title')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t('pages.verification.p1')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t('pages.verification.p2')}
        </Typography>
        <Grid container spacing={3} style={{marginTop: 30}} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Input
              name="nin"
              title={t('pages.verification.nin')}
              placeholder="QQ123456C"
              showError={isError('nin')}
              errorMessage={isError('nin') ? verificationState.errors.find(f => f.field === 'nin').message : ''}
              value={verificationState.fields.nin}
              handleChange={(e) => handleChange(e)}
              maxLength={9}
            />
          </Grid>
          <DateInput
            name="dateOfBirth"
            title={t('pages.verification.dob')}
            showError={isError('dateOfBirth')}
            errorMessage={isError('dateOfBirth') ? verificationState.errors.find(f => f.field === 'dateOfBirth').message : ''}
            handleChange={(e) => handleChange(e)}
            value={verificationState.fields.dateOfBirth}
            label={{
              day: t('pages.verification.day'),
              month: t('pages.verification.month'),
              year: t('pages.verification.year')
            }}
          />
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={submit}
          className={classes.nextButton}>{t('pages.verification.nextStep')} &gt;</Button>
      </Paper>
    </Container>
  );
});

export default Verification;
