import {makeStyles} from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import RadioButtonsGroup from '../../../../../components/form/RadioButtonGroup';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  section: {
    marginTop: 15
  }
}));

const MarriageDetails = (props) => {

  const {handleStepState, form} = props;
  const stepSlug = 'marriage-details';
  const classes = useStyles();

  const handleChange = event => handleStepState(stepSlug, {[event.target.name]: event.target.value});

  return (
    <React.Fragment>
      <div className={classes.section}>
        <Typography component="h2" variant="h2">
          Marriage and civil partnership
        </Typography>
        <RadioButtonsGroup
          label="What is your marital or same-sex civil partnership status?"
          showError={form[stepSlug].errors && form[stepSlug].errors.includes('partnershipStatus')}
          errorMsg={'If you do not wish to answer this question, please select ‘Prefer not to say’'}
          name="partnershipStatus"
          options={[
            {id: 'single', name: 'Never married and never registered in a civil partnership'},
            {id: 'married', name: 'Married'},
            {id: 'civilPartner', name: 'In a registered civil partnership'},
            {id: 'separated', name: 'Separated, but still legally married'},
            {id: 'civilPartnerSeparated', name: 'Separated, but still legally in a civil partnership'},
            {id: 'divorced', name: 'Divorced'},
            {id: 'civilPartnerDisolved', name: 'Formerly in a civil partnership which is now legally dissolved'},
            {id: 'widowed', name: 'Widowed'},
            {id: 'civilPartnerSurviving', name: 'Surviving partner from a civil partnership'},
            {id: 'preferNotToSay', name: 'Prefer not to say'}
          ]}
          value={form[stepSlug].data.partnershipStatus}
          handleChange={(e) => handleChange(e)}
        />
      </div>
      <div className={classes.section}>
        <Typography component="h2" variant="h2">
          Pregnancy, maternity and adoption
        </Typography>
        <RadioButtonsGroup
          label="Which of the following best describes you?"
          showError={form[stepSlug].errors && form[stepSlug].errors.includes('pregnancy')}
          errorMsg={'If you do not wish to answer this question, please select ‘Prefer not to say’'}
          name="pregnancy"
          options={[
            {id: 'currentlyPregnant', name: 'Currently pregnant'},
            {id: 'givenBirthInLast26Weeks', name: 'You or your partner have given birth or adopted in the last 26 weeks'},
            {id: 'neitherPregnantOrGivenBirthInLast26weeks', name: 'Neither pregnant nor given birth or adopted in the last 26 weeks'},
            {id: 'preferNotToSay', name: 'Prefer not to say'},
          ]}
          value={form[stepSlug].data.pregnancy}
          handleChange={(e) => handleChange(e)}
        />
      </div>
    </React.Fragment>
  );
};
MarriageDetails.propTypes = {
  handleStepState: PropTypes.func,
  form: PropTypes.object,
  step: PropTypes.object,
};
export default MarriageDetails;
