import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import axios from 'axios';
import config from '../../../config/config';
import Error from '../../../components/Error';
import ExpiredInvitation from './components/Expired';
import {useTranslation} from 'react-i18next';

const InvitationCheck = () => {

  const {t} = useTranslation();
  const [invitationState, setInvitationState] = useState(false);
  const invitationId = window.location.pathname.replace('/invite/', '');
  const params = new URLSearchParams(window.location.search);
  const invitationCode = params.get('code');

  useEffect(() => {
    const verify = async () => {

      try {
        const resp = await axios.get(
          `${config.BFF_BASE_URL}/api/invite/${invitationId}/check?code=${invitationCode}`
        );

        setInvitationState(resp.data.state);

      } catch (e) {
        console.error('Failed to check invite status because of: ', e);
        switch (e.response.status) {
          case 404:
            setInvitationState('NOT_FOUND');
            break;
          case 400:
            setInvitationState('CODE_MISSING');
            break;
          default:
            setInvitationState('GENERIC_ERROR');
        }
      }
    };
    verify();
  }, [invitationCode, invitationId]);

  const checkInvitation = () => {
    switch (invitationState) {
      case 'VALID':
        sessionStorage.setItem('invitationId', invitationId);
        sessionStorage.setItem('invitationCode', invitationCode);
        return <Redirect to={`/verify?invitationId=${invitationId}&invitationCode=${invitationCode}`} />;
      case 'REDEEMED':
        return <Redirect to="/applications" />;
      case 'EXPIRED':
        return <ExpiredInvitation />;
      case 'INVALID_CODE':
        return <Error title={t('invitation.invalidCodeTitle')} message={t('invitation.invalidCodeMsg')} />;
      case 'CODE_MISSING':
        return <Error title={t('invitation.codeMissingTitle')} message={t('invitation.codeMissingMsg')} />;
      case 'NOT_FOUND':
        return <Error title={t('invitation.notFoundTitle')} message={t('invitation.notFoundMsg')} />;
      case 'GENERIC_ERROR':
        return <Redirect to="/error" />;
      default:
        return <React.Fragment>
          {t('invitation.checkingCode')}
          <LinearProgress />
        </React.Fragment>;
    }
  };

  return (
    <React.Fragment>
      {checkInvitation()}
    </React.Fragment>
  );
};
export default InvitationCheck;
