import React from 'react';
import Error from '../../../../components/Error';
import Button from '@material-ui/core/Button';
import {makeStyles, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(() => ({
  bottomText: {
    padding: '20px 0'
  },
  loginButton: {
    margin: '20px 0'
  }
}));

const ExpiredInvitation = () => {

  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <div data-testid="expired-message">
      <Error title={t('invitation.expiredTitle')} message={t('invitation.expiredMsg1')} />
      <Button variant="contained" color="primary" className={classes.loginButton} onClick={() => window.location.href = '/'}>
        {t('common.login')}
      </Button>
      <Typography variant="body1" className={classes.bottomText}>{t('invitation.expiredMsg2')}</Typography>
    </div>
  );
};

export default ExpiredInvitation;
