import axios from 'axios';
import config from '../config/config';
import {getAccessToken, getLoginUrl, renewTokens} from './auth';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use((response) => response, (error) => {

  if (error.response && error.response.status === 401) {
    return renewTokens().then(() => {
      error.config.headers.Authorization = authorizationHeader();
      return axios(error.config);
    }).catch((renewError) => {
      console.error('Failed to renew auth tokens because: ', renewError);
      // Redirect to login screen when session cannot be refreshed
      return window.location.assign(getLoginUrl(config)(window.location.pathname));
    });
  }

  return Promise.reject(error);
});

const authorizationHeader = () => {
  const accessToken = getAccessToken();
  return accessToken ? `Bearer ${accessToken}` : '';
};

const call = (method, url, data) => {
  const options = {
    method,
    headers: {
      'Authorization': authorizationHeader(),
    },
    url,
    data,
  };

  return axiosInstance.request(options);
};

const get = (url) => {
  return call('GET', url);
};

const post = (url, data) => {
  return call('POST', url, data);
};

const put = (url, data) => {
  return call('PUT', url, data);
};

export {get, post, put};
