import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  stepper:{
    '@media screen and (max-width: 700px)': {
      marginBottom: 20
    }
  }
}));

const FormProgress = (props) => {
  const {activeStep, form, getForms} = props;
  const classes = useStyles();

  const calculateScreenReadersLabel = () => {
    return `Step ${activeStep + 1} out of ${getForms().length}`;
  };

  return (
    <div className={classes.stepper} aria-label={calculateScreenReadersLabel()}>
      <Stepper activeStep={activeStep}>
        {getForms().map(({title, slug}) => {

          const stepProps = {};
          (form[slug].data.completed) ? stepProps.completed = true : stepProps.completed = false;

          return (
            <Step key={title} {...stepProps}>
              <Tooltip title={title}>
                <StepLabel></StepLabel>
              </Tooltip>
            </Step>);
        })}
      </Stepper>
    </div>);
};

FormProgress.propTypes = {
  activeStep: PropTypes.number,
  form: PropTypes.object,
  getForms: PropTypes.func
};
export default FormProgress;
