import React, {useEffect, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {CircularProgress, Paper} from '@material-ui/core';
import PropTypes from 'prop-types';
import appUtils from '../../../utils/application';
import palette from '../../../theme/palette';
import typography from '../../../theme/typography';
import {useTranslation} from 'react-i18next';
import {STATES} from '../../../utils/applicationConst';

const useStyles = makeStyles({
  root: {
    padding: 24
  },
  cardActions: {
    textAlign: 'center',
    marginTop: 10,
  },
  singleCard: {
    margin: '10px 0 10px 0',
    '&:hover': {
      backgroundColor: palette.background.hover
    },
    '& .appData': {
      ...typography.contentBold
    }
  }
});

const AppIndex = () => {

  const classes = useStyles();
  const [appIndex, setAppIndex] = useState({apps: [], loading: true});
  const {t} = useTranslation();

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    const getApps = async () => {
      try {
        const pagedApplications = await appUtils.getApplications();
        setAppIndex({...appIndex, apps: pagedApplications.data.applications, loading: false});

      } catch (e) {
        setAppIndex({...appIndex, loading: false});
        console.error(e);
      }
    };
    getApps();
  }, []);

  return (
    <Paper className={classes.root}>
      <Typography variant="h1" component="h1">
        {t('dashboard.appIndex.title')}
      </Typography>
      {appIndex.apps && appIndex.apps.length > 0 ?
        appIndex.apps.map(app =>
          (
            <Card className={classes.singleCard} key={app.id} data-testid="card-single-app">
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <Typography variant="body2" color="textSecondary">
                      {t('dashboard.appIndex.applicationType')}
                    </Typography>
                    <Typography className="appData">
                      {app.eventType}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2" color="textSecondary">
                      {t('dashboard.appIndex.force')}
                    </Typography>
                    <Typography className="appData">
                      {app.force}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2" color="textSecondary">
                      {t('dashboard.appIndex.status')}
                    </Typography>
                    <Typography className="appData">
                      {t(STATES[app.state]).toUpperCase()}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <div className={classes.cardActions}>
                      <Button component={RouterLink} color="primary" variant="contained" to={`/applications/${app.id}`}>
                        {t('dashboard.appIndex.view')}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )
        )
        : <React.Fragment>
          {appIndex.loading ? <CircularProgress data-testid="loading" />
            : <Typography paragraph={true}>
              {t('dashboard.appIndex.noApps')}
            </Typography>}
        </React.Fragment>}
    </Paper>
  );
};
AppIndex.propTypes = {
  applications: PropTypes.array
};
export default AppIndex;
