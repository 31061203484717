import React from 'react';
import PropTypes from 'prop-types';
import RadioButtonsGroup from '../../../../../components/form/RadioButtonGroup';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  field: {
    margin: '24px 0px 24px 0px'
  }
}));

const NationalSchemes = (props) => {

  const {handleStepState, form} = props;
  const stepSlug = 'national-schemes';
  const classes = useStyles();

  const handleChange = event => handleStepState(stepSlug, {[event.target.name]: event.target.value});

  return (
    <React.Fragment>
      <Typography component="h2" variant="h2">
        National Schemes
      </Typography>
      <h3 style={{fontSize: '18px'}}>Are you on the following national schemes?</h3>
      <RadioButtonsGroup
        label="Fast Track Inspector"
        showError={form[stepSlug].errors && form[stepSlug].errors.includes('nationalSchemesFastTrack')}
        name="nationalSchemesFastTrack"
        options={[
          {id: 'yes', name: 'Yes'},
          {id: 'no', name: 'No'}
        ]}
        value={form[stepSlug].data.nationalSchemesFastTrack}
        handleChange={(e) => handleChange(e)}
        className={classes.field}
      />
      <RadioButtonsGroup
        label="Direct Entry Inspector"
        showError={form[stepSlug].errors && form[stepSlug].errors.includes('nationalSchemesDirectEntry')}
        name="nationalSchemesDirectEntry"
        options={[
          {id: 'yes', name: 'Yes'},
          {id: 'no', name: 'No'}
        ]}
        value={form[stepSlug].data.nationalSchemesDirectEntry}
        handleChange={(e) => handleChange(e)}
      />
    </React.Fragment>
  );
};
NationalSchemes.propTypes = {
  handleStepState: PropTypes.func,
  form: PropTypes.object,
  step: PropTypes.object,
};
export default NationalSchemes;
