import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Link, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 32,
    textAlign: 'center'
  },
  spacedLinks: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  }
}));

const Footer = () => {
  const classes = useStyles();
  const {t} = useTranslation();
  return (
    <footer className={classes.root}>
      <Typography variant="body1" className={classes.spacedLinks}>
        <Link href="/accessibility">
          {t('footer.links.accessibility')}
        </Link>
        <Link href="/privacy-policy">
          {t('footer.links.privacyPolicy')}
        </Link>
      </Typography>
      <Typography variant="body1">
        &copy;{' College of Policing (' + new Date().getFullYear() + ')'}
      </Typography>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};
export default Footer;
