import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Grid, Divider, Typography} from '@material-ui/core';
import ResultStatus from '../../../../components/ResultStatus';
import {
  Description as FileIcon
} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import typography from '../../../../theme/typography';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 30
  },
  paper: {
    textAlign: 'center',
    boxShadow: 'none'
  },
  divider: {
    marginTop: 15,
    marginBottom: 15
  },
  icon: {
    position: 'relative',
    top: 5
  },
  link: {
    fontSize: 17
  },
  linkGrid: {
    textAlign: 'center'
  },
  sectionTitle: {
    ...typography.sectionTitle
  }
}));

const AssessmentResults = props => {

  const {application} = props;
  const classes = useStyles();
  const {t} = useTranslation();
  const displayFeedbackReport = () => application.feedbackReport;
  return (
    <div className={classes.root} data-testid="assessment-results">
      <Typography className={classes.sectionTitle}>
        {t('dashboard.app.results.title')}
      </Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <ResultStatus passed={application.result === 'PASS'} />
        </Grid>
        {displayFeedbackReport()
          ? <Grid item xs={12} className={classes.linkGrid}>
            <a target='_blank' rel="noopener noreferrer" href={`/document?id=${application.feedbackReport}`}>
              <FileIcon className={classes.icon} />{t('dashboard.app.results.feedbackReport.title')}
            </a>
            <div className={`${classes.messageBox} ${classes['info']}`}>
              {t('dashboard.app.results.feedbackReport.text')}
            </div>
          </Grid>
          : null}

      </Grid>
    </div>
  );
};

AssessmentResults.propTypes = {
  application: PropTypes.object,
  dashboardMessage: PropTypes.func
};

export default AssessmentResults;
