import typography from './typography';
import palette from './palette';
import GothamRegFont from '../assets/fonts/GothamBookRegular.otf';
import GothamBoldFont from '../assets/fonts/GothamBold.otf';

const gothamReg = {
  fontFamily: 'Gotham',
  fontStyle: 'normal',
  src: `url(${GothamRegFont}) format('opentype')`
};
const gothamBold = {
  fontFamily: 'Gotham',
  fontStyle: 'normal',
  fontWeight: '700',
  src: `url(${GothamBoldFont}) format('opentype')`

};

export default {
  MuiCssBaseline: {
    '@global': {
      '@font-face': [gothamReg, gothamBold],
    },
  },
  MuiTableCell: {
    root: {
      borderBottom: 'none'
    }
  },
  MuiTableRow: {
    root: {
      ...typography.body1,
      borderBottom: '1px solid #CFE5EE',
      '&:last-child': {
        borderBottom: 'none'
      }
    },
  },
  MuiSelect: {
    selectMenu: {
      whiteSpace: 'pre-wrap'
    }
  },
  MuiStepLabel: {
    labelContainer: {
      '@media screen and (min-width: 0px) and (max-width: 1400px)': {
        display: 'none'
      }
    }
  },
  MuiStepper: {
    root: {
      '@media screen and (min-width: 0px) and (max-width: 700px)': {
        display: 'flex',
        padding: 0
      }
    }
  },
  MuiStep: {
    horizontal: {
      '@media screen and (min-width: 0px) and (max-width: 700px)': {
        display: 'flex',
        padding: 0
      }
    }
  },
  MuiStepIcon: {
    root: {
      '@media screen and (min-width: 0px) and (max-width: 700px)': {
        width: '0.8em',
        height: '0.8em'
      }
    }
  },
  MuiInputLabel: {
    root: {
      color: '#556976'
    }
  },
  MuiButton: {
    containedPrimary: {
      backgroundColor: palette.button.primary.color,
      '&:hover': {
        backgroundColor: palette.button.primary.hover,
        color: '#FFF'
      }
    }
  },
  MuiDivider: {
    root: {
      backgroundColor: '#35826A'
    }
  },
  MuiLink: {
    root: {
      color: '#0D7EAB'
    }
  },
  MuiFormControlLabel: {
    root: {
      '@media screen and (min-width: 0px) and (max-width: 700px)': {
        padding: 5
      }
    }
  },
  MuiFormControl: {
    root: {
      maxWidth: 500
    }
  },
  MuiContainer: {
    root: {
      paddingLeft: 0,
      paddingRight: 0
    }
  }
};
