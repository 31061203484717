import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams, Redirect} from 'react-router-dom';
import {hasDomainCorrectionSuggestion, isValidEmail} from '../../../utils/email';
import {isValidNIN} from '../../../utils/string';
import isValidDateOfBirth, {isDateOfBirthWithinAcceptableRange} from '../../../utils/date';
import Form from '../Form';
import getForms from './utils/forms';
import getFormsSpecials from './utils/formsSpecials';
import formDefinition from './utils/formDefinition';
import formDefinitionSpecials from './utils/formDefinitionSpecials';
import PropTypes from 'prop-types';
import productUtils from '../../../utils/products';
import {CircularProgress} from '@material-ui/core';
import {useStateWithLocalStorage} from '../utils/localStorage';
import {COMMON, OPTIONAL_FIELDS} from '../../../utils/applicationConst';

const RecruitmentForm = (props) => {

  const {product, force} = props;
  const {slug} = useParams();
  const {t} = useTranslation();

  const [productId, setProductId] = useState();
  const [form, setForm] = useState({
    activeStep: props.activeStep || 0,
  });
  const activeStep = form.activeStep;
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(true);
  const [roleForceData, setRoleForceData] = useStateWithLocalStorage('roleForceData');
  const [forceName, setForceName] = useState();
  const [fieldValueOnWarn, setFieldValueOnWarn] = useState({});

  const isNotOptionalField = (field) => !OPTIONAL_FIELDS.includes(field);

  const isFieldValueEmpty = (fieldValue) => !fieldValue || 0 === fieldValue.length;

  const fetchProduct = async (product) => {
    return await productUtils.getProduct(product);
  };

  const fetchForce = async (force, product) => {
    return await productUtils.getForce(force, product);
  };

  const handleError = (e) => {
    if (e?.response) {
      /*
      * The request was made and the server responded with a
      * status code that falls out of the range of 2xx
      */
      if (e?.response?.status === 404) {
        setError(404);
      } else if (e?.response?.status === 422) {
        setError(422);
      } else {
        setError(500);
      }
    } else if (e?.request) {
      /*
      * The request was made but no response was received, `error.request`
      * is an instance of XMLHttpRequest in the browser and an instance
      * of http.ClientRequest in Node.js
      */
      setError(504);
    } else {
      // Something happened in setting up the request and triggered an Error
      setError(503);
    }
  };

  const getFormDefinitionForProduct = (productId) => {
    if (productId?.toLowerCase() === COMMON.SPECIALS.toLowerCase() || productId?.toLowerCase() === COMMON.PCSO.toLowerCase())
      return formDefinitionSpecials();

    return formDefinition();
  };

  const fetchRoleForceData = async (product, force) => {
    try {
      let [fetchedRole, fetchedForce] = await Promise.all([
        fetchProduct(product),
        fetchForce(force, product)
      ]);
      if (fetchedRole?.status === 200 && fetchedForce?.status === 200) {
        setProductId(fetchedRole.data.uniqueID);
        const definition = getFormDefinitionForProduct(productId);
        definition['role-force'].data = {role: fetchedRole.data.id, force: fetchedForce.data.id};
        setForm({...form, ...definition});
        setRoleForceData(JSON.stringify({role: product, force: force}));
        setForceName(fetchedForce.data.name);
      }
    } catch (e) {
      handleError(e);
      setLoading(false);
      console.error('Error while loading product', e);
    }
  };

  const areQueryParamsPresent = () => {
    return product && force;
  };

  const hasLocalStorageData = () => {
    return roleForceData;
  };

  const resolveRoleForceData = () => {
    if (areQueryParamsPresent()) {
      return {role: product, force: force};
    } else {
      return JSON.parse(roleForceData);
    }
  };

  const fetchData = async () => {
    if (!isNotLinkingToFirstPage()) {
      if (areQueryParamsPresent() || hasLocalStorageData()) {
        const {role, force} = resolveRoleForceData();
        await fetchRoleForceData(role, force);
        setLoading(false);
      } else {
        setError(400);
        setLoading(false);
      }
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchData();
  }, []);

  const processErrors = () => {
    if (error === 400) {
      return <div data-testid="error-400">Either the force or the assessment type you are looking for is incorrect. Please get in touch with your force for the correct website address.</div>;
    } else if (error === 404) {
      return <div  data-testid="error-404">Either the force or the assessment type you are looking for is incorrect. Please get in touch with your force for the correct website address.</div>;
    } else if (error === 422) {
      return <div  data-testid="error-422">The force is not currently accepting applications for this assessment. Please contact them directly for guidance.</div>;
    } else if (error === 500){
      return <div data-testid="error-500">Internal server error.</div>;
    } else if (error === 503){
      return <div data-testid="error-503">Service unavailable.</div>;
    } else if (error === 504){
      return <div data-testid="error-504">There was an error communicating with the server. Please check your network connection and retry.</div>;
    }
  };

  const validateStepForWarnings = (step) => {
    const {data: {email}} = form[step];
    const fieldWarning = {};

    if (email && fieldValueOnWarn.email !== email) {
      const domain = hasDomainCorrectionSuggestion(email);
      if (domain) {
        fieldWarning['email'] = `${t('common.emailDomainCorrectionWarning')} "${domain}"`;
        setFieldValueOnWarn({...fieldValueOnWarn, email});
      }
    }

    return fieldWarning;
  };

  const isValid = (step) => {
    const fieldError = {};
    const errorFields = Object.keys(form[step].data).filter(isNotOptionalField).filter(
      field => {
        if (field === 'firstLanguage') return form[slug].data['firstLanguageEnglish'] === 'no' && isFieldValueEmpty(form[slug].data[field]);
        if (field === 'previousPolicingExperience') return form[slug].data['anyPreviousPolicingExperience'] === 'yes' && isFieldValueEmpty(form[slug].data[field]);
        if (field === 'gender') return form[slug].data['genderMatch'] === 'no' && 0 === form[slug].data[field].length;
        if (field === 'dateOfBirth') {
          if (!isValidDateOfBirth(form[slug].data.dateOfBirth)) return true;
          if(!isDateOfBirthWithinAcceptableRange(form[slug].data.dateOfBirth, 16, 80)) {
            fieldError.dateOfBirth = t('common.dateOfBirthAgeError');
            return true;
          }
          return false;
        }
        if (field === 'email') {
          return !isValidEmail(form[slug].data[field]);
        }
        if (field === 'nationalInsuranceNumber') {
          return !isValidNIN(form[slug].data[field]);
        } else {
          return isFieldValueEmpty(form[step].data[field]);
        }
      }
    );
    const fieldWarning = validateStepForWarnings(step);
    setForm({
      ...form,
      [step]: {...form[step], errors: [...errorFields], fieldError, fieldWarning},
    });

    return errorFields.length === 0 && Object.keys(fieldWarning).length === 0;
  };

  const isNotLinkingToFirstPage = () => {
    return slug !== 'role-force' && activeStep === 0;
  };

  if (isNotLinkingToFirstPage()) {
    return <Redirect to='/recruitment' />;
  }

  if (isLoading) {
    return <CircularProgress data-testid='loading' />;
  }

  if (error) {
    return processErrors();
  }

  const getFormsForProduct = (productId) => {
    if (productId?.toLowerCase() === COMMON.SPECIALS.toLowerCase() || productId?.toLowerCase() === COMMON.PCSO.toLowerCase())
      return getFormsSpecials;

    return getForms;
  };

  return (
    <React.Fragment>
      <Form title="College of Policing registration form"
        isValid={isValid}
        getForms={getFormsForProduct(productId)}
        activeStep={activeStep}
        type="recruitment"
        slug={slug}
        form={form}
        setForm={setForm}
        formDefinition={formDefinition}
        forceName={forceName}
        startBtnLabel={'Apply'}
        submitBtnLabel={'Apply'}
        productId={productId}
      />
    </React.Fragment>
  );
};
RecruitmentForm.propTypes = {
  product: PropTypes.string,
  force: PropTypes.string,
  activeStep: PropTypes.number
};
export default RecruitmentForm;
