import Link from '@material-ui/core/Link';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const PromotionSuccess = () => (
  <div>
    <Typography paragraph={true}>
      Thank you for your application to register for your exam. You can expect to receive an email shortly to confirm this. Please check your junk mail folder in case the email is there. If you do not receive an email within 24 hours, please contact your force exams contact in the first instance. Please do not re-register as this will create multiple registrations and may lead to delays in the processing of your application.
    </Typography>
    <Typography paragraph={true}>
      Your application will be sent to your force exams contact to verify that you are eligible to sit the exam.
    </Typography>
    <Typography paragraph={true}>
      We would recommend joining the <Link href='https://knowledgehub.group' target='_blank'>Candidate Online Exam Guidance - Knowledge Hub</Link> group now you have registered. This group has College-approved documents to help prepare for your exam including revision guidance, practice questions and preparation advice.
    </Typography>
    <Typography paragraph={true}>
      Approximately one week before the exam, you will receive an email asking you to log in to the exams platform to familiarise yourself with the platform and to test the suitability of your device. Please keep an eye on your junk mail folder. You will then receive another email at least 24 hours before your exam date with details of how to access your exam on the day.
    </Typography>
    <Typography paragraph={true}>
      If you have any questions, please contact your force exams contact.
    </Typography>
    <Typography paragraph={true}>
      If you think you have made an error, please do not submit a new application and contact your force exams contact.
    </Typography>
  </div>
);

export default PromotionSuccess;
