const flatten = (obj) => {
  const flattened = {};

  Object.entries(obj).forEach(([key, value]) => {
    // support multi-select field
    if (Array.isArray(value)) {
      if (value && value.length) {
        flattened[key] = value;
      }
    } else if (typeof value === 'object' && value !== null) {
      Object.assign(flattened, flatten(value));
    } else {
      if (value !== '') {
        flattened[key] = value;
      }
    }
  });
  return flattened;
};

const boolenify = (yesNo) => {
  switch (yesNo) {
    case 'yes':
      return true;
    case 'no' :
      return false;
    default:
      return '';
  }
};

const processErrors = (form) => (responseData) => responseData.reduce(function (steps, item) {
  const stepId = findStep(form)(item.field);
  if (stepId) {
    return {
      ...steps,
      [stepId]: {
        ...steps[stepId],
        fieldErrors: {
          ...(steps[stepId] ? steps[stepId].fieldErrors : {}),
          [item.field]: item.error,
        },
      },
    };
  }
  return steps;
}, {});

const findStep = (form) => (errorField) => Object.keys(form).find(step => form[step]?.data && Object.keys(form[step].data).includes(errorField));

export {processErrors, flatten, boolenify};
