import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {AppBar, Toolbar} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import logo from '../../../assets/CoP_MasterLogo_RGB.svg';
import ChangeLanguage from '../../../components/ChangeLanguage';

const useStyles = makeStyles(() => ({
  logo: {
    width: 125,
    marginTop: 3
  },
  root: {
    backgroundColor: 'white'
  },
  changeLanguageLink: {
    position: 'absolute',
    top: '30%',
    right: '10%'
  }
}));

const Topbar = (props) => {

  const classes = useStyles();
  return (
    <AppBar
      className={classes.root}
      position="fixed">
      <Container maxWidth="lg">
        <Toolbar>
          <Typography color="inherit" noWrap>
            <img src={logo} alt="CoP" className={classes.logo} />
          </Typography>
          {props.translate ? <div className={classes.changeLanguageLink}><ChangeLanguage /></div> : null}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  translate: PropTypes.bool
};

export default Topbar;
