import {makeStyles} from '@material-ui/styles';
import React from 'react';
import RadioButtonsGroup from '../../../../../components/form/RadioButtonGroup';
import PropTypes from 'prop-types';
import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  entryRouteTable: {
    marginTop: 15
  },
  entryRouteTableHead: {
    fontSize: 16
  },
  entryRouteTableCell: {
    verticalAlign: 'top',
    border: '1px solid  black'
  },
  entryRouteColumn: {
    width: '25%'
  },
  section: {
    marginTop: 15
  }
}));

const EntryRoute = (props) => {

  const {handleStepState, form, type} = props;
  const classes = useStyles();
  const stepSlug = 'entry-route';
  const handleChange = event => handleStepState(stepSlug, {[event.target.name]: event.target.value});

  const getIntroduction = () => {
    if (type === 'promotion') {
      return <Typography>
        We are interested to know what your entry route into the police constable rank was. Please see below a description of each of the entry routes.
      </Typography>;
    } else {
      return <Typography>
        We are interested to know your entry route into the police constable rank. Please see below a description of each of the entry routes.
      </Typography>;
    }
  };

  const getEntryRouteLabel = () => {
    if (type === 'promotion') {
      return 'Please indicate by which entry route you joined the police service (details of each entry route are provided above).';
    } else {
      return 'Please indicate by which entry route you are joining the police service (details of each entry route are provided above).';
    }
  };

  return (
    <React.Fragment>
      <Typography component="h2" variant="h2">
        Entry route
      </Typography>
      <div className={classes.section}>
        {getIntroduction()}
        <Table aria-label="simple table" className={classes.entryRouteTable}>
          <TableHead className={classes.entryRouteTableHead}>
            <TableRow>
              <TableCell className={`${classes.entryRouteTableHead} ${classes.entryRouteTableCell} ${classes.entryRouteColumn}`}>
                Entry route
              </TableCell>
              <TableCell className={`${classes.entryRouteTableHead} ${classes.entryRouteTableCell}`}>
                Description of entry route
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.entryRouteTableCell}>
                <Typography align="left">
                  Police Constable Degree Apprenticeship (PCDA)
                </Typography>
              </TableCell>
              <TableCell className={classes.entryRouteTableCell}>
                <Typography>
                  An entry route that enables new recruits without a degree to join the police service on a three-year professional degree apprenticeship delivered through a formal collaboration between a police force and an education provider.
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.entryRouteTableCell}>
                <Typography align="left">
                  Degree Holder Entry Programmes (DHEP)
                </Typography>
              </TableCell>
              <TableCell className={classes.entryRouteTableCell}>
                <Typography>
                  An entry route for degree holders. On successful completion, recruits achieve a Level 6 Graduate Diploma in Professional Policing Practice.
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.entryRouteTableCell}>
                <Typography align="left">
                  Detective DHEP
                </Typography>
              </TableCell>
              <TableCell className={classes.entryRouteTableCell}>
                <Typography>
                  An entry route based on DHEP for degree holders. It has a specific investigative focus, and the expectation is that recruits qualify as a detective constable with the Level 6 Graduate Diploma in Professional Policing Practice.
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.entryRouteTableCell}>
                <Typography align="left">
                  Pre-join Degree in Professional Policing
                </Typography>
              </TableCell>
              <TableCell className={classes.entryRouteTableCell}>
                <Typography>
                  An entry route for candidates with an accredited three-year degree in professional policing.
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.entryRouteTableCell}>
                <Typography align="left">
                  Police Constable Entry Programme (PCEP)
                </Typography>
              </TableCell>
              <TableCell className={classes.entryRouteTableCell}>
                <Typography>
                  An entry route that enables new recruits without a degree to join the police service on a two-year training programme which can be delivered in-house by forces in its entirety.
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      <RadioButtonsGroup
        label={getEntryRouteLabel()}
        showError={form[stepSlug].errors && form[stepSlug].errors.includes('entryRoute')}
        errorMsg={'If you do not wish to answer this question, please select ‘Prefer not to say’'}
        name="entryRoute"
        options={[
          {id: 'policeDegreeApprenticeship', name: 'Police Constable Degree Apprenticeship (PCDA)'},
          {id: 'degreeHolderEntry', name: 'Degree Holder Entry Programmes (DHEP)'},
          {id: 'detectiveDHEP', name: 'Detective DHEP'},
          {id: 'preJoinDegreeProfPolicing', name: 'Pre-join Degree in Professional Policing'},
          {id: 'policeConstableEntryProgramme', name: 'Police Constable Entry Programme (PCEP)'},
          {id: 'neitherOfTheseRoutes', name: 'None of these routes'},
          {id: 'dontKnow', name: 'Don\'t know'},
          {id: 'preferNotToSay', name: 'Prefer not to say'}
        ]}
        value={form[stepSlug].data.entryRoute}
        handleChange={(e) => handleChange(e)}
      />
    </React.Fragment>
  );
};
EntryRoute.propTypes = {
  handleStepState: PropTypes.func,
  type: PropTypes.string,
  form: PropTypes.object,
  step: PropTypes.object,
};
export default EntryRoute;
