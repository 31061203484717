import React from 'react';
import {Switch, Redirect, Route, useLocation} from 'react-router-dom';
import RouteWithLayout from './components/RouteWithLayout';
import {DashboardLayout, BasicLayout} from './layouts';
import {
  AccessibilityStatementView,
  VerificationView,
  ErrorView,
  PrivacyPolicyView,
  PromoSelfRegisterLandingView, PromoFormView,
  DashboardAppIndexView, DashboardAppSingleView,
  RecruitFormView
} from './views';
import InvitationCheck from './views/Pages/Invitation/InvitationCheck';
import OAuth2Callback from './components/OAuth2Callback';
import Document from './components/Document';
import OAuth2Guard from './components/OAuth2Guard';
import Error404 from './views/Pages/Error/404';

const withBasicLayout = (Component) => (
  <BasicLayout>
    <Component />
  </BasicLayout>
);
const withDashboardLayout = (Component) => (
  <DashboardLayout>
    <Component />
  </DashboardLayout>
);


const withAuth = (children) => (
  <OAuth2Guard>
    {children}
  </OAuth2Guard>
);

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Routes = () => {
  let query = useQuery();
  return (
    <Switch>
      <RouteWithLayout
        component={InvitationCheck}
        layout={BasicLayout}
        translate={true}
        exact
        path="/invite/:invitation"
      />
      <Route exact path="/verify">
        {withAuth(withBasicLayout(VerificationView))}
      </Route>
      <Route exact path="/">
        <Redirect to="/applications" />
      </Route>
      <RouteWithLayout
        component={OAuth2Callback}
        layout={BasicLayout}
        exact
        path="/oauth2"
      />
      <RouteWithLayout
        component={PrivacyPolicyView}
        layout={BasicLayout}
        exact
        path="/privacy-policy"
      />
      <RouteWithLayout
        component={AccessibilityStatementView}
        layout={BasicLayout}
        exact
        path="/accessibility"
      />
      <Route path="/promotion" exact>
        <BasicLayout translate={false}>
          <PromoSelfRegisterLandingView />
        </BasicLayout>
      </Route>
      <Route path="/promotion/:slug">
        <BasicLayout translate={false}>
          <PromoFormView />
        </BasicLayout>
      </Route>
      <Route path="/recruitment" exact>
        <Redirect to={`/recruitment/role-force?product=${query.get('product')}&force=${query.get('force')}`} />
      </Route>
      <Route path="/recruitment/:slug">
        <BasicLayout translate={false}>
          <RecruitFormView product={query.get('product')} force={query.get('force')} />
        </BasicLayout>
      </Route>
      <Route exact path="/applications">
        {withAuth(withDashboardLayout(DashboardAppIndexView))}
      </Route>
      <Route path="/applications/:appId">
        {withAuth(withDashboardLayout(DashboardAppSingleView))}
      </Route>
      <Route path="/document">
        {withAuth(withBasicLayout(Document))}
      </Route>
      <RouteWithLayout
        component={ErrorView}
        layout={BasicLayout}
        exact
        path="/error"
      />
      <RouteWithLayout
        component={Error404}
        layout={BasicLayout}
        exact
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
