import {makeStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import {Link, Table, TableBody, TableCell, TableRow, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {formatDateDDMMYYY} from '../../../../../utils/date';

const useStyles = makeStyles(() => ({
  verificationTable: {
    width: '80%'
  },
  verificationTableRow: {
    border: 'none'
  },
  verificationCellHeader: {
    fontWeight: 'bold'
  },
  section: {
    marginTop: 15
  },
  verificationCell: {
    fontSize: '16px'
  }
}));

const Verification = (props) => {

  const {form, getForms, goToStep} = props;
  const classes = useStyles();
  const {t} = useTranslation();

  const {data: personalDetails = {}} = form['personal-details'] || {};
  const personalDetailsStepId = getForms().findIndex(f => f.slug === 'personal-details');
  const verificationStepId = getForms().findIndex(f => f.slug === 'verification');

  return (
    <React.Fragment>
      <div>
        <Typography component='h2' variant='h2'>
          Verification
        </Typography>
        <div className={classes.section}>
          <Typography paragraph={true} align={'justify'}>
            {t('pages.verification.instruction')}
          </Typography>
          <Typography paragraph={true} align={'justify'}>
            {t('pages.verification.instruction1')}
          </Typography>
        </div>
        <Table aria-label='simple table' className={classes.verificationTable}>
          <TableBody>
            <TableRow className={classes.verificationTableRow}>
              <TableCell className={classes.verificationCell}>
                <span className={classes.verificationCellHeader}>First Name:</span> {personalDetails.firstName}
              </TableCell>
              <TableCell className={classes.verificationCell}>
                <Link
                  data-testid='firstNameLink'
                  component='button'
                  variant='body1'
                  onClick={() => goToStep(personalDetailsStepId, verificationStepId, 'firstName')}
                >
                  Edit
                </Link>
              </TableCell>
            </TableRow>
            <TableRow className={classes.verificationTableRow}>
              <TableCell className={classes.verificationCell}>
                <span className={classes.verificationCellHeader}>Last Name:</span> {personalDetails.lastName}
              </TableCell>
              <TableCell className={classes.verificationCell}>
                <Link
                  data-testid='lastNameLink'
                  component='button'
                  variant='body1'
                  onClick={() => goToStep(personalDetailsStepId, verificationStepId, 'lastName')}
                >
                  Edit
                </Link>
              </TableCell>
            </TableRow>
            <TableRow className={classes.verificationTableRow}>
              <TableCell className={classes.verificationCell}>
                <span className={classes.verificationCellHeader}>Email:</span> {personalDetails.email}
              </TableCell>
              <TableCell className={classes.verificationCell}>
                <Link
                  data-testid='emailLink'
                  component='button'
                  variant='body1'
                  onClick={() => goToStep(personalDetailsStepId, verificationStepId, 'email')}
                >
                  Edit
                </Link>
              </TableCell>
            </TableRow>
            <TableRow className={classes.verificationTableRow}>
              <TableCell className={classes.verificationCell}>
                <span className={classes.verificationCellHeader}>Date of birth:</span> {formatDateDDMMYYY(personalDetails.dateOfBirth)}
              </TableCell>
              <TableCell className={classes.verificationCell}>
                <Link
                  data-testid='dobLink'
                  component='button'
                  variant='body1'
                  onClick={() => goToStep(personalDetailsStepId, verificationStepId, 'dateOfBirth')}
                >
                  Edit
                </Link>
              </TableCell>
            </TableRow>
            <TableRow className={classes.verificationTableRow}>
              <TableCell className={classes.verificationCell}>
                <span className={classes.verificationCellHeader}>National Insurance number:</span> {personalDetails.nationalInsuranceNumber}
              </TableCell>
              <TableCell className={classes.verificationCell}>
                <Link
                  data-testid='ninoLink'
                  component='button'
                  variant='body1'
                  onClick={() => goToStep(personalDetailsStepId, verificationStepId, 'nationalInsuranceNumber')}
                >
                  Edit
                </Link>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </React.Fragment>
  );
};
Verification.propTypes = {
  form: PropTypes.object,
  getForms: PropTypes.func,
  goToStep: PropTypes.func,
};
export default Verification;
