import React, {useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import Input from '../../../../../components/form/Input';
import DateInput from '../../../../../components/form/DateInput';
import {trimValue} from '../../../../../utils/string';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 35
  },
  applyButton: {
    marginTop: 10
  },
  field: {
    margin: '24px 0px 24px 0px',
    maxWidth: 500
  },
  caution: {
    backgroundColor: '#F4F4F4',
    color: '#4A4A4A',
    padding: '20px 20px 5px 20px'
  }
}));

const PersonalDetails = (props) => {

  const {handleStepState, form, focusField} = props;
  const classes = useStyles();
  const {t} = useTranslation();
  const stepSlug = 'personal-details';
  const handleChange = event => handleStepState(stepSlug, {[event.target.name]: event.target.value});
  const trimValueOnBlur = event => handleStepState(stepSlug, {[event.target.name]: trimValue(event.target.value)});

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const dateOfBirthRef = useRef();
  const nationalInsuranceNumberRef = useRef();

  useEffect(() => {
    ({
      'firstName': firstNameRef,
      'lastName': lastNameRef,
      'email': emailRef,
      'dateOfBirth': dateOfBirthRef,
      'nationalInsuranceNumber': nationalInsuranceNumberRef
    })[focusField]?.current?.focus();
  }, [focusField]);

  const setInputRef = (inputRef) => (element) => {
    inputRef.current = element;
  };

  return (
    <React.Fragment>
      <Typography component='h2' variant='h2'>
        Personal details
      </Typography>
      <div className={classes.field}>
        <Input
          name="firstName"
          title="First name"
          showError={form[stepSlug].fieldError?.firstName || (form[stepSlug].errors && form[stepSlug].errors.includes('firstName'))}
          errorMessage={t([
            `error.firstName.${form[stepSlug].fieldError?.firstName}`,
            `error.${form[stepSlug].fieldError?.firstName}`,
            'error.default',
          ])}
          value={form[stepSlug].data.firstName}
          handleChange={(e) => handleChange(e)}
          handleBlur={(e) => trimValueOnBlur(e)}
          maxLength={50}
          inputRef={setInputRef(firstNameRef)}
        />
      </div>
      <div className={classes.field}>
        <Input
          name="lastName"
          title="Last name"
          showError={form[stepSlug].fieldError?.lastName || (form[stepSlug].errors && form[stepSlug].errors.includes('lastName'))}
          errorMessage={t([
            `error.lastName.${form[stepSlug].fieldError?.lastName}`,
            `error.${form[stepSlug].fieldError?.lastName}`,
            'error.default',
          ])}
          value={form[stepSlug].data.lastName}
          handleChange={(e) => handleChange(e)}
          handleBlur={(e) => trimValueOnBlur(e)}
          maxLength={50}
          inputRef={setInputRef(lastNameRef)}
        />
      </div>
      <div className={classes.field}>
        <Input
          name="email"
          title="Email"
          placeholder="name@example.com"
          showError={form[stepSlug].errors ? form[stepSlug].errors.includes('email') : false}
          errorMessage="Invalid email address"
          showWarning={form[stepSlug].fieldWarning ? Object.keys(form[stepSlug].fieldWarning).includes('email') : false}
          warningMessage={form[stepSlug].fieldWarning && form[stepSlug].fieldWarning['email']}
          value={form[stepSlug].data.email}
          handleChange={(e) => handleChange(e)}
          handleBlur={(e) => trimValueOnBlur(e)}
          maxLength={100}
          inputRef={setInputRef(emailRef)}
        />
      </div>
      <div className={classes.field}>
        <DateInput
          title="Date of birth"
          showError={form[stepSlug].errors ? form[stepSlug].errors.findIndex(element => element.includes('dateOfBirth')) !== -1 : false}
          errorMessage={form[stepSlug].errors && form[stepSlug].errors.findIndex(element => element.includes('dateOfBirth')) !== -1 ? form[stepSlug]?.fieldError['dateOfBirth'] || 'Invalid date of birth' : ''}
          handleChange={(e) => handleChange(e)}
          value={form[stepSlug].data.dateOfBirth}
          name="dateOfBirth"
          inputRef={setInputRef(dateOfBirthRef)}
        />
      </div>
      <div className={classes.field}>
        <Input
          name="nationalInsuranceNumber"
          title="National Insurance number"
          placeholder="QQ123456C"
          showError={form[stepSlug].errors ? form[stepSlug].errors.includes('nationalInsuranceNumber') : false}
          errorMessage="National Insurance number is invalid"
          value={form[stepSlug].data.nationalInsuranceNumber}
          handleChange={(e) => handleChange(e)}
          handleBlur={(e) => trimValueOnBlur(e)}
          maxLength={9}
          inputRef={setInputRef(nationalInsuranceNumberRef)}
        />
      </div>
      <div className={classes.caution}>
        <Typography paragraph={true} align={'justify'}>
          {t('pages.personalDetails.cautionText')}
        </Typography>
      </div>
    </React.Fragment>
  );
};
PersonalDetails.propTypes = {
  handleStepState: PropTypes.func,
  form: PropTypes.object,
  focusField: PropTypes.string,
  step: PropTypes.object,
};
export default PersonalDetails;
