import {ErrorView} from '../../index';
import React from 'react';
import {useTranslation} from 'react-i18next';

const Error404 = () => {

  const {t} = useTranslation();
  return <ErrorView title={t('pages.error.notFoundTitle')} message={t('pages.error.notFoundMsg')} />;
};
export default Error404;
