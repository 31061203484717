import {Box, Typography} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import RadioButtonsGroup from '../../../../components/form/RadioButtonGroup';

const SocioeconomicBackground = (props) => {

  const {handleStepState, form, type} = props;
  const stepSlug = 'socioeconomic-background';
  const handleChange = event => handleStepState(stepSlug, {[event.target.name]: event.target.value});
  const getIntroduction = () => {
    if (type === 'promotion') {
      return <Box>
        <Typography paragraph={true}>
          The College of Policing and wider policing family are committed to creating a culture that values difference and diversity.
        </Typography>
        <Typography paragraph={true}>
          We want to understand the background of people in policing, including: socioeconomic background, education, language and which route you came into policing through. We will use this information to improve access to policing.
        </Typography>
      </Box>;
    } else {
      return <Box>
        <Typography paragraph={true}>
          The College of Policing and wider policing family are committed to creating a culture that values difference and diversity.
        </Typography>
        <Typography paragraph={true}>
          We want to understand the background of people joining policing, including: socioeconomic background, education, language, which route you come into policing through and whether you have any previous policing background. We will use this information to improve access to policing.
        </Typography>
      </Box>;
    }
  };

  return (
    <React.Fragment>
      <Typography component="h2" variant="h2">
        Socioeconomic background
      </Typography>
      {getIntroduction()}
      <RadioButtonsGroup
        label="What type of school did you mainly attend between the ages of 11 and 16?"
        showError={form[stepSlug].errors && (form[stepSlug].errors.includes('highSchool') || form[stepSlug].errors.includes('highSchoolOther'))}
        errorMsg={'If you do not wish to answer this question, please select ‘Prefer not to say’'}
        name="highSchool"
        options={[
          {
            id: 'stateSelective',
            name: 'State-run or state-funded school - selective on academic, faith or other grounds'
          },
          {id: 'stateNonSelective', name: 'State-run or state-funded school - non-selective'},
          {id: 'independentBursary', name: 'Independent or fee-paying school - bursary'},
          {id: 'independentNonBursary', name: 'Independent or fee paying school - no bursary'},
          {id: 'outsideUK', name: 'Attended school outside the UK'},
          {id: 'dontKnow', name: 'Don\'t know'},
          {id: 'other', name: 'Other'},
          {id: 'preferNotToSay', name: 'Prefer not to say'}
        ]}
        value={form[stepSlug].data.highSchool}
        other={form[stepSlug].data.highSchoolOther}
        nameOther="highSchoolOther"
        labelOther="Please describe (optional)"
        handleChange={(e) => handleChange(e)}
        showTextfield="other"
      />

      <RadioButtonsGroup
        label="What is the highest level of qualifications achieved by either of your parent(s) or guardian(s) by the time you were 18?"
        showError={form[stepSlug].errors && (form[stepSlug].errors.includes('parentAcademicAttain') || form[stepSlug].errors.includes('parentAcademicAttainOther'))}
        errorMsg={'If you do not wish to answer this question, please select ‘Prefer not to say’'}
        name="parentAcademicAttain"
        options={[
          {id: 'parentDegree', name: 'At least one has a degree level qualification'},
          {id: 'parentBelowDegree', name: 'Qualifications below degree level'},
          {id: 'parentNoQualifications', name: 'No formal qualifications'},
          {id: 'dontKnow', name: 'Don\'t know'},
          {id: 'notApplicable', name: 'Not applicable'},
          {id: 'other', name: 'Other'},
          {id: 'preferNotToSay', name: 'Prefer not to say'}
        ]}
        value={form[stepSlug].data.parentAcademicAttain}
        other={form[stepSlug].data.parentAcademicAttainOther}
        nameOther="parentAcademicAttainOther"
        labelOther="Please describe (optional)"
        handleChange={(e) => handleChange(e)}
        showTextfield="other"
      />

      <RadioButtonsGroup
        label="If you finished school after 1980, were you eligible for Free School Meals at any point during your school years?"
        info={
          <Typography paragraph={true}>
            Free School Meals are a statutory benefit available to school-aged children from families who receive other qualifying benefits
            and who have been through the relevant registration process. It does not include those who receive meals at school through other
            means (e.g. boarding school).
          </Typography>
        }
        showError={form[stepSlug].errors && (form[stepSlug].errors.includes('freeSchoolMeals'))}
        errorMsg={'If you do not wish to answer this question, please select ‘Prefer not to say’'}
        name="freeSchoolMeals"
        options={[
          {id: 'yes', name: 'Yes'},
          {id: 'no', name: 'No'},
          {id: 'notApplicable', name: 'Not applicable (finished school before 1980 or went to school overseas)'},
          {id: 'dontKnow', name: 'Don\'t know'},
          {id: 'preferNotToSay', name: 'Prefer not to say'}
        ]}
        value={form[stepSlug].data.freeSchoolMeals}
        handleChange={(e) => handleChange(e)}
      />

      <RadioButtonsGroup
        label="Compared to people in general, would you describe yourself as coming from a lower socio-economic background?"
        showError={form[stepSlug].errors && (form[stepSlug].errors.includes('fromLowerSocioEcoBackground'))}
        errorMsg={'If you do not wish to answer this question, please select ‘Prefer not to say’'}
        name="fromLowerSocioEcoBackground"
        options={[
          {id: 'yes', name: 'Yes'},
          {id: 'no', name: 'No'},
          {id: 'dontKnow', name: 'Don\'t know'},
          {id: 'preferNotToSay', name: 'Prefer not to say'}
        ]}
        value={form[stepSlug].data.fromLowerSocioEcoBackground}
        handleChange={(e) => handleChange(e)}
      />
    </React.Fragment>
  );
};
SocioeconomicBackground.propTypes = {
  handleStepState: PropTypes.func,
  type: PropTypes.string,
  form: PropTypes.object,
  step: PropTypes.object,
};

export default SocioeconomicBackground;
