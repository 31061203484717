const isEmpty = (str) => {
  return (!str || 0 === str.length);
};

const isValidNIN = (nin) => {
  const regex = /^([A-Za-z]{2}[0-9]{6}[A-Za-z])$/;
  return regex.test(String(nin).toLowerCase());
};

const trimValue = (value) => typeof value === 'string'? value.trim() : value;

export {isEmpty, isValidNIN, trimValue};
