import Typography from '@material-ui/core/Typography';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 35
  },

}));
const FormHeader = (props) => {
  const {text} = props;
  const classes = useStyles();
  return (
    <Typography component="h1" variant="h1" className={classes.title}>
      {text}
    </Typography>
  );
};
FormHeader.propTypes = {
  text: PropTypes.string
};
export default FormHeader;
